import React from "react";
import "./Contacts.scss";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import location_icon from "../../../Assets/Icon/location.png";
import phone_icon from "../../../Assets/Home/phone2.png";

export class Contacts extends React.Component {

  render() {
    const { contact } = this.props;  
    return (
      <div className="contacts-component">
        <div className="container contacts-container">
          <h2 className="title">Contacts</h2>
          <div className=" main-row">
            <div className="row map-details">
              <div className="col-lg-6 item-area">
                <div>
                  <img src={location_icon} alt="lcoation" />
                </div>
                <p>
                  {contact.office} <br />
                  {contact.address1} <br />
                  {contact.address2} <br /> <br />
                </p>
              </div>
              <div className="col-lg-6 item-area ">
                <div>
                  <img src={phone_icon} alt="phone" />
                </div>
                <p>{contact.phone}</p>
              </div>
            </div>
            <div className="col-lg-12 map-area">
              <div className="map-wrapper">
                <div className="map-container">
                  <Map
                      google={this.props.google}
                      zoom={8}
                      style={{
                          width: '100%',
                          height: '100%'
                      }}
                      initialCenter={{ lat: contact.lattitude, lng: contact.longitude }}
                  >
                      <Marker position={{ lat: contact.lattitude, lng: contact.longitude }} />
                  </Map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper(
  (props) => ({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      language: 'en',
      region: 'us'
  }
))(Contacts)