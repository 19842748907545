import React from 'react';
import './HowItWorksV2.scss';

import work1 from '../../../Assets/Home/work1.png';
import work2 from '../../../Assets/Home/work2.png';
import work3 from '../../../Assets/Home/work3.png';

const WorkBlocks = [
    { id: 1, img: work1, title: 'Tutoring Needs', description: 'Let us know your specific tutoring needs and the type of tutor you would like to work with.' },
    { id: 2, img: work2, title: 'Free Trial Lesson', description: "We match you with a top tutor for a free trial lesson to ensure we're the right fit for you. No credit card is required." },
    { id: 3, img: work3, title: 'Design Your Plan', description: 'If 100% happy with your trial lesson, We let you design your payment plan starting at $27.5/hr for a budget that works for your family.' },
]

export default class HowItWorksV2 extends React.Component {
    render() {
        return (
            <div className="howitworks-component v-r">
                <div className="container v-r">
                    <h1>How it works</h1>
                    <div className="row">
                        {WorkBlocks.map((item, index) => <div className="col-lg-4 sub-col v-c h-c" key={index}>
                            <div className="work-block">
                                <div className="show-web-flex v-c h-c circle number-outter">
                                    <div className="v-c h-c circle number-in">{item.id}</div>
                                </div>
                                <div className="work-content v-r v-c">
                                    <img src={item.img} alt="work-img" />
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        )
    };
};