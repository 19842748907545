import React from "react";
import "./Services.scss";
import Loadable from "@loadable/component";
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import { Service } from "../../";
import { StartButton } from "../../";
import { NavLink } from "react-router-dom";
import _ from 'lodash'

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default class Services extends React.Component {
  render() {
    const { services, city } = this.props;
    var title = 'We have tutors for other subjects';
    if(city)title = title + ' in ' + city;
    return (
      <>
        {!_.isEmpty(services) && <div className="services-component">
          <div className="container services-container">
            <h2 className="title">Other Online Tutoring Services</h2>
            <div className="description">{ title }</div>
            <div className="col-lg-12 main-row">
              <Media
                queries={{
                  lg: "(min-width: 1200px)",
                  md: "(min-width: 992px) and (max-width: 1199px)",
                  sm: "(min-width: 768px) and (max-width: 991px)",
                  normal: "(max-width: 767px)",
                }}
              >
                {(matches) => (
                  <OwlCarousel
                    className="owl-theme"
                    nav
                    stagePadding={
                      matches.lg ? 15 : matches.md ? 5 : matches.sm ? 5 : 15
                    }
                    margin={
                      matches.lg ? 28 : matches.md ? 33 : matches.sm ? 45 : 20
                    }
                    items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                    dots={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {services.map((item, index) => {
                      return <Service key={index} data={item.data}/>;
                    })}
                  </OwlCarousel>
                )}
              </Media>
            </div>
            <NavLink style={{ textDecoration: "none" }} to="/sign-up">
              <StartButton className="start-btn" startText="FREE TRIAL LESSON" />
            </NavLink>
          </div>
        </div>}
      </>
    );
  }
}
