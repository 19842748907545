import React from "react";
import "./StartAreaSearch.scss";
import freeBg from "../../../Assets/Home/free-bg.png";
import cbs from "../../../Assets/Home/CBS.png";
import cnn from "../../../Assets/Home/CNN.png";
import fox from "../../../Assets/Home/FOX.png";
import boston from "../../../Assets/Home/The_Boston_Globe.png";
import miami from "../../../Assets/Home/The-Miami-Herald-Logo.png";
import homeRightImg1 from "../../../Assets/Home/home-rightImg1.png";
import homeRightImg2 from "../../../Assets/Home/home-rightImg2.png";
import homeRightImg3 from "../../../Assets/Home/home-rightImg3.png";
import homeRightImg4 from "../../../Assets/Home/home-rightImg4.png";
import homeRightImg5 from "../../../Assets/Home/home-rightImg5.png";
import { StartButton, HourlyRateSlider } from "../../Common";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { SelectBoxForFindTutor } from "../../../Components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  getSubjectAction,
  getCitiesAction
} from "../../../Redux/Actions";

class StartAreaSearch extends React.Component {
  state = {
    currentHourlyRate: 25,
    region: '',
    subject: ''
  };

  componentDidMount=()=> {
    this.props.getSubjectAction("");
    this.props.getCitiesAction();
    // this.setState({region: this.props.route_region});
  };

  cityTxt = (cityName) => {
    if(cityName.length%2 === 0){
      this.props.getCitiesAction(cityName);
    };
  };

  findTutor = () => {
    const { region, subject }  = this.state;
    this.props.findTutor( region, subject );
  };

  render() {
    const { cities, subjects, homeData, region, subject } = this.props;

    var route_region = "";
    var route_subject = "";
    if(region){
      var route_region = region.replace(/-/g, ' ');
    }
    if(subject){
      var route_subject = subject.replace(/-/g, ' ');
    }

    return (
      <div className="startarea-search-component">
        <div className="startarea-search-content">
          <div className="container startarea-search-container">
            <div className="main-row">
              <div className="col-lg-7 left-area">
                <h1>{homeData.title}</h1>
                <div className="row search-area">
                  <div className="col-lg-4 item-area">
                    <div className="input-area">
                      <SelectBoxForFindTutor
                        // value={route_region}
                        placeholder="City"
                        show_arrow
                        options={cities || []}
                        onChange={(e) =>this.setState({region: e.name})}
                        className={""}
                        changeTxt={(e)=>this.cityTxt(e)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 item-area">
                    <div className="input-area">
                      <SelectBoxForFindTutor
                        // value={route_subject}
                        placeholder="Subject"
                        show_arrow
                        options={subjects || []}
                        onChange={(e) =>this.setState({subject: e.name})}
                        className={""}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 item-area" onClick={()=>this.findTutor()}>
                    <div className="find-button">Find Tutor</div>
                  </div>

                </div>
                <p>{homeData.subtitle}</p>
              </div>
              <div className="col-lg-5 right-area show-web">
                <AliceCarousel
                  autoPlay={true}
                  keysControlDisabled={true}
                  autoPlayInterval={7000}
                  dotsDisabled={true}
                  buttonsDisabled={true}
                >
                  <img src={homeRightImg1} />
                  <img src={homeRightImg2} />
                  <img src={homeRightImg3} />
                  <img src={homeRightImg4} />
                  <img src={homeRightImg5} />
                </AliceCarousel>
              </div>
            </div>

            <div className="row show-web web-logo">
              <div className="col-lg-12 logo-area show-web-flex">
                <img src={cnn} alt="cnn" />
                <img src={cbs} alt="cbs" />
                <img src={fox} alt="fox" />
                <img src={boston} alt="boston" />
                <img src={miami} alt="miami" />
              </div>
            </div>
            <div className="show-mobile mt-5">
              <div className="col-lg-12 logo-area logo-area-mobile show-mobile-flex">
                <img src={cbs} alt="cbs" />
                <img src={cnn} alt="cnn" />
                <img src={fox} alt="fox" />
              </div>
              <div className="col-lg-12 logo-area logo-area-mobile show-mobile-flex">
                <img src={boston} alt="boston" />
                <img src={miami} alt="miami" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subjects: state.Core.subjects,
  cities: state.Core.cities,
});

export default connect(mapStateToProps, {
  getSubjectAction,
  getCitiesAction,
})(withRouter(StartAreaSearch));
