import {
  GET_AUTH_SIGN_IN,
  GET_AUTH_SIGN_IN_SUCCESS,
  GET_AUTH_SIGN_IN_FAIL,
  GET_AUTH_SIGN_OUT,
  GET_AUTH_SIGN_OUT_SUCCESS,
  GET_AUTH_SIGN_OUT_FAIL,
  GET_AUTH_SIGN_UP,
  GET_AUTH_SIGN_UP_SUCCESS,
  GET_AUTH_SIGN_UP_FAIL,
  GET_PROFILE_UPDATE,
  GET_PROFILE_UPDATE_SUCCESS,
  GET_PROFILE_UPDATE_FAIL,
  GET_AUTH_HUBSPOT_SIGN_UP,
  GET_AUTH_HUBSPOT_SIGN_UP_SUCCESS,
  GET_AUTH_HUBSPOT_SIGN_UP_FAIL,
  GET_AUTH_FORGOT_PASSWORD,
  GET_AUTH_FORGOT_PASSWORD_SUCCESS,
  GET_AUTH_FORGOT_PASSWORD_FAIL,
  GET_AUTH_RESET_PASSWORD,
  GET_AUTH_RESET_PASSWORD_SUCCESS,
  GET_AUTH_RESET_PASSWORD_FAIL,
  TUTOR_SIGN_UP,
  TUTOR_SIGN_UP_SUCCESS,
  TUTOR_SIGN_UP_FAIL,
  MAILCHIMP,
  MAILCHIMP_SUCCESS,
  MAILCHIMP_FAIL,
  GET_SEND_NONCE_TO_SERVER,
  GET_SEND_NONCE_TO_SERVER_SUCCESS,
  GET_SEND_NONCE_TO_SERVER_FAIL,
  GET_TV_SUBSCRIBE,
  GET_TV_SUBSCRIBE_SUCCESS,
  GET_TV_SUBSCRIBE_FAIL,
  GET_TUTOR_PROFILE_UPDATE,
  GET_TUTOR_PROFILE_UPDATE_SUCCESS,
  GET_TUTOR_PROFILE_UPDATE_FAIL,
} from "../Type";
import {
  signIn,
  signUp,
  profileUpdate,
  signOut,
  forgotPassword,
  resetPassword,
  tutorSignUp,
  signUpHubspot,
  mailchimp,
  sendNoneToServer,
  tvSubscribe,
  tutorProfileUpdate
} from "../../Api/Auth";

export const signInAction = (username, password) => async (dispatch) => {
  dispatch({ type: GET_AUTH_SIGN_IN });
  const res = await signIn(username, password);
  if (res && res.data) {
    dispatch({ type: GET_AUTH_SIGN_IN_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AUTH_SIGN_IN_FAIL, payload: res });
  }
};
export const signUpAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_SIGN_UP });
  const res = await signUp(payload);
  if (res && res.data) {
    dispatch({ type: GET_AUTH_SIGN_UP_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AUTH_SIGN_UP_FAIL, payload: res });
  }
};
export const profileUpdateAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_PROFILE_UPDATE });
  const res = await profileUpdate(payload);
  if (res && res.data) {
    dispatch({ type: GET_PROFILE_UPDATE_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_PROFILE_UPDATE_FAIL, payload: res });
  }
};
export const signUpHubspotAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_HUBSPOT_SIGN_UP });
  const res = await signUpHubspot(payload);
  if (res && res.data) {
    dispatch({ type: GET_AUTH_HUBSPOT_SIGN_UP_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AUTH_HUBSPOT_SIGN_UP_FAIL, payload: res });
  }
};
export const tutorSignUpAction = (payload) => async (dispatch) => {
  dispatch({ type: TUTOR_SIGN_UP });
  const res = await tutorSignUp(payload);
  if (res && res.data && res.data) {
    dispatch({ type: TUTOR_SIGN_UP_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: TUTOR_SIGN_UP_FAIL, payload: res });
  }
};
export const signOutAction = () => async (dispatch) => {
  dispatch({ type: GET_AUTH_SIGN_OUT });
  const res = await signOut();
  if (res && res.data && res.data) {
    dispatch({ type: GET_AUTH_SIGN_OUT_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_AUTH_SIGN_OUT_FAIL, payload: null });
  }
};
export const forgotPasswordAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_FORGOT_PASSWORD });
  const res = await forgotPassword(payload);
  if (res && res.data) {
    dispatch({
      type: GET_AUTH_FORGOT_PASSWORD_SUCCESS,
      payload: res.data,
    });
  } else {
    dispatch({ type: GET_AUTH_FORGOT_PASSWORD_FAIL, payload: res.message });
  }
};
export const resetPasswordAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_AUTH_RESET_PASSWORD });
  const res = await resetPassword(payload);
  if (res && res.data && res.data.data) {
    dispatch({
      type: GET_AUTH_RESET_PASSWORD_SUCCESS,
      payload: res.data.data,
    });
  } else {
    dispatch({ type: GET_AUTH_RESET_PASSWORD_FAIL, payload: null });
  }
};
export const mailchimpAction = (payload) => async (dispatch) => {
  dispatch({ type: MAILCHIMP });
  const res = await mailchimp(payload);
  if (res && res.data) {
    dispatch({
      type: MAILCHIMP_SUCCESS,
      payload: res.data
    });
  } else {
    dispatch({ type: MAILCHIMP_FAIL, payload: null });
  }
};

export const sendNoneToServerAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_SEND_NONCE_TO_SERVER });
  const res = await sendNoneToServer(payload);
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_SEND_NONCE_TO_SERVER_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_SEND_NONCE_TO_SERVER_FAIL, payload: res });
  }
};

export const tvSubscribeAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_TV_SUBSCRIBE });
  const res = await tvSubscribe(payload);
  if (res && res.data && res.success) {
    dispatch({ type: GET_TV_SUBSCRIBE_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_TV_SUBSCRIBE_FAIL, payload: res.data });
  }
};

export const tutorProfileUpdateAction = (payload) => async (dispatch) => {
  dispatch({ type: GET_TUTOR_PROFILE_UPDATE });
  const res = await tutorProfileUpdate(payload);
  if (res && res.data.id) {
      dispatch({ type: GET_TUTOR_PROFILE_UPDATE_SUCCESS, payload: res.data });
  } else {
      dispatch({ type: GET_TUTOR_PROFILE_UPDATE_FAIL, payload: res.data });
  }
};
