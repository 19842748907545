import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import './TutorJobs.scss';
import arrow from '../../../Assets/Tutor/left-arrow.png';
import Loadable from '@loadable/component';
import { CarouselLeftButton2, CarouselRightButton2 } from '../../../Constant';
import { StartButton } from '../../Common';
import { getJobsAction } from "../../../Redux/Actions";

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const Jobs = [
    {
        id: 1,
        name: 'Aswathy A.',
        address: 'Aledo, TX',
        contents: [
            { title: 'Subject', description: 'ACT Prep Math' },
            { title: 'Grade Level', description: 'Test Prep' },
            { title: 'Time', description: 'I really struggle with math, and it’a holding me back from getting the score I want' },
        ]
    },
    {
        id: 2,
        name: 'Aswathy A.',
        address: 'Aledo, TX',
        contents: [
            { title: 'Subject', description: 'ACT Prep Math' },
            { title: 'Grade Level', description: 'Test Prep' },
            { title: 'Time', description: 'I really struggle with math, and it’a holding me back from getting the score I want' },
        ]
    },
    {
        id: 3,
        name: 'Aswathy A.',
        address: 'Aledo, TX',
        contents: [
            { title: 'Subject', description: 'ACT Prep Math' },
            { title: 'Grade Level', description: 'Test Prep' },
            { title: 'Time', description: 'I really struggle with math, and it’a holding me back from getting the score I want' },
        ]
    }
];


class TutorJobs extends React.Component {

    componentDidMount = () => {
        this.props.getJobsAction();
    }

    render() {
        const { jobs } = this.props;
        return (
            <div className="tutorjobs-component">
                <div className="container v-r">
                    <h1>Jobs</h1>
                    <div className="jobs-list show-web-flex v-r">
                        <div className="col-lg-12 jobs v-r">
                            {jobs && (jobs.map((item, index) => <div className="job-item v-c" key={index}>
                                <div className="col-lg-4 user-area v-c">
                                    <div className={`avatar v-r h-c v-c user-${index % 3}`}>{(item.student.name.split(' ')[0]).charAt(0)} {item.student.name.split(' ').length > 1 ? (item.student.name.split(' ')[1]).charAt(0) : ''}</div>
                                    <div className="user-info v-r">
                                        <h2>{(item.student.name.split(' ')[0])} {item.student.name.split(' ').length > 1 ? (item.student.name.split(' ')[1]).charAt(0) : ''}</h2>
                                        {/* <p>from {item.address}</p> */}
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <img src={arrow} alt="arrow" />
                                </div>
                                <div className="col-lg-6 content-list v-r">
                                    {/* {item.contents.map((content, contentIndex) => <div className="content-item" key={contentIndex}>
                                        <b>{content.title}: </b>&nbsp;&nbsp;{content.description}
                                    </div>)} */}
                                    <div className="content-item">
                                        <b>subject: </b>&nbsp;&nbsp;{item.subject.name}
                                    </div>
                                    <div className="content-item">
                                        <b>Grade Level: </b>&nbsp;&nbsp;{item.level.name}
                                    </div>
                                    <div className="content-item">
                                        <b>Time: </b>&nbsp;&nbsp;{item.content}
                                    </div>
                                </div>
                            </div>))}
                        </div>
                        <div className="bg-dot" />
                    </div>
                    <div className="show-mobile-flex v-r">
                        <OwlCarousel
                            className="owl-theme"
                            nav
                            stagePadding={15}
                            margin={15}
                            items={1}
                            dots={false}
                            navText={[CarouselLeftButton2, CarouselRightButton2]}
                        >
                            {Jobs.map((item, index) => {
                                return (
                                    <div className="job-mobile-container v-r" key={index}>
                                        <div className="job-item v-r">
                                            <div className="user-area v-c">
                                                <div className={`avatar v-r h-c v-c user-${item.id}`}>AT</div>
                                                <div className="user-info v-r">
                                                    <h2>{item.name}</h2>
                                                    <p>from {item.address}</p>
                                                </div>
                                            </div>
                                            <div className="content-list v-r">
                                                {item.contents.map((content, contentIndex) => <div className="content-item" key={contentIndex}>
                                                    <b>{content.title}: </b>&nbsp;&nbsp;{content.description}
                                                </div>)}
                                            </div>
                                        </div>
                                        <div className="bg-dot" />
                                    </div>
                                )
                            })}
                        </OwlCarousel>
                    </div>
                    {/* <StartButton onClick={() => this.props.onNext(null)} className="start-btn" /> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    jobs: state.Core.jobs,
});


export default connect(mapStateToProps, {
    getJobsAction,
})(withRouter(TutorJobs));