import React from 'react';
import './style.scss';

export default function QuestionSummary({
  className = '',
  banner 
}) {

  return (
    <div className='pt-summary-wrapper' onClick={() => window.open(banner.link, "_blank", "noreferrer")}>
      <div className='banner-wrapper'>
        <img src={process.env.REACT_APP_IMAGE_DOMAIN + banner.name} className='banner'/>
      </div>
    </div>
  );
}