import React from 'react';
import './GradeTVPage.scss';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
  TVStartArea,
  Channels,
  LatestNews,
  Watch,
  Listen,
  Read,
  Ebook,
  Subscribe,
  BlogFooter
} from "../../Components";

import {
  getGradeTvDataAction,
} from "../../Redux/Actions";
import Helper from '../../Util/Helper';
import _ from 'lodash'

class GradeTVPage extends React.Component {

  componentDidMount() {
    Helper.showSpinner();
    this.props.getGradeTvDataAction().then(() => {
      Helper.hideSpinner();
    });
  };

  render() {
    const { tvDatas } = this.props;
    const meta_content = "Grade TV: Boost Your Knowledge, Boost Your Confidence - A GradeGetter Initiative";
    const meta_description = "Elevate your study experience with Grade TV's in-depth lessons from skilled tutors and stride into your tests with assurance.";
    const meta_image = 'https://gradegetter.com/logos/gradetv-2.jpg'

    return (
      <>
        {tvDatas && <div className="tv-page">
          <Helmet>
            <title>{meta_content}</title>
            <meta name="title" content={meta_content} />
            <meta name="description" content={meta_description} />
            <meta property="type" content="website" />
            <meta property="url" content={`${window.location.origin}/gradeTv`} />
            <meta property="image" content={meta_image} />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={meta_content} />
            <meta itemprop="description" content={meta_description} />
            <meta itemprop="image" content={meta_image} />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={`${window.location.origin}/gradeTv`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta_content} />
            <meta property="og:description" content={meta_description} />
            <meta property="og:image" content={meta_image} />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={meta_content} />
            <meta name="twitter:description" content={meta_description} />
            <meta name="twitter:image" content={meta_image} />
          </Helmet>

          <div className="tv-container">
            <TVStartArea tvDatas={tvDatas} />
            <Channels channels={tvDatas.channels} />
            {/* <LatestNews news={tvDatas.news} testimonials={tvDatas.testimonials} /> */}
            {!_.isEmpty(tvDatas.watch) && <Watch watch={tvDatas.watch} blogs={tvDatas.blogs} />}
            {/* {!_.isEmpty(tvDatas.listen) && <Listen listen={tvDatas.listen} />} */}
            {!_.isEmpty(tvDatas.read) && <Read read={tvDatas.read} />}
            {!_.isEmpty(tvDatas.books) && <Ebook books={tvDatas.books} />}
            <Subscribe />
            <BlogFooter />
          </div>
        </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tvDatas: state.Core.tvDatas,
});

export default connect(mapStateToProps, {
  getGradeTvDataAction
})(withRouter(GradeTVPage));