import React from "react";
import "./Video.scss";
import _ from 'lodash';
import { NavLink } from "react-router-dom";

function innerFunc(item) {
  return {__html: item}
}

export default class Video extends React.Component {
  state = {
    playing1: false,
    playing2: false,
  }

  playVideo1 = (e) => {
      this.videoPlayer1.play();
  }

  playVideo2 = (e) => {
    this.videoPlayer2.play();
  }
  render() {
    const { videos, title } = this.props;
    return (
      <>
        {!_.isEmpty(videos) && <div className="video-component">
          <div className="container video-container">
            <h2 className="title">{title}</h2>
            <div className="row main-row">
              {videos.map((item, index)=> (
                <div className="col-lg-6 item-area" key={index}>
                  <div className="player-area" dangerouslySetInnerHTML={innerFunc(item.code)}/>
                  <p>{item.name}</p>
                </div>
              ))}
              <NavLink className="load-more-btn" exact to="/gradeTV">More</NavLink>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
