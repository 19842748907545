import React from "react";
import "./Hours.scss";
import calendar from "../../../Assets/Icon/calendar.png";

export default class Hours extends React.Component {

  render() {
    const { hours } = this.props;
    return (
      <div className="hours-component">
        <div className="container hours-container">
          <h2 className="title">Opening Hours</h2>
          <div className="row main-row">
            {hours.map((item, index)=> (
              <div className="col-lg-4 item-container" key={index}>
                <div className="item-area">
                  <div className="item-title">{item.title}</div>
                  {item.blocks.map((time_item, time_index)=> (
                    <div className="sub-item" key={time_index}>
                      <div>
                        <img src={calendar} alt="calendar" />
                      </div>
                      <div className="time-container">
                        <p>{time_item.day}:</p>
                        <p>{time_item.time}</p>
                      </div>
                    </div>                  
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
