import {
  FIRST_CHARGE,
  FIRST_CHARGE_SUCCESS,
  FIRST_CHARGE_FAIL,
} from "../Type";
import {
  firstCharge,
} from "../../Api";

export const firstChargeAction = () => async (dispatch) => {
  dispatch({ type: FIRST_CHARGE });
  const res = await firstCharge();
  if (res && res.data && !res.data.error) {
    dispatch({ type: FIRST_CHARGE_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: FIRST_CHARGE_FAIL, payload: res.data });
  }
};
