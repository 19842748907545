import { FEEDBACK_MODAL_TOGGLE, HOMEV2_MODAL_TOGGLE } from '../Type';

export const toggleFeedbackModal = (isOpen, info = {}) => {
  return {
    type: FEEDBACK_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleHomeV2Modal = (isOpen, info = {}) => {
  return {
    type: HOMEV2_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}