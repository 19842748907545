import React from 'react'
import './style.scss'

export default class ComingSoonPage extends React.Component {

    render() {
        return (
            <div className='coming-soon-wrapper'>
                <div className='container'>
                    <h1 className='title'>Coming Soon...</h1>
                </div>
            </div>
        )
    }
}