import { FEEDBACK_MODAL_TOGGLE, HOMEV2_MODAL_TOGGLE } from '../Type';

const INITIAL = {
  info: '',
  isFeedbackModalOpen: false,
  isHomeV2ModalOpen: false
}

export default (state = INITIAL, action) => {
  switch (action.type) {

    case FEEDBACK_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isFeedbackModalOpen: isOpen, info };
    }

    case HOMEV2_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isHomeV2ModalOpen: isOpen, info };
    }

    default:
      return state;
  }
}