import React from "react";
import "./HeaderV2.scss";
import logo from "../../../Assets/Header/logo.png";
import pen_mobile from "../../../Assets/HomeV2/pen-mobile.png";

import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toggleHomeV2Modal } from '../../../Redux/Actions';

class HeaderV2 extends React.Component {
  state = {
    transform: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    this.setState({
      transform: window.scrollY > 0 ? true : false,
    });
  };

  isHomeV3 = () => {
    if (
      this.props.location.pathname.replaceAll("/", "") === "toptutors"
    ) {
      return true;
    }
    return false;
  };

  no = () => {
    this.props.toggleHomeV2Modal(true);
  }

  render() {
    
    return (
      <React.Fragment>
        <div
          className={`header-v2-component ${this.state.transform
              ? "transform-header-v2"
              : ""
            }`}
          style={{ display: !this.isHomeV3() && 'none' }}>
          <div className="container">
            <div className="normal-container">
              <NavLink className="menu-item show-web" exact to="/">
                <img src={logo} alt="logo" />
              </NavLink>
              <NavLink className="menu-item mobile-logo show-mobile" exact to="/" style={{ display: this.state.transform && 'none' }}>
                <img src={logo} alt="logo" />
              </NavLink>
              {/* <NavLink className="menu-item show-mobile" exact to="/" style={{display: !this.state.transform && 'none'}}>
                  <img src={logo2} alt="logo" />
                </NavLink>               */}

              <div className="menu show-web" style={{ display: !this.state.transform && 'none' }}>
                <div className="menu-item description">Want a free trial lesson?</div>
                <NavLink className="menu-item sign-up shadow-object yes-btn show-web v-c h-c" exact to="/sign-up">
                  Yes
                </NavLink>
                <div className="menu-item sign-up shadow-object no-btn show-web v-c h-c" onClick={() => this.no()}>
                  No
                </div>
              </div>
            </div>
            <div className="normal-container show-mobile mt-2" style={{ display: !this.state.transform && 'none' }}>
              <img src={pen_mobile} alt="pen_mobile" />
              <div>
                <div className="menu-item description">Want a free Trial lesson?</div>
                <div className="v-c">
                  <NavLink className="menu-item sign-up shadow-object yes-btn v-c h-c" exact to="/sign-up">
                    Yes
                  </NavLink>
                  <div className="menu-item sign-up shadow-object no-btn v-c h-c" onClick={() => this.no()}>
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, {
  toggleHomeV2Modal
})(withRouter(HeaderV2));
