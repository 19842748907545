import React from "react";
import './style.scss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Header4 extends React.Component {

    hideHeader() {
        const pathName = this.props.location.pathname;
        if (
            pathName === "/sign-in" ||
            pathName === "/forgot-password" ||
            pathName === "/new-password" ||
            pathName === "/sign-up"
            )
        {
            return true;
        }
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <div className="top-menu-component show-web" style={{ display: this.hideHeader() && 'none' }}>
                    <div className="top-menu v-c">
                        <a href="https://gradegetter.com/" className="tag grade-getter" target="_blank" rel="noopener noreferrer">GradeGetter</a>
                        <div className="dot">•</div>
                        <a href="https://makeitmakesense.gradegetter.com/" className="tag" target="_blank" rel="noopener noreferrer">Make It Make Sense</a>
                        <div className="dot">•</div>
                        <a href="https://gradegetter.com/gradetv" className="tag" target="_blank" rel="noopener noreferrer">Grade TV</a>
                        <div className="dot">•</div>
                        <a href="https://icodeedu.com/ " className="tag" target="_blank" rel="noopener noreferrer">iCodeEdu</a>
                        <div className="dot">•</div>
                        <a href="https://gradegetter.com/coming-soon" className="tag" target="_blank" rel="noopener noreferrer">AskFranky</a>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default connect(null)(withRouter(Header4));