import React from 'react'
import {
    PTQuestionHeader,
    PTResourceList
} from 'src/Components'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helper from "../../../Util/Helper"
import {
    getPTSubjectFilesAction,
    getPTFileDownloadLinkAction
} from '../../../Redux/Actions'
import { Helmet } from 'react-helmet';

class TestResources extends React.Component {

    componentDidMount() {
        Helper.showSpinner()
        this.props.getPTSubjectFilesAction(this.props.match.params.id).then(() => {
            Helper.hideSpinner()
        })
    }

    getSubject() {
        const subject = this.props.match.params.subject
        return subject.replace(/-/g, ' ')
    }

    downloadFile = (id, email) => {
        Helper.showSpinner()
        this.props.getPTFileDownloadLinkAction(id, email).then(() => {
            Helper.hideSpinner()
            const { download_link } = this.props
            if (download_link !== null) {
                const link = document.createElement('a');
                link.href = download_link.url;
                link.download = download_link.name;
                link.target = "_blank"
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })
    }

    render() {
        const { files } = this.props
        const subject = this.getSubject()

        const title = `${subject} Practice Test`
        const description = `${subject} Practice test questions & answers`

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <meta name="description" content={description} />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/logos/practice-test-questions.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={title} />
                    <meta itemprop="description" content={description} />
                    <meta itemprop="image" content="https://gradegetter.com/logos/practice-test-questions.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content="https://gradegetter.com/logos/practice-test-questions.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>
                    <meta name="twitter:image" content="https://gradegetter.com/logos/practice-test-questions.png" />       
                </Helmet>
                <PTQuestionHeader subject={subject} />
                {files && <PTResourceList files={files} subject={subject} onDownloadFile={(id, email) => this.downloadFile(id, email)}/>}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    files: state.Question.pt_files,
    download_link: state.Question.pt_file_download_link
})

export default connect(mapStateToProps, {
    getPTSubjectFilesAction,
    getPTFileDownloadLinkAction
})(withRouter(TestResources))