import React from 'react';
import './style.scss';
import {
  QuestionSummary,
  QuestionAnswerFlow,
  QuestionDescription,
  SeeMoreQuestions,
} from 'src/Components';
import PopularQuestionItem from './PopularQuestionItem';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function QuestionDetail({ fullAnswer, questions }) {
  const history = useHistory()
  const onShowQuestion = (item) => { }

  const onSeeMore = () => {
    history.push({pathname: `/common-questions/questions-list/${fullAnswer.subject.name.replace(/\s+/g, '-')}/${fullAnswer.subject.id}`});
  }

  return (
    <div className="question-detail-component">
      <div className="container question-detail-container">
        <div className="row mt-4">
          <div className='col-lg-3 left-content'>
            <div className='questions-list'>
              <h2>Popular questions</h2>
              <div className='subject-list'>
                {questions && questions.sort((a, b) => a.read_count > b.read_count ? 1 : -1)
                  .slice(0, 10)
                  .map((item, index) => (
                    <PopularQuestionItem
                      key={index}
                      info={item}
                      onShowQuestion={onShowQuestion}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-9 right-content">
             <div className="answer-content">
              { fullAnswer && <QuestionDescription Question={fullAnswer}/>}
              {/* <QuestionAnswerFlow
                AnswersInfo={AnswersInfo}
                isLanding={true}
              /> */}
              { fullAnswer && <SeeMoreQuestions onSeeMore={() => onSeeMore()} answer={fullAnswer} />}
            </div>
            <div className='summary'>
              { fullAnswer && <QuestionSummary banner={fullAnswer.subject.banner}/> }
             </div>
          </div>
        </div>
      </div>
    </div>
  );
}