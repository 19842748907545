import React from 'react';
import './style.scss';
// import { togglePlanModal } from 'src/Redux/Actions';
import { ReactSVG } from "react-svg";
import LogoWhite from 'src/Assets/CommonQuestions/logo-white-lg.svg';

export default function QuestionSummary({
  className = '',
  banner
}) {

  return (
    <div className='question-summary-wrapper'>
      <div className={`question-summary-component ${className}`}>
        <div className='right-description-content'>
          <ReactSVG src={LogoWhite} className='small-logo' />
          <div className='num-wrapper'>
            <h2 className='questions-num'>{banner.question_count }</h2>
          </div>
          <h2>{ banner.name }</h2>
          <span className='text-center'>{`Practice questions with video \nexplorations`}</span>
        </div>
        <div className='btn-3 mt-2' onClick={() => window.open(banner.link, "_blank", "noreferrer")}>TRY IT NOW</div>
      </div>
    </div>
  );
}