import React from "react";
import "./Tutors.scss";
import Loadable from "@loadable/component";
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import { Tutor } from "../../";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default class Tutors extends React.Component {
  render() {
    const { tutors } = this.props;
    return (
      <div className="tutors-component" id="section-1-7">
        <div className="container tutors-container">
          <div className="title">Who Are Our Tutors?</div>
          <div className="col-lg-12 main-row">
            <Media
              queries={{
                lg: "(min-width: 1200px)",
                md: "(min-width: 992px) and (max-width: 1199px)",
                sm: "(min-width: 768px) and (max-width: 991px)",
                normal: "(max-width: 767px)",
              }}
            >
              {(matches) => (
                <OwlCarousel
                  className="owl-theme"
                  nav
                  stagePadding={
                    matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 15
                  }
                  margin={
                    matches.lg ? 28 : matches.md ? 33 : matches.sm ? 45 : 20
                  }
                  items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                  dots={false}
                  navText={[CarouselLeftButton, CarouselRightButton]}
                >
                  {tutors.map((item, index) => {
                    return <Tutor key={index} tutor_info={item} team={false}/>;
                  })}
                </OwlCarousel>
              )}
            </Media>
          </div>
        </div>
      </div>
    );
  }
}
