import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './PhoneNumberInput.scss';
import { ReactSVG } from 'react-svg'

export default class PhoneNumberInput extends React.Component {
    state = {
        value: ''
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || ''
        })
    }

    changeText = (phone) => {
        this.setState({
            value: phone
        })
        this.props.onChange(phone);
    }

    render() {
        const { className, icon, placeholder, type } = this.props;
        const { value } = this.state;
        return (
            <div className={`inputbox-component ${className || ''}`}>
                <div className={`phoneInput-container ${className}`}>
                    {icon && <ReactSVG src={icon} style={{fill: '#C5CBD7'}}/> }
                    {/* <input type={type || 'text'} className={`search-text ${!icon ? 'no-icon' : ''}`} placeholder={placeholder} value={value} onChange={e => this.changeText(e)}/> */}
                    <PhoneInput
                        placeholder=""
                        inputClass="w-100 phone"    
                        buttonClass="buttonClass"
                        dropdownClass="dropdown"       
                        country={'us'}
                        value={value}
                        onChange={e => this.changeText(e)}
                    />
                </div>
                <div className="under-dot inputbox-bg" />
            </div> 
        );
    }
}
