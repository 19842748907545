import React from "react";
import "./Topics.scss";
import { Accordion } from "../../../Components";
import { StartButton } from "../../";
import { NavLink } from "react-router-dom";
import _ from 'lodash'

export default class Topics extends React.Component {
  
  render() {
    const { topics } = this.props;
    
    return (
      <>
        {!_.isEmpty(topics) && <div className="topics-component">
          <div className="container topics-container">
            <div className="title">{topics.title}</div>
            <div className="topics-list shadow-object">
              {topics.topics.map((item, index) => {
                return (
                  <Accordion
                    className="accordion"
                    title={item.title}
                    content={item.content}
                    key={index}
                  />
                );
              })}
            </div>
            <NavLink style={{ textDecoration: "none" }} to="/sign-up">
              <StartButton className="start-btn" startText="FREE TRIAL LESSON" />
            </NavLink>
          </div>
        </div>}
      </>
    );
  }
}
