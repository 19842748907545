import React from "react";
import "./Channels.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class Channels extends React.Component {
  state = {
    big_chanel_index: null,
    small_chanel_index: null
  };

  bigChanelHover = (index) => {
    this.setState({ big_chanel_index: index });
  };

  bigChanelHoverOut = () => {
    this.setState({ big_chanel_index: null });
  }

  watching = (item) => {
    var channel_name = item.name.replace(/\s+/g, '-');
    this.props.history.push(`/channel/${channel_name}`);
  }

  render() {
    const { big_chanel_index } = this.state;
    const { channels } = this.props;
    return (
      <div className="channels-component">
        <div className="container">
          <div className="body show-web">
            <Slider
              dots={false}
              infinite={false}
              speed={500}
              slidesToShow={window.innerWidth > 1237 ? 4 : 3}
              slidesToScroll={1}>
              {(channels && channels.length > 0) && channels.map((data, index) => (
                <div className="item" key={index}>
                  <div className="big-chanel-img"
                    // style={{ backgroundImage:`url(${data.image})`}}
                    onMouseOver={() => this.bigChanelHover(index)}
                    onMouseLeave={() => this.bigChanelHoverOut()}>
                    {big_chanel_index === index ?
                      <div className="big-chanel-item-hover">
                        <img src={data.image} alt="logo" className="chalel-cover-img" />
                        <div className="big-chanel-item-content-hover">
                          <p className="big-changel-title-category">{data.channel_type}</p>
                          <div className="hover-details">
                            <p className="big-changel-rule-hover">{data.name}</p>
                            <p className="big-changel-description">{data.description}</p>
                            <div className="start-watch-btn " onClick={(e) => this.watching(data)}>
                              <p className="btn-txt">START WATCHING</p>
                              <div className="overlay" />
                              {/* <ChevronRight className="right-arrow-icon"/> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="big-chanel-item">
                        <img src={data.image} alt="logo" className="chalel-cover-img" />
                        <div className="big-chanel-item-content">
                          <img src={data.logo} alt="logo" className="chalel-logo" />
                          <p className="big-changel-title-category">{data.channel_type}</p>
                          <p className="big-changel-title">{data.name}</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="body show-mobile">
            <Slider
              dots={false}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}>
              {(channels && channels.length > 0) && channels.map((data, index) => (
                <div className="item" key={index} onClick={(e) => this.watching(data)}>
                  <img src={data.image} className="big-chanel-img" alt='chanel-bg' />
                  <div className="big-chanel-item">
                    <div className="big-chanel-item-content">
                      <p className="big-changel-title-category">{data.channel_type}</p>
                      <p className="big-changel-title">{data.name}</p>
                    </div>
                    <div className="start-watch-btn" onClick={(e) => this.watching(data)}>
                      START WATCHING
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {

})(withRouter(Channels));