import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  CQSubjectsPage,
  CQQuestionsListPage,
  CQQuestionPage,
} from "../Pages";

function CQRoutes() {

  return (
    <div>
      <Switch>
        <Route exact path="/common-questions" component={CQSubjectsPage} />
        <Route exact path="/common-questions/questions-list/:subject/:id" component={CQQuestionsListPage} />
        <Route exact path="/common-questions/:subject/:question/:id" component={CQQuestionPage} />
      </Switch>
    </div>
  )
}

export default CQRoutes;
