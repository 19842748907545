import React from 'react';
import './style.scss';

export default function SubjectHeader() {

  return (
    <div className="ws-subject-header-component">
      <div className="container subject-start-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <h1>Worksheets</h1>
          </div>
        </div>
      </div>
    </div>
  );
}