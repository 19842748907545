import { get, post } from "./Api";

export const getCQSubjects = () => {
    return get('/cqsubjects')
}

export const getCQQuestions = (subjectId) => {
    return get(`/cqquestions?cq_subject_id=${subjectId}`)
}

export const getCQFullAnswer = (questionId) => {
    return get(`/cqquestions/${questionId}`)
}

export const getPTSubjects = () => {
    return get('/ptsubjects')
}

export const getPTSubjectFiles = (subjectId) => {
    return get(`/ptsubjects/${subjectId}/files`)
}

export const getPTFileDownloadLink = (id, email) => {
    return post(`/ptfiles/download`, { id, email })
}

export const getFcSubjects = () => {
    return get('/fcsubjects');
}

export const getFcTopics = (id) => {
    return get(`/fcsubjects/${id}/topics`)
}

export const getFlashcards = (topic_id) => {
    return get(`/fcsubjects/${topic_id}/flashcards`)
}