import React from "react";
import './style.scss';
import Question from './Question';
import { QuestionSummary } from "src/Components/CommonQuestions";

export default function TopicQuestions({cards}) {

    return (
        <div className="fc-question-section">
            <div className="container">
                <div className="row row-md section-wrapper">
                    <div className="col-lg-8 left-section">
                        <div className="question-container">
                            { cards.length > 0 && <Question cards={cards} />}
                        </div>
                    </div>
                    <div className="col-lg-4 right-section">
                        { cards.length > 0 && <QuestionSummary banner={ cards[0].banner } /> }
                    </div>
                </div>
            </div>
        </div>
    )
} 