import React from "react";
import "./StartArea.scss";
import cbs from "../../../Assets/Home/CBS.png";
import cnn from "../../../Assets/Home/CNN.png";
import fox from "../../../Assets/Home/FOX.png";
import boston from "../../../Assets/Home/The_Boston_Globe.png";
import miami from "../../../Assets/Home/The-Miami-Herald-Logo.png";
import homeRightImg1 from "../../../Assets/Home/home-rightImg1.png";
import homeRightImg2 from "../../../Assets/Home/home-rightImg2.png";
import homeRightImg3 from "../../../Assets/Home/home-rightImg3.png";
import homeRightImg4 from "../../../Assets/Home/home-rightImg4.png";
import homeRightImg5 from "../../../Assets/Home/home-rightImg5.png";
import { StartButton} from "../../Common";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { NavLink } from "react-router-dom";

export default class StartArea extends React.Component {

  render() {
    const { title, imageText } = this.props;
    return (
      <div className="startarea-component">
        <div className="startarea-content">
          <div className="container startarea-container">
            <div className="main-row">
              <div className="col-lg-6 left-area">
                <div className="left-text">
                  <h1>
                    {title ? title : "1:1 Online Tutoring For K-12, College & Test Prep"}
                  </h1>
                </div>
                <div className="first-lesson">
                  <p>
                    Start with a <strong className="free-highlight">FREE</strong> trial lesson!
                  </p>
                  {/* <p className="mt-3">
                    <strong className="credit-highlight">No credit card is required.</strong>
                  </p> */}
                </div>
                <div className="pay-startBtn">
                  <div className="form-area">
                    <NavLink style={{ textDecoration: "none" }} to="/sign-up">
                      <StartButton
                        className="start-btn"
                        startText="FREE TRIAL LESSON"
                      />
                    </NavLink>
                    {/*<div style={{ marginTop: "20px", color: "grey" }}>Rates starting at $27.5 per hour</div>*/}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 right-area">
                <AliceCarousel
                  autoPlay={true}
                  keysControlDisabled={true}
                  autoPlayInterval={7000}
                  dotsDisabled={true}
                  buttonsDisabled={true}
                >
                  <img src={homeRightImg1} alt={imageText} />
                  <img src={homeRightImg2} alt={imageText} />
                  <img src={homeRightImg3} alt={imageText} />
                  <img src={homeRightImg4} alt={imageText} />
                  <img src={homeRightImg5} alt={imageText} />
                </AliceCarousel>
              </div>
            </div>            

            <div className="row show-web web-logo">
              <div className="col-lg-12 logo-area show-web-flex">
                <img src={cnn} alt="cnn" />
                <img src={cbs} alt="cbs" />
                <img src={fox} alt="fox" />
                <img src={boston} alt="boston" />
                <img src={miami} alt="miami" />
              </div>
            </div>
            <div className="show-mobile mt-5">
              <div className="col-lg-12 logo-area logo-area-mobile show-mobile-flex">
                <img src={cbs} alt="cbs" />
                <img src={cnn} alt="cnn" />
                <img src={fox} alt="fox" />
              </div>
              <div className="col-lg-12 logo-area logo-area-mobile show-mobile-flex">
                <img src={boston} alt="boston" />
                <img src={miami} alt="miami" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
