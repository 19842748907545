import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    FlashcardTopicHeader,
    FlashcardQuestions
} from 'src/Components';
import {
    getFlashcardsAction
} from '../../../Redux/Actions';
import Helper from "src/Util/Helper";
import { Helmet } from "react-helmet";

class FlashcardPage extends React.Component {

    componentDidMount() {
        Helper.showSpinner()
        this.props.getFlashcardsAction(this.props.match.params.id).then(() => {
            Helper.hideSpinner()
        })
    }

    getStructuralData(flashcards, subject, topic) {
        const structuralData = {
            "@context": "https://schema.org/",
            "@type": "Quiz",
            "about": {
                "@type": "Thing",
                "name": topic
            },
            "educationalAlignment": [
                {
                "@type": "AlignmentObject",
                "alignmentType": "educationalSubject",
                "targetName": subject
                }
            ],
            "hasPart": flashcards.map(item => {
                return {
                    "@context": "https://schema.org/",
                    "@type": "Question",
                    "eduQuestionType": "Flashcard",
                    "text": item.question,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": item.answer
                    }
                }
            })
        }

        return structuralData;
    }

    render() {
        const { params } = this.props.match
        const topic = params.topic.replace(/-/g, ' ');
        const subject = params.subject.replace(/-/g, ' ');
        const { flashcards } = this.props

        const title = topic
        const description = topic

        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <meta name="description" content={description} />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={title} />
                    <meta itemprop="description" content={description} />
                    <meta itemprop="image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>
                    <meta name="twitter:image" content="https://gradegetter.com/logos/flashcard.png" />

                    { flashcards && <script type="application/ld+json">{ JSON.stringify(this.getStructuralData(flashcards, subject, topic))}</script>}
                </Helmet>
                <FlashcardTopicHeader topic={topic} subject={subject} />
                { flashcards && <FlashcardQuestions cards={flashcards} /> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    flashcards: state.Question.flashcards
});

export default connect(mapStateToProps, {
    getFlashcardsAction
})(withRouter(FlashcardPage))