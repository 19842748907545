import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";

export default function SubjectItem({
  subject, onClick
}) {

  return (
    <div className='bg-wrapper'>
        <div className="ptest-item-component center shadow-object" onClick={onClick}>
        <img src={ process.env.REACT_APP_IMAGE_DOMAIN + subject.picture } className='logo'/>
            <div className='subject-name mt-1'>{subject.name}</div>
        </div>
        <div className='dotted-bg'></div>
    </div>
  );
}