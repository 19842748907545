import React, { useEffect, useState } from 'react';
import './style.scss';
import QuestionHeaderImg from 'src/Assets/PracticeQuestions/subject-header-img.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash'

export default function QuestionHeader() {
  const history = useHistory()
  const [subject, setSubject] = useState("Biology")

  return (
    <div className="pq-question-header-component">
      <div className="container question-header-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <div>
              <span>Practice Questions</span>
              <span className='opacity'>/</span>
              <span className='opacity'>{subject}</span>
            </div>
            <div>
              <span>PRACTICE QUESTIONS</span>
              <h1>{subject}</h1>
            </div>
          </div>
          <div className="col-lg-6 show-web right-content">
            <div>
              <img src={QuestionHeaderImg} alt="header-img" className="header-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}