import React from "react";
import "./TutorSignUpPage.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import arrow from "../../Assets/Common/left-arrow.svg";
import { ReactSVG } from "react-svg";
import { YesNo } from "./assets";
import {
  SelectBox,
  StartButton,
  InputBox,
  PhoneNumberInput,
  StartButtonDisable,
  TrainingVideo,
  Availability
} from "../../Components";
import shoppingList from "../../Assets/Common/shopping-list.svg";
import telephone from "../../Assets/Common/telephone.svg";
import place from "../../Assets/Common/place.svg";
import add from "../../Assets/Common/add.svg";
import {
  tutorSignUpAction,
  getLevelAction,
  getSubjectAction,
  getSchoolAction,
  getCitiesAction
} from "../../Redux/Actions";
import MultiSelect from "react-multi-select-component";
import { toast } from 'react-toastify';
import { GetDashboardUrl } from "../../Constant";
import Helper from '../../Util/Helper';
import _ from 'lodash'

class TutorSignUpPage extends React.Component {
  state = {
    currentProgress: 2,

    zipcode: "",
    region_id: "",
    phone: "",
    deliver: "",
    referal_code: "",

    college_interest: "",
    college_subject: [],
    college_subjectsMap: [],

    high_interest: "",
    high_subject: [],
    high_subjectsMap: [],

    middle_interest: "",
    middle_subject: [],
    middle_subjectsMap: [],

    elementary_interest: "",
    elementary_subject: [],
    elementary_subjectsMap: [],

    test_interest: "",
    test_subject: [],
    test_subjectsMap: [],

    computer_interest: "",
    computer_subject: [],
    computer_subjectsMap: [],

    language_interest: "",
    language_subject: [],
    language_subjectsMap: [],

    school1_id: 0,
    major: "",
    gpa: "",

    school2_id: 0,
    degree2: "",
    degree2_type: "",

    school3_id: 0,
    degree3: "",
    degree3_type: "",

    profile_title: "",
    fan_facts: "",
    about_me: "",
    experience: "",
    approach: "",
    showPopUp: false,
    college_subject_dropdown: [],
    high_subject_dropdown: [],
    schedule_confirm: false,
    degreeType: [
      { id: 1, name: "Bachelor" },
      { id: 2, name: "Masters" },
      { id: 3, name: "PhD" },
      { id: 4, name: "Dr" }
    ],
    photo: '',
    upload_picture: '',
    parms_validation: null,
    training_status: 0,
    schedule_data: ''
  };

  constructor(props) {
    super(props);
    this.workContainer = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener("keydown", this.checkEnterPress);
    this.props.getLevelAction();
    this.props.getSubjectAction();
    this.props.getSchoolAction();
    this.props.getCitiesAction();

    const { isUser, user, signup_progress } = this.props.location;

    const SignUpState = this.props.location.state;

    if (SignUpState === null && !user) {
      this.props.history.push(`/tutor-home`);
    };

    if (isUser) {
      this.setState({
        training_status: user.training_status,
        currentProgress: signup_progress
      });
    } else {
      this.setState({ currentProgress: 1 });
    };
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.checkEnterPress);
  }

  checkEnterPress = (e) => {
    if (e.keyCode !== 13) return;
  };

  goBack = () => {
    this.props.history.push("/tutor-home");
  };

  changeProgress_1 = (property, value, movePart = 0, subject_level) => {
    this.setState({ [property]: value });
    movePart === 0 ? this.scrollToDom(0, 0) : this.scrollToDom(1, movePart);
    var subjectsMap = [];
    this.props.getSubjectAction(subject_level).then(() => {
      subjectsMap =
        this.props.subjects && this.props.subjects.length
          ? this.props.subjects.map((s) => {
            return {
              label: s.name,
              value: s.id,
            };
          })
          : [];

      switch (subject_level) {
        case 2:
          this.setState({ college_subjectsMap: subjectsMap })
        case 3:
          this.setState({ high_subjectsMap: subjectsMap })
        case 4:
          this.setState({ middle_subjectsMap: subjectsMap })
        case 5:
          this.setState({ elementary_subjectsMap: subjectsMap })
        case 6:
          this.setState({ test_subjectsMap: subjectsMap })
        case 8:
          this.setState({ computer_subjectsMap: subjectsMap })
        case 9:
          this.setState({ language_subjectsMap: subjectsMap })
        default:
          console.log("")
      }
    })
  };

  inputProgress_1 = (property, value) => {
    if (value && value.id) {
      this.setState({ [property]: value.id });
    } else {
      this.setState({ [property]: value });
    }
  };

  selectCollegeSubject = (value, is_delete = false) => {
    let { college_subject } = this.state;
    let index = college_subject.indexOf(value);
    if (is_delete) {
      college_subject.splice(index, 1);
    } else {
      if (index === -1) {
        college_subject.push(value);
      }
    }
    this.setState({ college_subject });
  };

  selectHighSubject = (value, is_delete = false) => {
    let { high_subject } = this.state;
    let index = high_subject.indexOf(value);
    if (is_delete) {
      high_subject.splice(index, 1);
    } else {
      if (index === -1) {
        high_subject.push(value);
      }
    }
    this.setState({ high_subject });
  };

  selectMiddleSubject = (value, is_delete = false) => {
    let { middle_subject } = this.state;
    let index = middle_subject.indexOf(value);
    if (is_delete) {
      middle_subject.splice(index, 1);
    } else {
      if (index === -1) {
        middle_subject.push(value);
      }
    }
    this.setState({ middle_subject });
  };

  selectElementarySubject = (value, is_delete = false) => {
    let { elementary_subject } = this.state;
    let index = elementary_subject.indexOf(value);
    if (is_delete) {
      elementary_subject.splice(index, 1);
    } else {
      if (index === -1) {
        elementary_subject.push(value);
      }
    }
    this.setState({ elementary_subject });
  };

  selectTestSubject = (value, is_delete = false) => {
    let { test_subject } = this.state;
    let index = test_subject.indexOf(value);
    if (is_delete) {
      test_subject.splice(index, 1);
    } else {
      if (index === -1) {
        test_subject.push(value);
      }
    }
    this.setState({ test_subject });
  };

  selectComputerSubject = (value, is_delete = false) => {
    let { computer_subject } = this.state;
    let index = computer_subject.indexOf(value);
    if (is_delete) {
      computer_subject.splice(index, 1);
    } else {
      if (index === -1) {
        computer_subject.push(value);
      }
    }
    this.setState({ computer_subject });
  };

  selectLanguageSubject = (value, is_delete = false) => {
    let { language_subject } = this.state;
    let index = language_subject.indexOf(value);
    if (is_delete) {
      language_subject.splice(index, 1);
    } else {
      if (index === -1) {
        language_subject.push(value);
      }
    }
    this.setState({ language_subject });
  };

  scrollToDom = (section, part) => {
    const heightFromTop =
      (window.outerHeight -
        this.refs[`section${section}${part}`].offsetHeight) /
      2;
    const yToReach =
      this.refs[`section${section}${part}`].offsetTop - heightFromTop;
    this.workContainer.current.scrollTo({
      left: 0,
      top: yToReach,
      behavior: "smooth",
    });
  };

  pickFileForPhoto = (e) => {
    e.preventDefault();
    if (!e.target.files[0]) return;
    this.setState({
      progress_4: {
        ...this.state.progress_4,
        photo: URL.createObjectURL(e.target.files[0]),
      },
    });
  };

  selectCollegeSubjectMap = (e) => {
    this.setState({
      college_subject_dropdown: e,
      college_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  selectHighSubjectMap = (e) => {
    this.setState({
      high_subject_dropdown: e,
      high_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  selectMiddleSubjectMap = (e) => {
    this.setState({
      middle_subject_dropdown: e,
      middle_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  selectElementarySubjectMap = (e) => {
    this.setState({
      elementary_subject_dropdown: e,
      elementary_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  selectTestSubjectMap = (e) => {
    this.setState({
      test_subject_dropdown: e,
      test_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  selectComputerSubjectMap = (e) => {
    this.setState({
      computer_subject_dropdown: e,
      computer_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  selectLanguageSubjectMap = (e) => {
    this.setState({
      language_subject_dropdown: e,
      language_subject: e.map((s) => {
        return { id: s.value, name: s.label };
      }),
    });
  };

  tutorSignUp = () => {
    const SignUpState = this.props.location.state;
    const {
      upload_picture,
      zipcode,
      region_id,
      phone,
      school1_id,
      major,
      major_gpa,

      profile_title,
      fan_facts,
      about_me,
      experience,
      approach,
      college_subject,
      high_subject,
      middle_subject,
      elementary_subject,
      test_subject,
      computer_subject,
      language_subject,
    } = this.state;

    if (SignUpState === null) {
      toast("Please go back and at first fill Individual Information")
      // this.goBack();
    } else {
      this.setState({ parms_validation: true });
      if (
        upload_picture && zipcode && region_id && phone && school1_id && major && major_gpa && profile_title && fan_facts && about_me && experience && approach &&
        college_subject.length +
        high_subject.length +
        middle_subject.length +
        elementary_subject.length +
        test_subject.length +
        computer_subject.length +
        language_subject.length > 0
      ) {
        this.tutorSignUp_server(SignUpState);
      } else {
        toast("Please fill all fields correctly!");
      }

    };
  };

  tutorSignUp_server = (SignUpState) => {
    Helper.showSpinner();

    var classifications = []
    var college_number = [];
    var high_number = [];
    var middle_number = [];
    var middle_number = [];
    var elementary_number = [];
    var test_number = [];
    var computer_number = [];
    var language_number = [];

    if (this.state.college_subject.length > 0) {
      this.state.college_subject.map((data) => {
        college_number.push(data.id)
      })
      classifications.push({ "2": college_number })
    }

    if (this.state.high_subject.length > 0) {
      this.state.high_subject.map((data) => {
        high_number.push(data.id)
      })
      classifications.push({ "3": high_number })
    }

    if (this.state.middle_subject.length > 0) {
      this.state.middle_subject.map((data) => {
        middle_number.push(data.id)
      })
      classifications.push({ "4": middle_number })
    }

    if (this.state.elementary_subject.length > 0) {
      this.state.elementary_subject.map((data) => {
        elementary_number.push(data.id)
      })
      classifications.push({ "5": elementary_number })
    }

    if (this.state.test_subject.length > 0) {
      this.state.test_subject.map((data) => {
        test_number.push(data.id)
      })
      classifications.push({ "6": test_number })
    }

    if (this.state.computer_subject.length > 0) {
      this.state.computer_subject.map((data) => {
        computer_number.push(data.id)
      })
      classifications.push({ "8": computer_number })
    }

    if (this.state.language_subject.length > 0) {
      this.state.language_subject.map((data) => {
        language_number.push(data.id)
      })
      classifications.push({ "9": language_number })
    }

    const formData = new FormData()
    formData.append('address_zipcode', this.state.zipcode);
    formData.append('region_id', this.state.region_id);
    formData.append('phone', this.state.phone);
    formData.append('referrer_code', this.state.referal_code);

    formData.append('first_name', SignUpState.first_name);
    formData.append('last_name', SignUpState.last_name);
    formData.append('email', SignUpState.email);
    formData.append('password', SignUpState.password);
    formData.append('birth_date', "1999-01-12");
    formData.append('classifications', JSON.stringify(classifications));
    formData.append('school1_id', this.state.school1_id);
    formData.append('major', this.state.major);
    formData.append('major_gpa', this.state.major_gpa);

    formData.append('school2_id', this.state.school2_id);
    formData.append('degree2', this.state.degree2);
    formData.append('degree2_type', this.state.degree2_type);
    formData.append('school3_id', this.state.school3_id);
    formData.append('degree3', this.state.degree3);
    formData.append('degree3_type', this.state.degree3_type);
    formData.append('profile_title', this.state.profile_title);
    formData.append('fun_facts', this.state.fan_facts);
    formData.append('about_me', this.state.about_me);
    formData.append('experience', this.state.experience);
    formData.append('approach', this.state.approach);
    if (this.state.upload_picture) {
      formData.append('picture', this.state.upload_picture);
    };

    this.props.tutorSignUpAction(formData).then(() => {
      Helper.hideSpinner();

      const { signErr, user, token } = this.props;
      if (user && token) {
        toast("sign up successful!")
        this.setState({ currentProgress: 2 });
        this.workContainer.current.scrollTo(0, 0);
      } else {
        toast(
          signErr.errors.email.toString() || signErr.message
        );
      };
    });
  };

  scheduleConfirm = (status) => {
    this.setState({ schedule_confirm: true });
  };

  goToAvailability = () => {
    this.setState({ currentProgress: 3 });
  };

  goToDashboard = () => {
    window.location.href = GetDashboardUrl(this.props.user, this.props.token)
  }

  pickFileForPhoto = (e) => {
    e.preventDefault();
    if (!e.target.files[0]) return;
    this.setState({ photo: URL.createObjectURL(e.target.files[0]) });
    this.setState({ upload_picture: e.target.files[0] });
  }

  cityTxt = (cityName) => {
    if (cityName.length % 2 === 0) {
      this.props.getCitiesAction(cityName);
    };
  };

  playVideo = (e) => {
    this.videoPlayer.play();
  };

  updateTrainingStatus = (training_status) => {
    this.setState({ training_status })
  };

  availabilityStatus = (schedule_data) => {
    this.setState({ schedule_data });
  };

  render() {
    const {
      upload_picture,
      currentProgress,
      college_interest,
      college_subject,
      high_interest,
      high_subject,
      middle_interest,
      middle_subject,
      elementary_interest,
      elementary_subject,
      test_interest,
      test_subject,
      computer_interest,
      computer_subject,
      language_interest,
      language_subject,
      zipcode,
      region_id,
      phone,
      school1_id,
      major,
      major_gpa,

      profile_title,
      fan_facts,
      about_me,
      experience,
      approach,
      degreeType,
      photo,
      parms_validation,
      training_status
    } = this.state;
    const { subjects, schools, levels, signErr, cities } = this.props;
    const SignUpState = this.props.location.state;
    return (
      <div className="tutorsignup-page">
        <div className="progress-explain">
          <ReactSVG
            src={arrow}
            style={{
              fill: "white",
              cursor: "pointer",
              top: "32px",
              left: "31px",
              position: "absolute",
            }}
            onClick={(e) => this.goBack()}
          />
          <div className="bg-dot custom-bg" />
          <div className="explain-content">
            {currentProgress === 1 && (
              <div id="section-explain-1">
                <h1>Become a Tutor</h1>
                <p>
                  GradeGetter is the only platform for one on one online
                  Tutoring for K-12, College, and Test Prep where our customers
                  pay what they can afford. This model was influenced by the
                  GradeGetter’s founders Christian faith in his calling to make
                  tutoring available to everyone — not just a select few. We
                  would love to have you join us!
                </p>
              </div>
            )}
            {currentProgress === 2 && (
              <div id="section-explain-2">
                <h2>Complete training</h2>
                <p>

                </p>
              </div>
            )}
            {currentProgress === 3 && (
              <div id="section-explain-3">
                <h2>Set Availability</h2>
                <p>

                </p>
              </div>
            )}
          </div>
        </div>
        <div className="work-area" ref={this.workContainer}>
          <div className="container work-container">
            {currentProgress === 1 && (
              <div className="work-content" id="section-1">
                <div className="row show-mobile-flex" id="section-1-title">
                  <div className="col-lg-12">
                    <h2>Become a tutor</h2>
                  </div>
                </div>
                <div className="row section-1-row" id="section-1-1">
                  <div className="col-lg-12">
                    <h1>Personal Info</h1>
                  </div>
                  <div className="col-lg-12">
                    <div className="photo-area v-c my-2">
                      <div className="initial-name h-c v-c">
                        {photo ? <img src={photo} style={{ width: '100%', height: '100%', borderRadius: '50%' }} alt="avatar" />
                          : <span>{SignUpState.first_name[0] || 'A'} {SignUpState.last_name[0] || 'L'}</span>}
                      </div>
                      <input type="file" accept="image/*" id="image_picker" style={{ display: 'none' }} onChange={e => this.pickFileForPhoto(e)}></input>
                      <label htmlFor="image_picker"><div className={`upload_picture btn-2 btn-2-outline upload_btn v-c h-c ${parms_validation && !upload_picture ? "upload-picture-error" : ""}`}>Upload Photo</div></label>
                      <div className="ml-2">(Linkedin-like profile pic)</div>
                    </div>
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={place}
                      placeholder="Zipcode"
                      onChange={(e) => this.inputProgress_1("zipcode", e)}
                      className={parms_validation && !zipcode ? "inputbox-container-error" : ""}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <SelectBox
                      placeholder="City"
                      show_arrow
                      options={cities || []}
                      onChange={(e) =>
                        this.inputProgress_1("region_id", e)
                      }
                      className={parms_validation && !region_id ? "selectbox-container-error" : ""}
                      changeTxt={(e) => this.cityTxt(e)}
                    />
                    {" "}
                  </div>

                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Have a referal code (optional)"
                      onChange={(e) => this.inputProgress_1("referal_code", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    <PhoneNumberInput
                      icon={telephone}
                      placeholder="Phone"
                      onChange={(e) => this.inputProgress_1("phone", e)}
                      className={parms_validation && !phone ? "phoneInput-container-error" : ""}
                    />{" "}
                  </div>
                </div>

                {/* ------------------------College level subjects------------------------ */}
                <div>
                  <div className="row section-1-row" id="section-1-2" ref="section12">
                    <div className="col-lg-12">
                      {(parms_validation &&
                        college_subject.length +
                        high_subject.length +
                        middle_subject.length +
                        elementary_subject.length +
                        test_subject.length +
                        computer_subject.length +
                        language_subject.length < 1) && (
                          <div className="subject-error-txt">Need 1 interested subject at least!</div>
                        )
                      }
                      <h1>
                        Are you interested in tutoring some college level
                        subjects?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("college_interest", item, index + 3, 2)
                        }
                      >
                        <div
                          className={`grade-level center-item ${college_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-3" ref="section13">
                    {college_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>College level subjects you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {college_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectCollegeSubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.college_subjectsMap}
                              value={this.state.college_subject_dropdown}
                              onChange={(e) => this.selectCollegeSubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ High School subjects----------------- */}
                <div>
                  <div className="row section-1-row" id="section-1-4" ref="section14" >
                    <div className="col-lg-12">
                      <h1>
                        Are you interested in tutoring some high school level subjects?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("high_interest", item, index + 5, 3)
                        }
                      >
                        <div
                          className={`grade-level center-item ${high_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-5" ref="section15" >
                    {high_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>High school level subjects you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {high_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectHighSubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.high_subjectsMap}
                              value={this.state.high_subject_dropdown}
                              onChange={(e) => this.selectHighSubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ Middle  subjects--------------------- */}
                <div>
                  <div className="row section-1-row" id="section-1-6" ref="section16" >
                    <div className="col-lg-12">
                      <h1>
                        Are you interested in tutoring some middle school subjects?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("middle_interest", item, index + 7, 4)
                        }
                      >
                        <div
                          className={`grade-level center-item ${middle_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-7" ref="section17" >
                    {middle_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>Middle school subjects you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {middle_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectMiddleSubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.middle_subjectsMap}
                              value={this.state.middle_subject_dropdown}
                              onChange={(e) => this.selectMiddleSubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ Elementary  subjects----------------- */}
                <div>
                  <div className="row section-1-row" id="section-1-8" ref="section18" >
                    <div className="col-lg-12">
                      <h1>
                        Are you interested in tutoring some elementary School subjects?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("elementary_interest", item, index + 9, 5)
                        }
                      >
                        <div
                          className={`grade-level center-item ${elementary_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-9" ref="section19" >
                    {elementary_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>Elementary School subjects you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {elementary_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectElementarySubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.elementary_subjectsMap}
                              value={this.state.elementary_subject_dropdown}
                              onChange={(e) => this.selectElementarySubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ Test  subjects------------------------ */}
                <div>
                  <div className="row section-1-row" id="section-1-10" ref="section110" >
                    <div className="col-lg-12">
                      <h1>
                        Are you interested in tutoring in test preparation?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("test_interest", item, index + 11, 6)
                        }
                      >
                        <div
                          className={`grade-level center-item ${test_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-11" ref="section111" >
                    {test_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>Test preparation you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {test_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectTestSubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.test_subjectsMap}
                              value={this.state.test_subject_dropdown}
                              onChange={(e) => this.selectTestSubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ Computer  subjects------------------------ */}
                <div>
                  <div className="row section-1-row" id="section-1-12" ref="section112" >
                    <div className="col-lg-12">
                      <h1>
                        Are you interested in tutoring some computer science subjects?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("computer_interest", item, index + 13, 8)
                        }
                      >
                        <div
                          className={`grade-level center-item ${computer_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-13" ref="section113" >
                    {computer_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>Computer science subjects you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {computer_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectComputerSubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.computer_subjectsMap}
                              value={this.state.computer_subject_dropdown}
                              onChange={(e) => this.selectComputerSubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* ------------------------ Languages  subjects------------------------ */}
                <div>
                  <div className="row section-1-row" id="section-1-14" ref="section114" >
                    <div className="col-lg-12">
                      <h1>
                        Are you interested in tutoring languages?
                      </h1>
                    </div>
                    {YesNo.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_1("language_interest", item, 15, 9)
                        }
                      >
                        <div
                          className={`grade-level center-item ${language_interest === item ? "active" : ""
                            }`}
                        >
                          {item}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))}
                  </div>
                  <div id="section-1-15" ref="section115" >
                    {language_interest === 'Yes' && (
                      <div className="row section-1-row">
                        <div className="col-lg-12">
                          <h1>Tutoring language you're interested in tutoring</h1>
                        </div>
                        <div className="col-lg-12">
                          <div className="col-lg-12 subject-container">
                            {language_subject.map((item, index) => (
                              <div className="subject-item v-c" key={index}>
                                <p>{item.name}</p>
                                <ReactSVG
                                  src={add}
                                  className="svg-icon"
                                  onClick={(e) => this.selectLanguageSubject(e, true)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="subject-dorpdown-container">
                            <MultiSelect
                              options={this.state.language_subjectsMap}
                              value={this.state.language_subject_dropdown}
                              onChange={(e) => this.selectLanguageSubjectMap(e)}
                              labelledBy={"Select"}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                {/* ------------------------ Education Part------------------------ */}
                <div className="row section-1-row" id="section-1-6" ref="section116">
                  <div className="col-lg-12">
                    <h1>Education</h1>
                  </div>
                  {/* ------------shcool1---------------   */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <SelectBox
                      icon={shoppingList}
                      placeholder="Undergraduate School"
                      show_arrow
                      options={schools || []}
                      onChange={(e) =>
                        this.inputProgress_1("school1_id", e)
                      }
                      className={parms_validation && !school1_id ? "selectbox-container-error" : ""}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Major"
                      onChange={(e) => this.inputProgress_1("major", e)}
                      className={parms_validation && !major ? "inputbox-container-error" : ""}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col third-col">
                    {" "}
                    <InputBox
                      placeholder="What is your major GPA?"
                      onChange={(e) => this.inputProgress_1("major_gpa", e)}
                      className={parms_validation && !major_gpa ? "inputbox-container-error" : ""}
                    />{" "}
                  </div>
                  {/* ------------shcool2---------------   */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <SelectBox
                      placeholder="Graduate School (optional)"
                      show_arrow
                      options={schools || []}
                      onChange={(e) =>
                        this.inputProgress_1("school2_id", e)
                      }
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Degree"
                      onChange={(e) => this.inputProgress_1("degree2", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col third-col">
                    <SelectBox
                      placeholder="Please select a type"
                      show_arrow
                      options={degreeType || []}
                      onChange={(e) => this.inputProgress_1("degree2_type", e)}
                    />
                  </div>
                  {/* ------------shcool3---------------   */}
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <SelectBox
                      placeholder="Graduate School (optional)"
                      show_arrow
                      options={schools || []}
                      onChange={(e) =>
                        this.inputProgress_1("school3_id", e)
                      }
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Degree"
                      onChange={(e) => this.inputProgress_1("degree3", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    <SelectBox
                      placeholder="Please select a type"
                      show_arrow
                      options={degreeType || []}
                      onChange={(e) => this.inputProgress_1("degree3_type", e)}
                    />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Profile Title</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="Experienced Math & Science Ivy League Tutor"
                      value={profile_title}
                      maxLength="45"
                      onChange={(e) =>
                        this.inputProgress_1("profile_title", e.target.value)
                      }
                      className={parms_validation && !profile_title ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Fun Facts</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="One passion people quickly learn about me is that I LOVE golf! Any time I have a chance, you will find me playing or studying the sport. I am the oldest of seven, and have never been out of Texas."
                      value={fan_facts}
                      // maxLength="50"
                      onChange={(e) =>
                        this.inputProgress_1("fan_facts", e.target.value)
                      }
                      className={parms_validation && !fan_facts ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>About Me</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="I am a senior Accounting major at Texas Christian University. I've been on the Dean's List for 2 years in a row! When I graduate, I hope to attend the University of Texas is Arlington to earn my Masters."
                      value={about_me}
                      onChange={(e) =>
                        this.inputProgress_1("about_me", e.target.value)
                      }
                      className={parms_validation && !about_me ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Experience</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="As a member of National Junior Honor Society, I had the opportunity to tutor students bi-weekly in all subject areas during my senior year in high school."
                      value={experience}
                      onChange={(e) =>
                        this.inputProgress_1("experience", e.target.value)
                      }
                      className={parms_validation && !experience ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
                <div
                  className="row section-1-row"
                  id="section-1-6"
                  ref="section116"
                >
                  <div className="col-lg-12">
                    <h1>Approach</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      placeholder="As a child, I struggled in school and found learning to be difficult and demoralizing. In middle school, I was fortunate enough to have a teacher who, through positive encouragement and persistence."
                      value={approach}
                      onChange={(e) =>
                        this.inputProgress_1("approach", e.target.value)
                      }
                      className={parms_validation && !approach ? "textarea-error" : ""}
                    />
                    <div className="under-dot additional-bg" />
                  </div>
                </div>
              </div>
            )}
            {currentProgress === 2 && (
              <div className="work-content v-r" id="section-2">
                <div className="row show-mobile-flex" id="section-2-title">
                  <div className="col-lg-12">
                    <h2>Become a tutor</h2>
                  </div>
                </div>
                <h4>
                  Thank you for taking the first step in becoming a GradeGetter tutor.
                  Please watch the training videos below to understand how the platform works.
                  Once all videos are watched, a member of our onboarding team
                  will contact you to communicate the next step in the process and verify your account.
                </h4>
                <br />

                {/* <div className="calendly-content">
                  <InlineWidget                    
                    url="https://calendly.com/ds9083923/30min"
                    styles={{height: 900}}
                  />
                </div> */}

                <TrainingVideo
                  training_status={training_status}
                  updateTrainingStatus={(e) => this.updateTrainingStatus(e)}
                />

                <div className="start-button-container">
                  {training_status === 11 ?
                    <StartButton
                      className="start-btn"
                      height="65px"
                      startText="Set Availability"
                      onClick={() => this.goToAvailability()}
                    />
                    :
                    <StartButtonDisable
                      className="start-btn"
                      height="65px"
                      startText="Set Availability"
                    />
                  }
                </div>
              </div>
            )}
            {currentProgress === 3 && (
              <div className="work-content v-r" id="section-2">
                <h4 className="text-center">Set Your Weekly Availability</h4>
                <br />

                <Availability
                  availabilityStatus={(e) => this.availabilityStatus(e)}
                />

                <div className="start-button-container">
                  {!_.isEmpty(this.state.schedule_data) ?
                    <StartButton
                      className="start-btn"
                      height="65px"
                      startText="GO TO DASHBOARD"
                      onClick={() => this.goToDashboard()}
                    />
                    :
                    <StartButtonDisable
                      className="start-btn"
                      height="65px"
                      startText="GO TO DASHBOARD"
                    />
                  }
                </div>
              </div>
            )}
          </div>

          {currentProgress === 1 && (
            <div className={`submit-area`}>
              <div
                className="container submit-container"
                style={{ paddingBottom: window.outerHeight / 2 + 100 }}
              >
                <div className="btn-area" ref="section00">
                  <div
                    className="back-btn"
                    onClick={(e) => this.goBack()}
                  >
                    <div className="back-icon center-item">
                      <ReactSVG src={arrow} style={{ fill: "#C0C5C9" }} />
                    </div>
                    <div className="back-text center-item">Back</div>
                  </div>
                  <div>
                    <StartButton
                      className="start-btn show-web-flex"
                      height="65px"
                      startText="Continue"
                      onClick={(e) => this.tutorSignUp("next")}
                    />
                    <StartButton
                      className="start-btn show-mobile-flex"
                      height="50px"
                      startText="Continue"
                      onClick={(e) => this.tutorSignUp("next")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subjects: state.Core.subjects,
  schools: state.Core.schools,
  cities: state.Core.cities,
  levels: state.Core.levels,
  token: state.Auth.token,
  user: state.Auth.user,
  signErr: state.Auth.signErr,
});

export default connect(mapStateToProps, {
  getLevelAction,
  getSubjectAction,
  tutorSignUpAction,
  getSchoolAction,
  getCitiesAction,
})(withRouter(TutorSignUpPage));


