import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { 
    PQQSubjectsPage,
    PQQTopicsListPage,
    PQQuestionResourcePage
} from '../Pages'

function PQRoutes() {

    return (
        <div>
            <Switch>
                <Route exact path="/practice-questions" component={PQQSubjectsPage} />
                <Route exact path="/practice-questions/topics" component={PQQTopicsListPage} />
                <Route exact path="/practice-questions/files" component={PQQuestionResourcePage} />
            </Switch>
        </div>
    )
}

export default PQRoutes;