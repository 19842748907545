import React from "react";
import "./About.scss";

function innerFunc(item) {
  return {__html: item}
}

export default class About extends React.Component {

  render() {
    const { homeData } = this.props;
    return (
      <div className="about-component">
        <div className="container about-container">
          <h2 className="title">About our {homeData.about.title}</h2>
          <h3 className="description">{homeData.about.subtitle}</h3>
          <div className="row main-row">
            <div className="col-lg-6 order-lg-first order-second left-area">
              <div className="about-details"  dangerouslySetInnerHTML={innerFunc(homeData.about.text)}></div>              
            </div>
            <div className="col-lg-6 order-lg-second order-first right-area ">
              <div className="image-container">
                <img src={homeData.about.image} alt={homeData.about.imagetext}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
