import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import ResourceItem from './ResourceItem'
import { PTSummary } from 'src/Components'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function ResourceList({ files, subject, onDownloadFile }) {
    
    const [fileCount, setFileCount] = useState(10)
    const [showModal, setShowModal] = useState(false)
    const [email, setEmail] = useState("")
    const [fileId, setFileId] = useState(0)
    const inputRef = useRef(null)
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const onDownload = () => {
        if (email === "") {
            inputRef.current.focus()
            toast("Please enter your email")
        } else if (!email.match(validRegex)) {
            inputRef.current.focus()
            toast("Please enter a valid email")
        } else {
            setShowModal(false)
            onDownloadFile(fileId, email)
        }
    }

   const openDialog = (file) => {
        setFileId(file.id)
        setShowModal(true)
    } 

    return (
        <>
            <div className='resource-list-component'>
                <div className='container component-wrapper'>
                    <div className='row'>
                        <div className='col-lg-8 left-section'>
                            <h2>{subject} Practice Test questions & answers </h2>
                            <div className='sub-description'>Select Test you want to discover.</div>
                            <div className='row'>
                                {
                                    files
                                        .slice(0, fileCount)
                                        .map(file => 
                                        <div className='col-lg-6 col-md-12' key={file.id}>
                                                <ResourceItem key={file.id} resource={file} onClick={() => openDialog(file)} />
                                        </div>
                                    )
                                }
                            </div>
                            { fileCount < files.length && <div className='btn-3 load-more-btn' onClick={() => setFileCount(fileCount + 10)}>LOAD MORE</div>}
                        </div>
                        <div className='col-lg-4 right-section'>
                            { files.length > 0 && <PTSummary banner={files[0].banner} />}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="download-file-modal">
                <div className='modal-content'>
                    <div className='form-group mb-0'>
                        <label>Please enter your email.</label>
                        <input className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} ref={inputRef}></input>
                        <button className='btn-3' onClick={() => onDownload()}>Download</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}