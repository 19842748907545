import React from 'react';
import './GradeVideoPage.scss';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

import {
    VideoStartArea,
    VideoList,
    BlogFooter
} from "../../Components";

import {
    getChannelDetailAction,
} from "../../Redux/Actions";
import Helper from '../../Util/Helper';


class GradeVideoPage extends React.Component {

    componentDidMount() {
        Helper.showSpinner();
        this.props.getChannelDetailAction(this.props.match.params.channelName).then(() => {
            Helper.hideSpinner();
        });
    };

    render() {
        const { channelData } = this.props;

        return (
            <>
                {channelData && <div className="video-page">
                    <Helmet>
                        <title>{channelData.meta_title}</title>
                        <meta name="title" content={channelData.meta_title} />
                        <meta name="description" content={channelData.meta_description} />
                        <meta property="type" content="website" />
                        <meta property="url" content={window.location.href} />
                        <meta property="image" content={channelData.cover_image} />

                        {/* <!-- Google / Search Engine Tags --> */}
                        <meta itemprop="name" content={channelData.meta_title} />
                        <meta itemprop="description" content={channelData.meta_description} />
                        <meta itemprop="image" content={channelData.cover_image} />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={channelData.meta_title} />
                        <meta property="og:description" content={channelData.meta_description} />
                        <meta property="og:image" content={channelData.cover_image} />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={channelData.meta_title} />
                        <meta name="twitter:description" content={channelData.meta_description} />
                        <meta name="twitter:image" content={channelData.cover_image} />
                    </Helmet>


                    <div className="video-container">
                        <VideoStartArea channelData={channelData} />
                        <VideoList channelData={channelData} />
                        <BlogFooter />
                    </div>
                </div>}
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    channelData: state.Core.channelData,
});

export default connect(mapStateToProps, {
    getChannelDetailAction
})(withRouter(GradeVideoPage));