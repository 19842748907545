import React from 'react';
import './style.scss';
import {
  QuestionHeader,
  QuestionDetail,
} from 'src/Components';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helper from "../../../Util/Helper"
import {
  getCQFullAnswerAction,
  getCQQuestionsAction
} from "../../../Redux/Actions"
import { Helmet } from 'react-helmet';
class CQQuestionPage extends React.Component {

  state = {
    popularQuestions: null
  }

  getStructuralData(answer) {
    const articleStructuredData = {
      "@context": "https://schema.org",
      "@type": "Article",
      headline: `${answer.subject.name} Commonly Asked Questions`,
      name: answer.name,
      description: answer.short_answer,
      image: process.env.REACT_APP_IMAGE_DOMAIN + answer.picture,
    };
    return articleStructuredData;
  }

  componentDidMount() {
    Helper.showSpinner()
    this.props.getCQFullAnswerAction(this.props.match.params.id).then(() => {
      Helper.hideSpinner()
      // Load popular questions from api if this page is visited directly
      if (this.props.fullAnswer !== null && this.props.questions === null) {
        this.props.getCQQuestionsAction(this.props.fullAnswer.subject.id).then(() => {
          this.setState({ popularQuestions: this.props.questions })
        })
      } else {
        this.setState({ popularQuestions: this.props.questions })
      }
    })
  }

  getSubject() {
    const subject = this.props.match.params.subject
    return subject.replace(/-/g, ' ')
  }

  render() {
    const subject = this.getSubject()
    const { fullAnswer } = this.props
    const { popularQuestions } = this.state

    return (
      <>
        {fullAnswer && <Helmet>
          <title>{fullAnswer.name}</title>
          <meta name="title" content={fullAnswer.name} />
          <meta name="description" content={fullAnswer.short_answer} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/logos/common-questions.png" />

           {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={fullAnswer.name} />
          <meta itemprop="description" content={fullAnswer.short_answer} />
          <meta itemprop="image" content="https://gradegetter.com/logos/common-questions.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={fullAnswer.name} />
          <meta property="og:description" content={fullAnswer.short_answer} />
          <meta property="og:image" content="https://gradegetter.com/logos/common-questions.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={fullAnswer.name}/>
          <meta name="twitter:description" content={fullAnswer.short_answer}/>
          <meta name="twitter:image" content="https://gradegetter.com/logos/common-questions.png" />
          
          { fullAnswer &&  <script type="application/ld+json">{JSON.stringify(this.getStructuralData(fullAnswer))}</script> }
        </Helmet>}
        <div className="cq-question-page">
          <QuestionHeader subject={subject} answer={fullAnswer}/>
          <QuestionDetail
            fullAnswer={fullAnswer}
            questions={popularQuestions}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fullAnswer: state.Question.cq_full_answer,
  questions: state.Question.cq_questions
})

export default connect(mapStateToProps, {
  getCQFullAnswerAction,
  getCQQuestionsAction
})(withRouter(CQQuestionPage))