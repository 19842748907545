import React from "react";
import "./TutorsInCity.scss";
import { ToutorInCity } from "../../";
import { StartButton } from "../../";
import { NavLink } from "react-router-dom";
import _ from 'lodash'

export default class TutorsInCity extends React.Component {
  state = {
    isLoadMore: false
  };

  loadMore = () => {
    this.setState({isLoadMore: !this.state.isLoadMore});
  };

  render() {
    const { isLoadMore } = this.state;
    const { tutor_list } = this.props;
    return (
      <>
        {!_.isEmpty(tutor_list) && <div className="tutors-city-component">
          <div className="container tutors-city-container">
            <h2 className="title">{tutor_list.title}</h2>
            <div className="description">{tutor_list.subtitle}</div>
            <div className="main-tutor-area">
              {tutor_list.tutors.map((item, index)=> (
                <div key={index}>
                  {isLoadMore &&                
                    <ToutorInCity item={item}/>
                  }
                  {!isLoadMore && index < 5 &&
                    <ToutorInCity item={item}/>
                  }
                </div>
              ))}
              <div 
                className="load-more-btn"
                onClick={()=>this.loadMore()}>
                { isLoadMore? "Load Less" : "Load More"}
              </div>
              <NavLink style={{ textDecoration: "none" }} to="/sign-up">
                <StartButton className="start-btn" startText="FREE TRIAL LESSON" />
              </NavLink>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
