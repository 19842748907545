import {
  GET_CONNECTIONS,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
  GET_TUTORS_FAIL,
  GET_TUTORS,
  GET_TUTORS_SUCCESS,
  GET_PLANS,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAIL,
  GET_LEVELS,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAIL,
  GET_SUBJECTS,
  GET_SUBJECTS_FAIL,
  GET_SUBJECTS_SUCCESS,
  GET_SCHOOLS,
  GET_SCHOOLS_FAIL,
  GET_SCHOOLS_SUCCESS,
  GET_SORT_SCHOOLS,
  GET_SORT_SCHOOLS_FAIL,
  GET_SORT_SCHOOLS_SUCCESS,  
  GET_CITIES,
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_BLOG_LIST,
  GET_BLOG_LIST_SUCCESS,
  GET_BLOG_LIST_FAIL,
  GET_BRAINTREEKEY,
  GET_BRAINTREEKEY_FAIL,
  GET_BRAINTREEKEY_SUCCESS,
  GET_JOBS,
  GET_JOBS_FAIL,
  GET_JOBS_SUCCESS,
  GET_CHANNELS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_SUCCESS,
  GET_CHANNEL_DETAIL,
  GET_CHANNEL_DETAIL_FAIL,
  GET_CHANNEL_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_FAIL,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_HOME_DATA,
  GET_HOME_DATA_FAIL,
  GET_HOME_DATA_SUCCESS,
  GET_TV_DATA,
  GET_TV_DATA_FAIL,
  GET_TV_DATA_SUCCESS,
  GET_UNIVERSITY_LADING_DATA,
  GET_UNIVERSITY_LADING_DATA_FAIL,
  GET_UNIVERSITY_LADING_DATA_SUCCESS,
  GET_WIKIPEDIA_DETAIL,
  GET_WIKIPEDIA_DETAIL_FAIL,
  GET_WIKIPEDIA_DETAIL_SUCCESS,
  INIT_APP,
  GET_MIMS_TOPICS,
  GET_MIMS_TOPICS_FAIL,
  GET_MIMS_TOPICS_SUCCESS
} from "../Type";
import {
  getConnections,
  getTutors,
  getPlans,
  getLevels,
  getSubjects,
  getSchools,
  getSortSchool,
  getCities,
  getBlogListing,
  getBraintreekey,
  getJobs,
  getChannels,
  getChannelDetail,
  getVideoDetail,
  getHomeData,
  getGradeTvData,
  getUniversityLandingData,
  getWikipedia,
  getMiMSTopics
} from "../../Api/Core";

export const getConnectionAction = () => async (dispatch) => {
  dispatch({ type: GET_CONNECTIONS });
  const res = await getConnections();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_CONNECTIONS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_CONNECTIONS_FAIL, payload: res.data });
  }
};

export const getTutorAction = () => async (dispatch) => {
  dispatch({ type: GET_TUTORS });
  const res = await getTutors();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_TUTORS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_TUTORS_FAIL, payload: res.data });
  }
};

export const getPlanAction = () => async (dispatch) => {
  dispatch({ type: GET_PLANS });
  const res = await getPlans();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_PLANS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_PLANS_FAIL, payload: res.data });
  }
};

export const getLevelAction = () => async (dispatch) => {
  dispatch({ type: GET_LEVELS });
  const res = await getLevels();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_LEVELS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_LEVELS_FAIL, payload: res.data });
  }
};
export const getSubjectAction = (level_id) => async (dispatch) => {
  dispatch({ type: GET_SUBJECTS });
  const res = await getSubjects(level_id);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_SUBJECTS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_SUBJECTS_FAIL, payload: res.data });
  }
};
export const getSchoolAction = () => async (dispatch) => {
  dispatch({ type: GET_SCHOOLS });
  const res = await getSchools();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_SCHOOLS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_SCHOOLS_FAIL, payload: res.data });
  }
};
export const getSortSchoolAction = (alphabet) => async (dispatch) => {
  dispatch({ type: GET_SORT_SCHOOLS });
  const res = await getSortSchool(alphabet);
  if (res.success && res.data && res.data.data) {
    dispatch({ type: GET_SORT_SCHOOLS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_SORT_SCHOOLS_FAIL, payload: res.data });
  }
};
export const getCitiesAction = (city) => async (dispatch) => {
  dispatch({ type: GET_CITIES });
  const res = await getCities(city);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_CITIES_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_CITIES_FAIL, payload: res.data });
  }
};

export const initAppAction = () => (dispatch) => {
  dispatch({ type: INIT_APP });
};

export const getBlogListingAction = () => async (dispatch) => {
  dispatch({ type: GET_BLOG_LIST });
  const res = await getBlogListing();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_BLOG_LIST_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_BLOG_LIST_FAIL, payload: res.data });
  }
};

export const getBraintreekeyAction = () => async (dispatch) => {
  dispatch({ type: GET_BRAINTREEKEY });
  const res = await getBraintreekey();
  if (res && res.data && !res.data.error) {
    dispatch({ type: GET_BRAINTREEKEY_SUCCESS, payload: res.data});
  } else {
    dispatch({ type: GET_BRAINTREEKEY_FAIL, payload: res.data });
  }
};

export const getJobsAction = () => async (dispatch) => {
  dispatch({ type: GET_JOBS });
  const res = await getJobs();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_JOBS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_JOBS_FAIL, payload: res.data });
  }
};

export const getChannelsAction = () => async (dispatch) => {
  dispatch({ type: GET_CHANNELS });
  const res = await getChannels();
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_CHANNELS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_CHANNELS_FAIL, payload: res.data });
  }
};

export const getChannelDetailAction = (channel_id) => async (dispatch) => {
  dispatch({ type: GET_CHANNEL_DETAIL });
  const res = await getChannelDetail(channel_id);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_CHANNEL_DETAIL_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_CHANNEL_DETAIL_FAIL, payload: res.data });
  }
};

export const getVideoDetailAction = (channel_id) => async (dispatch) => {
  dispatch({ type: GET_VIDEO_DETAIL });
  const res = await getVideoDetail(channel_id);
  if (res && res.data && res.data.data) {
    dispatch({ type: GET_VIDEO_DETAIL_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_VIDEO_DETAIL_FAIL, payload: res.data });
  }
};

export const getHomeDataAction = (city, subject) => async (dispatch) => {
  dispatch({ type: GET_HOME_DATA });
  const res = await getHomeData(city, subject);
  if (res && res.success) {
    dispatch({ type: GET_HOME_DATA_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_HOME_DATA_FAIL, payload: res.data });
  }
};

export const getGradeTvDataAction = () => async (dispatch) => {
  dispatch({ type: GET_TV_DATA });
  const res = await getGradeTvData();
  if (res && res.data && res.success) {
    dispatch({ type: GET_TV_DATA_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_TV_DATA_FAIL, payload: res.data });
  }
};

export const getUniversityLandingDataAction = (university) => async (dispatch) => {
  dispatch({ type: GET_UNIVERSITY_LADING_DATA });
  const res = await getUniversityLandingData(university);
  if (res && res.success) {
    dispatch({ type: GET_UNIVERSITY_LADING_DATA_SUCCESS, payload: res.data });
  } else {
    dispatch({ type: GET_UNIVERSITY_LADING_DATA_FAIL, payload: res.data });
  }
};

export const getWikipediaAction = (city_name) => async (dispatch) => {
  dispatch({ type: GET_WIKIPEDIA_DETAIL });
  const res = await getWikipedia(city_name);
  if (res) {
    dispatch({ type: GET_WIKIPEDIA_DETAIL_SUCCESS, payload: res });
  } else {
    dispatch({ type: GET_WIKIPEDIA_DETAIL_FAIL, payload: res });
  }
};

export const getMiMSTopicsAction = () => async (dispatch) => {
  dispatch({ type: GET_MIMS_TOPICS });
  const res = await getMiMSTopics();
  if (res && res.success) {
    dispatch({ type: GET_MIMS_TOPICS_SUCCESS, payload: res.data.data });
  } else {
    dispatch({ type: GET_MIMS_TOPICS_FAIL, payload: res.data });
  }
}