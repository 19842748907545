import React from 'react';
import './FooterV2.scss';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import arrow from "../../../Assets/HomeV2/arrow-3.png";
import { toggleHomeV2Modal } from '../../../Redux/Actions';

class FooterV2 extends React.Component {

    yes = () => {
        this.props.history.push(`/sign-up`);
    };

    no = () => {
        this.props.toggleHomeV2Modal(true);
    };

    render() {
        return (
            <div className="footer-v2-component v-r">
                <div className="container v-r footer-content">
                <img src={arrow} alt="arrow" className="arrow" />
                    <h1>Do you want to claim your FREE trial tutoring lesson today?</h1>
                    <div className="request-area v-c">
                        <div className="yes-btn shadow-object-2 request-btn v-c h-c" onClick={()=>this.yes()}>
                            YES
                        </div>
                        <div className="no-btn shadow-object-2 request-btn v-c h-c ml-4" onClick={()=>this.no()}>
                            NO
                        </div>
                    </div>
                </div>
                <div className="footer v-c h-c">
                    ©2021 GradeGetter. All rights reserved
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, { 
    toggleHomeV2Modal
})(withRouter(FooterV2));