import React from 'react';
import './FeedbackModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleFeedbackModal } from '../../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'
import { ClientFeedbackV2 } from '../';
import _ from 'lodash'

class FeedbackModal extends React.Component {
    state = {
        isOpen: false,
        info: {}
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info
        }
    }

    closeModal = () => {
        this.props.toggleFeedbackModal(false);
    }

    onHide = () => {
    }

    render() {
        let { isOpen, info } = this.state;
        info = info || {};
        return (
            <Modal show={isOpen} onHide={this.onHide} className="feedback-modal-component">
                <div className="feedback-content v-r">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    {!_.isEmpty(info) && <ClientFeedbackV2 info={{...info}} index={info.index} showAll={true} className="sub-feedback"/> }
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isFeedbackModalOpen,
        info: state.Modal.info,
    }
}
  
export default connect(mapStateToProps, { toggleFeedbackModal })(withRouter(FeedbackModal));
