import React from "react";
import "./Blog.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ArticlePreview } from "../../../Components";
import { getBlogListingAction } from "../../../Redux/Actions";
import _ from 'lodash'

class Blog extends React.Component {
  
  navigateToArticle = (item) => {
    this.props.history.push(`/blog/${item.url}`);
  };

  render() {
    const { blogs } = this.props;
    return (
      <>
        {!_.isEmpty(blogs) && <div className="blog-component">
          <div className="container blog-container">
            <h2 className="title">Education Blog</h2>
            <div className="description">Expert Blog Written By GradeGetter Tutors & Staff</div>
            <div className="row main-row">
              {blogs.map((item, index) => (
                index <2 && (<div className="col-lg-6" key={index}>
                  <ArticlePreview
                    data={item}
                    onClick={(e) => this.navigateToArticle(item)}
                  />
                </div>)
              ))}            
            </div>
          </div>
        </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bloglist: state.Core.bloglist,
  };
}

export default connect(mapStateToProps, { getBlogListingAction })(
  withRouter(Blog)
);
