import React from 'react';
import './OurTeamPage.scss';
import Media from 'react-media';
import mary_pic from '../../Assets/Team/mary.png';
import roland_pic from '../../Assets/Team/roland.png';
import anh_pic from '../../Assets/Team/anh.png';
import yalovyi_pic from '../../Assets/Team/yalovyi.png';
import roman_pic from '../../Assets/Team/roman.png';
import jolly_pic from '../../Assets/Team/jolly.png';
import rica_pic from '../../Assets/Team/rica.png';
import alex_pic from '../../Assets/Team/alex.png';
import kristin_pic from '../../Assets/Team/kristin.png';
import { Tutor, TutorModal } from '../../Components';
import { connect } from 'react-redux';
import { toggleTutorModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';

const members = [
    { name: 'Roland Omene', profile_title: 'CEO', picture: mary_pic, about_me: 'Roland Omene is an agile and versatile strategist with over 10 years’ experience of proven success in brand management & communications in the corporate and education environment in Kenya and the United States. Mary Anne has a Bachelor of Business Administration (BBA) - In marketing and Supply & Value Chain Management from Texas Christian University and an International MBA (IMBA) from IE Business School in Madrid, Spain. Her passion is helping students Get Better Grades through her leadership role at GradeGetter, Mary Anne is a proud mum to two amazing children as well as a published children’s book author.' },
    { name: 'Roland Omene', profile_title: 'Lead Strategist', picture: roland_pic, about_me: 'During his former years at Texas Christian University, Roland began developing FrogTutoring in the confines of his student apartment with the aid of a single laptop. After graduating from TCU with a BS degree in Biochemistry in 2008, Roland finally founded his first company FrogTutoring a year later. FrogTutoring provides one-on-one private home tutoring and now successfully operates in more than 33 cities across the nation. Roland is instrumental in driving the strategic direction of GradeGetter.' },
    { name: 'Anh Pham', profile_title: 'Lead Developer', picture: anh_pic, about_me: 'Anh graduated from Texas Christian University in 2010 with a BS degree in Maths and Computer Science. During his years at TCU, Anh worked with Roland developing his FrogTutoring startup phase. Since graduating, he has been programming and developing the FrogTutoring and Amumba platforms full-time, as well as other major projects that he is involved in. Anh actively maintains and develops all the technical-related and web-based aspects of the GradeGetter business.' },
    { name: 'Denys Yalovyi', profile_title: 'Graphic Designer / Illustrator', picture: yalovyi_pic, about_me: '' },
    { name: 'Alex Tischenko', profile_title: 'Web & Mobile Designer', picture: alex_pic, about_me: '' },
    { name: 'Rica Sichon', profile_title: 'Hiring Associate', picture: rica_pic, about_me: '' },
    { name: 'Roman Ivaniv', profile_title: 'Front end and mobile developer', picture: roman_pic, about_me: '' },
    { name: 'Kristin Burgas', profile_title: 'Recruiting Associate', picture: kristin_pic, about_me: '' },
    { name: 'Jolly Munez', profile_title: 'Student Success Coordinator', picture: jolly_pic, about_me: '' },
];

class OurTeamPage extends React.Component {
    showTutorInfo = (item, rect) => {
        this.props.toggleTutorModal(true, item, rect);
    }

    render() {
        return (
            <div className="ourteam-page">
                <TutorModal />
                <div className="container ourteam-container">
                    <div className="title">Meet the GradeGetter Team!</div>
                    <Media queries={{
                        lg: "(min-width: 1200px)",
                        md: "(min-width: 992px) and (max-width: 1199px)",
                        sm: "(min-width: 768px) and (max-width: 991px)",
                        normal: "(max-width: 767px)",
                        }}>
                        {matches => (
                            <div className={`team-members 
                                ${
                                    matches.lg ? 'member-list-lg' :
                                    matches.md ? 'member-list-md' : 
                                    matches.sm ? 'member-list-sm' : 'member-list-normal'    
                                }`}>
                            {
                                members.map((item, index) => {
                                    return (
                                        <Tutor key={index} tutor_info={item} className="member" team={true} onClick={(rect) => this.showTutorInfo(item, rect)}/>
                                    )
                                })
                            }
                            </div>
                        )}
                    </Media>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps, { toggleTutorModal })(withRouter(OurTeamPage));
  