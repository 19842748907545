import React from "react";
import "./LatestVideo.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PlayArrow } from '@material-ui/icons';
import { VideoModal } from "src/Components";

function innerFunc(item) {
  return { __html: item }
}
class LatestVideo extends React.Component {

  state = {
    isVideoModal: false,
  };

  playVideo = (id) => {
    this.props.history.push(`/gradeVideo/${id}`);
  };

  closeVideoModal = () => {
    this.setState({ isVideoModal: false });
  };

  render() {
    const { latest_video } = this.props;
    return (
      <div className="latest-video-component">
        <h2>LATEST VIDEO</h2>
        <div
          className="latest-video-chanel"
          onClick={() => this.playVideo(latest_video.id)}>
          <div className="video-thumb-content">
            <img
              src={latest_video.meta_image}
              alt="last-video"
              className="video-thumb-img"
            />
            <div className="play-btn-content play-hover">
              <PlayArrow className="play-icon" />
            </div>
          </div>
          <div className="description-content" >
            <h2>
              {latest_video.name}
            </h2>
            <div
              className="video-description des-hover"
              dangerouslySetInnerHTML={innerFunc(latest_video.description)}
            />
          </div>
        </div>
        <VideoModal
          isVideoModal={this.state.isVideoModal}
          closeVideoModal={(e) => this.closeVideoModal(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {

})(withRouter(LatestVideo));