import React, { useState } from 'react';
import './style.scss';
import ResourceItem from './ResourceItem'
import { PQuestionSummary } from 'src/Components'
import { Modal } from 'react-bootstrap';
import ModalImage from 'src/Assets/Worksheets/modal-image.png'

export default function ResourceList() {
    const [showModal, setShowModal] = useState(false);
    const userTypes = [
        { id: 1, name: "Student" },
        { id: 2, name: "Teacher" },
        { id: 3, name: "Parent" },
    ];
    const [userType, setUserType] = useState(userTypes[0].id);

    const ResourceList = [
        { id: "1", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "2", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "3", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "4", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "5", title: "1st Grade Math Worksheets.pdf", size: "20 MB"},
        { id: "6", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "7", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "8", title: "1st Grade Math Worksheets.pdf", size: "20 MB"},
    ]

    const onRadioChange = (value) => {

    } 

    function RadioItem({ selected, name, onClick }) {
        return (
            <div className={`radio-item ${ selected ? 'selected' : '' }`} onClick={() => onClick()}>
                <div className='circle-out'>
                    <div className='circle-in'></div>
                </div>
                <div className='name'>{name}</div>
            </div>
        )
    } 

    return (
        <>
            <div className='ws-resource-list-component'>
                <div className='container component-wrapper'>
                    <div className='row'>
                        <div className='col-lg-8 left-section'>
                            <h2 className=''>Files</h2>
                            <div className='row'>
                                {
                                    ResourceList.map(resource => 
                                        <div className='col-lg-6 col-md-12'>
                                            <ResourceItem key={resource.id} item={resource} onClick={() => setShowModal(true)} />
                                        </div>
                                    )
                                }
                            </div>
                            <div className='btn-3 load-more-btn'>LOAD MORE</div>
                        </div>
                        <div className='col-lg-4 right-section'>
                            <PQuestionSummary />
                        </div>
                    </div>
                </div>
            </div>
            <Modal className='worksheet-modal' onHide={() => setShowModal(false)} show={showModal}>
                <div className='header-top'>
                    <img src={ModalImage} />
                    <h4>Where do you want us to send your worksheet?</h4>
                </div>
                <div className='form-section'>
                    <h6>General Info</h6>
                    <div className='form-group'>
                        <input type='text' className='form-control' placeholder='Full Name'/>
                    </div>
                     <div className='form-group'>
                        <input type='email' className='form-control' placeholder='Email'/>
                    </div>
                    <div className='user-type'>
                        <h6>Select type of user</h6>
                        <div className='selection-list'>
                            {userTypes.map(item => (
                                <RadioItem
                                    name={item.name}
                                    key={item.id}
                                    selected={item.id === userType}
                                    onClick={() => setUserType(item.id)} />
                           ))}
                        </div>
                    </div>

                </div>
           </Modal>
        </>
    )
}