import React from "react";
import "./FAQ.scss";
import { Accordion } from "../../../Components";
import { StartButton } from "../../";
import { NavLink } from "react-router-dom";

const faqs = [
  {
    title: "Faith",
    content: [
      "GradeGetter is a Christian company that provides one on one online tutoring for K - 12, college, and test preparation. We work with students from all backgrounds, regardless of age, gender, religious affiliation, or academic capability.",
      "We believe our faith in Christ is inseparable from our work and that our work is a form of worship.",
      "We believe in seeking first the kingdom of God and His righteousness, and everything else will be added to you.  Matthew 3:33",
      "We believe in always putting God first. - Matt 3:33"
    ],
  },
  {
    title: "Family",
    content: [
      "We believe to deliver on our mission as a company we need to be a tight-knit family.",
      "We believe our commitment to each other makes us unbreakable.",
      "We believe in celebrating wins.",
      "We believe communication is irreplaceable.",
      "We believe communion and fellowship has no substitute.",
      "We believe in appreciating each other.",
      "We believe in looking out for each other.",
    ]
  },
  {
    title: "Excellence",
    content: [
      "We believe patience and persistence wins the day.",
      "We believe we’re more than tutors, we’re mentors and leaders.",
      "We believe in the continued pursuit of knowledge.",
      "We believe whatever you do, work heartily, as for the Lord and not for men. Colossians 3:23.",
      "We believe in excelling in knowledge and grace - 2 Corinthians 8:7.",
      "We believe in being a model of good works in all respect, and in your teaching show integrity and dignity - Titus 2:7.",
      "We believe we’re a chosen people, a royal priesthood, call to serve others through our gift of teaching. - 1 Peter 2: 9."
    ]
  },
  {
    title: "Empathy",
    content: [
      "We believe the golden rule is more important and valuable than a free avocado toast.",
      "We believe courtesy should not be based on the size of one’s nest egg.",
      "We inspired by God’s empathy in John 3:16.",
      "We believe whatever you wish that others do for you, do also to them - Matthew 7:22.",
      "We believe in rejoicing with those who rejoice and weep with those who weep. Romans 12:15.",
      "We believe in loving others as Christ loves us. - John 15:12.",
      "We believe in building up, not tearing down. - Ephesians 4:29.",
      "We believe a single failure is a failure for the entire team and a single win is a win for the entire team. - I Corinthians 12:26 or."
    ]
  },
];

export default class FAQ extends React.Component {
  
  render() {
    const {isStartBnt} = this.props
    return (
      <div className="faqs-component">
        <div className="container faqs-container">
          <h2 className="title">FAQs</h2>
          <div className="faqs-list shadow-object">
            {faqs.map((item, index) => {
              return (
                <Accordion
                  className="accordion"
                  title={item.title}
                  content={item.content}
                  key={index}
                />
              );
            })}
          </div>
          {isStartBnt && <NavLink style={{ textDecoration: "none" }} to="/sign-up">
            <StartButton className="start-btn" startText="FREE TRIAL LESSON" />
          </NavLink>}
        </div>
      </div>
    );
  }
}
