/* @flow */

import { combineReducers } from "redux";

import Sidebar from "./Sidebar";
import Tutor from "./Tutor";
import Core from "./Core";
import Auth from "./Auth";
import Modal from "./Modal";
import Payment from "./Payment";
import Question from "./Question";

export default combineReducers({
  Sidebar,
  Tutor,
  Core,
  Auth,
  Modal,
  Payment,  
  Question,
});
