import React from 'react';
import './DemoPage.scss';
import { StartButton } from '../../Components';
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default class DemoPage extends React.Component { 

    componentDidMount(){
        var stored = localStorage.getItem('demoRoom')

        if (!stored || stored.indexOf('room.sh') === -1) {
            stored = null
        }

        fetch("https://api.thelessonspace.com/v2/demo/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({desired_room: stored})
        }).then(function (response) {
            response.json().then(function (j) {
                localStorage.setItem('demoRoom', j.url)
                document.getElementById("lessonspaceDemoIframe").src = j.url
            })
        });
    }

    render() {
        return (
            <div className="demo-page">
                <Helmet>
                    <title>GradeGetter Online Tutoring Demo</title>
                    <meta name="title" content="GradeGetter Online Tutoring Demo" />
                    <meta name="description" content="Gradegetter online tutoring demo lets you practice using our online tutoring software at no cost." />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content="GradeGetter Online Tutoring Demo" />
                    <meta itemprop="description" content="Gradegetter online tutoring demo lets you practice using our online tutoring software at no cost." />
                    <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="GradeGetter Online Tutoring Demo" />
                    <meta property="og:description" content="Gradegetter online tutoring demo lets you practice using our online tutoring software at no cost." />
                    <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="GradeGetter Online Tutoring Demo"/>
                    <meta name="twitter:description" content="Gradegetter online tutoring demo lets you practice using our online tutoring software at no cost."/>
                    <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
                </Helmet>
                <div className="container demo-container">
                    <div className="title">The Best Online Tutoring Platform. Try It Today!</div>
                    <div className="send-box">
                        <input className="email-input" placeholder="Enter your Email"/>
                        <button className="send-btn">FREE TRIAL LESSON</button>
                        <div className="bg-dot" alt="bgdot"/>
                    </div>
                    <div className="demo-list shadow-object">
                        {/* <img src={bg} alt="bg" /> */}
                        <iframe id="lessonspaceDemoIframe" frameborder="0" allow="camera; microphone; display-capture" src="about:blank" style={{width:'100%', height:'100%'}}>
                        </iframe>
                    </div>
                    <NavLink style={{ textDecoration: "none" }} to="/sign-up">
                        <StartButton 
                            className="start-btn" 
                            startText="FREE TRIAL LESSON"
                        />                        
                    </NavLink>
                </div>
            </div>
        );
    }
}