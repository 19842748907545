import React from 'react';
import './HomeV2Modal.scss';
import { Modal } from 'react-bootstrap';
import { toggleHomeV2Modal } from '../../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../../Assets/Icon/add.svg';
import email_icon from '../../../Assets/Common/email.svg';
import { ReactSVG } from 'react-svg'

class HomeV2Modal extends React.Component {
    state = {
        email: '',
        comment: '',
        isOpen: false,
        info: {}
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info
        }
    }

    closeModal = () => {
        this.props.toggleHomeV2Modal(false);
    }

    onHide = () => {
    }

    schedule = () => {
        window.open("https://meetings.hubspot.com/roland62/answered-no-if-they-want-free-lesson-");
    }

    submit = () => {
        this.props.toggleHomeV2Modal(false);
    }

    render() {
        let { isOpen, info, email, comment } = this.state;
        info = info || {};
        return (
            <Modal show={isOpen} onHide={this.onHide} className="homev2-modal-component">
                <div className="homev2-content v-r">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="title">Sorry to hear that you don't want a free trial lesson with one of our tutors.</div>
                    <div className="help-title mt-3">How can we be of help?</div>
                    <div className="v-c mt-3">
                        <div className="cagegory-content v-c h-c">A</div>
                        <div className="call-me-txt ml-2">Call me</div>
                        <div className="call-back-btn v-c h-c ml-2 shadow-object" onClick={()=>this.schedule()}>Click here to schedule a call back</div>
                    </div>
                    <div className="submit-area mt-3">
                        <div className="cagegory-content v-c h-c">B</div>
                        <div className="ml-2 comment-container"> 
                            <div className="comment-area">
                                <div className="input-header v-c">
                                    <div className="ml-2"><ReactSVG src={ email_icon } className='email-icon'/></div>
                                    <div className="input-container ml-1">
                                        <input className="custom-input"
                                            placeholder="Your email"
                                            onChange={e => this.setState({email: e.target.value})} 
                                        />
                                    </div>
                                </div>
                                <div className="text-area">
                                    <textarea
                                        placeholder="Your comment"
                                        value={comment}                                    
                                        onChange={(e) =>this.setState({comment: e.value})}
                                        rows="5"
                                    />
                                </div>
                            </div>
                            <div className="submit-btn v-c h-c mt-2" onClick={()=>this.submit()}>Submit</div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isHomeV2ModalOpen,
        info: state.Modal.info,
    }
}
  
export default connect(mapStateToProps, { 
    toggleHomeV2Modal 
})(withRouter(HomeV2Modal));
