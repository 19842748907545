import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { initAppAction } from "../Redux/Actions";

import { connect } from "react-redux";
import {
  HomePage,
  CitySubjectLadingPage,
  SubjectLandingPage,
  CityLandingPage,
  UniversityListPage,
  UniversityLandingPage,
  AboutUsPage,
  FeaturesPage,
  FAQsPage,
  ContactUsPage,
  OurTeamPage,
  SiteInfoPage,
  AuthPage,
  BlogListPage,
  BlogArticlePage,
  SignUpPage,
  DemoPage,
  ValuesPage,
  // PricePage,
  GradeTVPage,
  GradeVideoPage,
  GradeVideoDetailPage,
  TutorHomePage,
  TutorSignupPage,
  SummerEnrichmentPage,
  ComingSoonPage
} from "../Pages";
import { GetDashboardUrl } from "../Constant";
import _ from 'lodash'

class CustomRoutes extends Component {

  componentDidMount() {
    this.props.initAppAction();
    this.intercomSetting();

    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const token = localStorage.getItem("token");
    if (user && token) {
      if (user.role === 'tutor') {
        if (!_.isEmpty(user.schedule)) {
          window.location.href = GetDashboardUrl(user, token);
        };
      };

      if (user.role === 'student') {
        if (user.can_book_trial || user.plan_frequency || user.trial_lesson.status === 'pending') {
          window.location.href = GetDashboardUrl(user, token)
        };
      };
    };
  };

  intercomSetting = () => {
    window.intercomSettings = {
      app_id: "mx9avtti"
    };
  };

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/features" component={FeaturesPage} />
          <Route exact path="/faqs" component={FAQsPage} />
          <Route exact path="/terms" component={SiteInfoPage} />
          <Route exact path="/privacy" component={SiteInfoPage} />
          <Route exact path="/refund" component={SiteInfoPage} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/team" component={OurTeamPage} />
          <Route exact path="/sign-in" component={AuthPage} />
          <Route exact path="/sign-up" component={SignUpPage} />
          <Route exact path="/forgot-password" component={AuthPage} />
          <Route exact path="/new-password" component={AuthPage} />
          <Route exact path="/blog" component={BlogListPage} />
          <Route exact path="/blog/:url" component={BlogArticlePage} />
          <Route exact path="/demo" component={DemoPage} />
          <Route exact path="/values" component={ValuesPage} />
          <Route exact path="/gradeTV" component={GradeTVPage} />
          <Route exact path="/channel/:channelName" component={GradeVideoPage} />
          <Route exact path="/gradeVideo/:id" component={GradeVideoDetailPage} />
          <Route exact path="/tutor-home" component={TutorHomePage} />
          <Route exact path="/tutor-signup" component={TutorSignupPage} />
          <Route exact path="/camp" component={SummerEnrichmentPage} />

          <Route exact path="/tutors" component={CitySubjectLadingPage} />
          <Route exact path="/tutors/:region/:subject" component={CitySubjectLadingPage} />
          <Route exact path="/tutors-subject/:subject" component={SubjectLandingPage} />
          <Route exact path="/tutors-region/:region" component={CityLandingPage} />
          <Route exact path="/USA/University" component={UniversityListPage} />
          <Route exact path="/USA/University/:university" component={UniversityLandingPage} />
          <Route exact path="/coming-soon" component={ComingSoonPage} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {

};

export default connect(mapStateToProps, { initAppAction })(CustomRoutes);
