import React from "react";
import "./Location.scss";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { StartButton } from "../../";
import { NavLink } from "react-router-dom";
import _ from 'lodash'

export class Location extends React.Component {
  
  render() {
    const { city } = this.props;    
    return (
      <>
        {!_.isEmpty(city) && <div className="location-component">
          <div className="container location-container">
            <h2 className="title">{city.name}</h2>
            <div className="row main-row">
              <div className="col-lg-6 order-lg-first order-second left-area">
                <div className="location-details">{city.description}</div>
              </div>
              <div className="col-lg-6 order-lg-second order-first right-area ">
                <div className="map-wrapper">
                  <div className="map-container">
                    <Map
                        google={this.props.google}
                        zoom={8}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                        initialCenter={{ lat: city.lattitude, lng: city.longitude }}
                    >
                        <Marker position={{ lat: city.lattitude, lng: city.longitude }} />
                    </Map>
                  </div>
                </div>
              </div>
            </div>
            <NavLink style={{ textDecoration: "none" }} to="/sign-up">
              <StartButton className="start-btn" startText="FREE TRIAL LESSON" />
            </NavLink>
          </div>
        </div>}
      </>
    );
  }
}

export default GoogleApiWrapper(
  (props) => ({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      language: 'en',
      region: 'us'
  }
))(Location)