import React from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function SeeMoreQuestions({ onSeeMore, answer }) {

  return (
    <div className="see-more-questions-component">
      <div className='btn-3' onClick={onSeeMore}>See more commonly asked { answer.subject.name } questions</div>
    </div>
  );
}