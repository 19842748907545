import React, { useState } from "react";
import './style.scss'
import RightButton from '../../../../Assets/Flashcard/ButtonRight.png';
import LeftButton from '../../../../Assets/Flashcard/ButtonLeft.png';
import CustomToggle from "../../CustomToggle";


export default function Question({cards}) {
    const [flipped, setFlipped] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(cards[0].id);
    const [lightTheme, setLightTheme] = useState(false);

    function innerFunc(item) {
        return { __html: item }
    }

    const getCurrentIndex = () => {
        const currentIndex = cards.findIndex(item => {
            return item.id === activeQuestion;
        })
        return currentIndex;
    }

    const flipCard = () => {
        const flashcard = document.getElementById('QuestionFlashCard')
        if (flashcard) {
            if (flashcard.classList.contains('flipped')) {
                flashcard.classList.remove('flipped')
                setFlipped(false)
            } else {
                flashcard.classList.add('flipped')
                setFlipped(true);
            }
       }
    }

    const resetCard = () => {
        const flashcard = document.getElementById('QuestionFlashCard')
       if (flashcard && flashcard.classList.contains('flipped')) {
            flashcard.classList.remove('flipped')
            setFlipped(false)
        } 
    }

    const onNextQuestion = () => {
        const currentIndex = getCurrentIndex()
        if (currentIndex !== -1 && currentIndex < cards.length - 1) {
            const nextQuestion = cards[currentIndex + 1]
            setActiveQuestion(nextQuestion.id)
            resetCard()
        }
    }

    const onPrevQuestion = () => {
        const currentIndex = getCurrentIndex()
        if (currentIndex !== -1 && currentIndex > 0) {
            const prevQuestion = cards[currentIndex - 1];
            setActiveQuestion(prevQuestion.id)
            resetCard()
        }
    }

    const onThemeChange = (value) => {
        const flashcard = document.getElementById('QuestionFlashCard');
        if (flashcard) {
            if (value) {
                flashcard.classList.add('light')
            } else {
                flashcard.classList.remove('light')
            }
        }
        setLightTheme(value)
    }

    function QuestionContent({card}) {
        return (
            <div className="question-content">
                <div className="front">
                    <div dangerouslySetInnerHTML={innerFunc(card.question)}></div>
                </div>
                <div className="back">
                    <div dangerouslySetInnerHTML={innerFunc(card.question)}></div>
                    <p className="mt-1" dangerouslySetInnerHTML={innerFunc(card.answer)}></p>
                </div>
            </div>
        )
    } 

    return (
        <div className="flashcard-section">
            <div className="items-shown"><span>{ getCurrentIndex() + 1}</span> of {cards.length}</div>
            <div className="question-card-wrapper" id="QuestionFlashCard">
                {
                    cards.map(question => (
                        <div className={question.id === activeQuestion ? 'appear' : 'd-none' } key={question.id}>
                            <QuestionContent card={question} />
                        </div>
                    ))
                }
               { activeQuestion !== cards[0].id && <img className="prev" src={LeftButton} onClick={() => onPrevQuestion()}></img>}
                { activeQuestion !== cards[cards.length - 1].id && <img className="next" src={RightButton} onClick={() => onNextQuestion()}></img>}
            </div>
            <div className="toggle-wrapper">
                <CustomToggle disable={false} onChange={(value) => onThemeChange(value)} value={ lightTheme } />
            </div>
           { !flipped && <div className='btn-3 flip-card-btn' onClick={() => flipCard()}>FLIP CARD</div>}
        </div>
    )
}