import React, { useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import SubjectItem from './SubjectItem';
import { QuestionSummary } from 'src/Components';

export default function SubjectList({ subjects }) {
  let history = useHistory();
  const [questionCount, setQuestionCount] = useState(10);

  const onSubject = (item) => {
    const name = item.name.replace(/\s+/g, '-')
    history.push({pathname: `/common-questions/questions-list/${name}/${item.id}`});
  }

  return (
    <div className="subject-lists-component">
      <div className="container home-start-container">
        <h2>Subjects</h2>
        <div className='sub-description'>Select subject you want to discover</div>
        <div className="row mt-4">
          <div className="col-lg-8 left-content">
            <div className='subject-list'>
              {subjects && subjects
                .slice(0, questionCount)
                .map(item => (
                  <SubjectItem
                    key={item.id}
                    info={item}
                    onClick={() => onSubject(item)}
                  />
                ))}
            </div>
            { questionCount < subjects.length && <div className='btn-3 load-more-btn' onClick={() => setQuestionCount(questionCount + 10)}>LOAD MORE</div>}
          </div>
          <div className="col-lg-4 right-content">
            {subjects.length > 0 && <QuestionSummary banner={subjects[0].banner} />}
          </div>
        </div>
      </div>
    </div>
  );
}