import React, { useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import SubjectItem from './SubjectItem';
import { PTSummary } from 'src/Components';

export default function SubjectList({ subjects }) {
  let history = useHistory();
  const [subjectCount, setSubjectCount] = useState(10)

  const onSubject = (subject) => {
    history.push(`/practice-tests/files/${subject.short_name.replace(/\s+/g, '-')}/${subject.id}`);
  }

  return (
    <div className="ptest-lists-component">
      <div className="container home-start-container">
        <h2>Subjects</h2>
        <div className='sub-description'>Select subject you want to discover</div>
        <div className="row mt-4">
          <div className="col-lg-8 left-content">
            <div className='subject-list'>
              {subjects.slice(0, subjectCount)
                .map(item => (
                <SubjectItem
                  key={item.id}
                  subject={item}
                  onClick={() => onSubject(item)}
                />
              ))}
            </div>
            { subjectCount < subjects.length && <div className='btn-3 load-more-btn' onClick={() => setSubjectCount(subjectCount + 10)}>LOAD MORE</div>}
          </div>
          <div className="col-lg-4 right-content">
            { subjects.length > 0 && <PTSummary banner={subjects[0].banner}/>} 
          </div>
        </div>
      </div>
    </div>
  );
}