import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    FlashcardSubjectHeader,
    FlashcardSubjectList
} from 'src/Components';
import {
    getFcSubjectsAction
} from '../../../Redux/Actions';
import { Helmet } from "react-helmet";
import Helper from "src/Util/Helper";

class FlashcardSubjectsPage extends React.Component {

    componentDidMount() {
        Helper.showSpinner()
        this.props.getFcSubjectsAction().then(() => {
            Helper.hideSpinner()
        })
    }

    render() {
        const { subjects } = this.props
        const title = "Flashcards for Test Prep & Academics - Master Subjects Easily"
        const description = "Explore our flashcards for efficient topic reviews. Perfect for test prep and academic subjects. Start mastering today!"

        return (
            <div>
                 <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <meta name="description" content={description} />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={title} />
                    <meta itemprop="description" content={description} />
                    <meta itemprop="image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>
                    <meta name="twitter:image" content="https://gradegetter.com/logos/flashcard.png" />
                </Helmet>
                <FlashcardSubjectHeader />
                { subjects && <FlashcardSubjectList subjects={subjects} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    subjects: state.Question.fc_subjects
});

export default connect(mapStateToProps, {
    getFcSubjectsAction
})(withRouter(FlashcardSubjectsPage))