import React from 'react';
import './GradeVideoDetailPage.scss';
import {
    VideoDetail,
    BlogFooter
} from "../../Components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';

import {
    getVideoDetailAction,
} from "../../Redux/Actions";

class GradeVideoDetailPage extends React.Component {

    state = {

    };

    componentDidMount() {
        this.props.getVideoDetailAction(this.props.match.params.id);
    };

    componentDidUpdate(prevProps){
        if(prevProps.match.params.id !== this.props.match.params.id){
            window.location.reload();
        }
        if(!this.props.videoDetailData){
            window.location.reload();
        }
    }

    render() {
        const {videoDetailData} = this.props;
        return (
            <>
                {videoDetailData && <div className="video-detail-component">
                    <Helmet>
                        <title>{videoDetailData.meta_title}</title>
                        <meta name="title" content={videoDetailData.meta_title} />
                        <meta name="description" content={videoDetailData.meta_description} />
                        <meta property="type" content="website" />
                        <meta property="url" content={`${window.location.origin}/gradeVideo/${videoDetailData.id}`}/>
                        <meta property="image" content={videoDetailData.meta_image} />

                        {/* <!-- Google / Search Engine Tags --> */}
                        <meta itemprop="name" content={videoDetailData.meta_title} />
                        <meta itemprop="description" content={videoDetailData.meta_description} />
                        <meta itemprop="image" content={videoDetailData.meta_image} />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta property="og:url" content={`${window.location.origin}/gradeVideo/${videoDetailData.id}`} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={videoDetailData.meta_title} />
                        <meta property="og:description" content={videoDetailData.meta_description} />
                        <meta property="og:image" content={videoDetailData.meta_image} />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={videoDetailData.meta_title}/>
                        <meta name="twitter:description" content={videoDetailData.meta_description}/>
                        <meta name="twitter:image" content={videoDetailData.meta_image}/>
                    </Helmet>

                    <VideoDetail videoDetailData={videoDetailData}/>
                    <BlogFooter />
                </div>}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    videoDetailData: state.Core.videoDetailData,
});

export default connect(mapStateToProps, {
    getVideoDetailAction
})(withRouter(GradeVideoDetailPage));