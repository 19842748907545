import React from "react";
import "./HowItWorks.scss";
import work1 from "../../../Assets/Home/work1.png";
import work2 from "../../../Assets/Home/work2.png";
import work3 from "../../../Assets/Home/work3.png";

export default class HowItWorks extends React.Component {
  render() {
    const { title } = this.props;
    return (
      <div className="works-component">
        <div className="container works-container">
          <h2 className="title">{title ? title : 'How It Works'}</h2>
          <div className="row main-row">
            <div className="col-lg-4">
              <div className="item-area text-center">
                <div className="number-out center-item">
                  <div className="number-in center-item">1</div>
                </div>
                <div className="image-item first-image">
                  <img src={work1} alt="core-bg" />
                </div>
                <div className="des-item">
                  <h1>Tutoring Needs:</h1>
                  <p className="line-height">
                    Let us know your specific tutoring needs and the type of tutor you would like to work with.
                  </p>
                </div>
              </div>
              {/* <div className="bg-dot" /> */}
            </div>

            <div className="col-lg-4">
              <div className="item-area text-center mx-auto">
                <div className="number-out center-item">
                  <div className="number-in center-item">2</div>
                </div>
                <div className="image-item">
                  <img src={work2} alt="core-bg" />
                </div>
                <div className="des-item">
                  <h1>Free Trial Lesson:</h1>
                  <p className="line-height">
                    We match you with a top tutor for a free trial lesson to ensure we're the right fit for you. No credit card is required.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="item-area text-center">
                <div className="number-out center-item">
                  <div className="number-in center-item">3</div>
                </div>
                <div className="image-item third-image">
                  <img src={work3} alt="core-bg" />
                </div>
                <div className="des-item">
                  <h1>Design Your Plan:</h1>
                  <p>
                    If 100% happy with your trial lesson, We let you design your payment plan starting at $37.5/hr for a budget that works for your family.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
