import React from "react";
import "./Schools.scss";
import Loadable from "@loadable/component";
import Media from "react-media";
import { School } from "../../";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import _ from 'lodash'

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default class Schools extends React.Component {

  render() {
    const { schools } = this.props;
    return (
      <>
        {!_.isEmpty(schools) && <div className="schools-component">
          <div className="container schools-container">
            <h2 className="title">Schools</h2>
            <div className="description">These are schools that we have tutored their students</div>
            <div className="col-lg-12 main-row">
              <Media
                queries={{
                  lg: "(min-width: 1200px)",
                  md: "(min-width: 992px) and (max-width: 1199px)",
                  sm: "(min-width: 768px) and (max-width: 991px)",
                }}
              >
                {(matches) => (
                  <OwlCarousel
                    className="owl-theme"
                    rewind
                    nav
                    stagePadding={matches.lg ? 10 : matches.md ? 10 : 5}
                    margin={matches.lg ? 28 : matches.md ? 33 : 20}
                    items={matches.lg ? 2 : 1}
                    dots={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {schools.map((item, index) => (
                      <School key={index} item={item} />
                    ))}
                  </OwlCarousel>
                )}
              </Media>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
