import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    FlashcardPage,
    FlashcardSubjectsPage,
    FlashcardTopicsPage
} from '../Pages';

function FlashcardRoutes() {

    return (
        <div>
            <Switch>
                <Route exact path="/flashcards" component={FlashcardSubjectsPage} />
                <Route exact path="/flashcard-topics/:subject/:id" component={FlashcardTopicsPage} />
                <Route exact path="/flashcard-questions/:subject/:subjectId/:topic/:id" component={FlashcardPage} />
            </Switch>
        </div>
    )
}

export default FlashcardRoutes;