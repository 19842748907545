import React, {useState} from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import { useHistory } from 'react-router-dom';
import CloseIcon from 'src/Assets/CommonQuestions/minus-icon.svg';
import OpenIcon from 'src/Assets/CommonQuestions/plus-icon.svg';

export default function QuestionItem({
  question,
}) {
  let history = useHistory();

  const [showDetail, setShowDetail] = useState(false);

  const getQuestion = () => {
    let name = question.name.replace(/[^\w\s]/g, '');
    return name.replace(/\s+/g, '-');
  }

  const subject_name = question.subject.name.replace(/\s+/g, '-');
  const question_name = getQuestion()

  const onDetail = () => setShowDetail(!showDetail);
  const onReadMore = () => history.push({ pathname: `/common-questions/${subject_name}/${question_name}/${question.id}`})

  return (
    <div className="question-item-component shadow-object">
      <div className='question-header'>
        <div className='question-title mb-2'>{question.name}</div>
        <ReactSVG
          src={showDetail ? CloseIcon : OpenIcon}
          className='open-close-icon'
          onClick={onDetail}
        />
      </div>
      {showDetail && <div className='question-body'>
        <p>{question.short_answer}</p>
        <div className='btn-3 read-more-btn' onClick={onReadMore}>READ MORE</div>
      </div>}
    </div>
  );
}