import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    WSResourceList,
    WSTopicHeader
} from 'src/Components';


class WorksheetFilesPage extends React.Component {

    render() {
        const subject = "1st Grade Math";

        return (
            <div>
                <WSTopicHeader subject={subject}/>
                <WSResourceList />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(withRouter(WorksheetFilesPage))