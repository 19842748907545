import React from 'react';
import './style.scss';
import ResourceItem from './ResourceItem'
import { PQuestionSummary } from 'src/Components'
import ResourceLogo from 'src/Assets/CommonQuestions/logo-subject.svg';

export default function ResourceList() {

    const ResourceList = [
        { id: "1", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "2", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "3", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "4", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "5", title: "1st Grade Math Worksheets.pdf", size: "20 MB"},
        { id: "6", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "7", title: "1st Grade Math Worksheets.pdf", size: "20 MB" },
        { id: "8", title: "1st Grade Math Worksheets.pdf", size: "20 MB"},
    ]

    return (
        <div className='resource-list-component'>
            <div className='container component-wrapper'>
                <div className='row'>
                    <div className='col-lg-8 left-section'>
                        <div className='row'>
                            {
                                ResourceList.map(resource => 
                                    <div className='col-lg-6 col-md-12'>
                                        <ResourceItem key={resource.id} item={resource} />
                                    </div>
                                )
                            }
                        </div>
                        <div className='btn-3 load-more-btn'>LOAD MORE</div>
                    </div>
                    <div className='col-lg-4 right-section'>
                        <PQuestionSummary />
                    </div>
                </div>
            </div>
        </div>
    )
}