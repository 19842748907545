import React from "react";
import "./CityLandingPage.scss";
import { connect } from "react-redux";
import LazyLoad from 'react-lazyload';
import {
  StartArea,  
  About,
  TutorsInCity,
  ClientSaying,
  Pricing,
  Video,
  FAQ,
  Offers,
  Brochure,
  Blog,
  Meet,
  Contacts,
  Schools,
  Events,
  LocalPartners,
  Hours,
  HowItWorks,
  Location,
  ServicesAreas,
  Services
} from "../../Components";
import {
  getPlanAction,
  getHomeDataAction,
} from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";


class CityLandingPage extends React.Component {
  state = {
    route_region: '',
  };

  componentDidMount() {
    this.props.getPlanAction();    
    const { region } = this.props.match.params;
    const route_region = region.replace(/-/g, ' ');
    this.setState({route_region})
    this.props.getHomeDataAction(route_region, "");
  };

  render() {
    const { plans, LandingData } = this.props;
    const { route_region } = this.state;
    const metaTitle = `# 1 Tutors in ${route_region} Near you | Get 1 FREE Lesson`
    const metaDescription = `You get a FREE 30 minutes lesson with our ${route_region} tutors near you in all subjects and test prep. We’re a Christian Company and you pay what you can afford.`
    return (
      <main>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={metaTitle} />
          <meta itemprop="description" content={metaDescription} />
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle}/>
          <meta name="twitter:description" content={metaDescription}/>
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
        </Helmet>
        <div className="home-page" ref={this.workContainer}>
          <StartArea title={LandingData.title}/>
          <About homeData={LandingData}/>
          <LazyLoad height={200} offset={100} once>
            <TutorsInCity tutor_list={LandingData.tutor_list}/>
            <ClientSaying reviews={LandingData.reviews} />
            <HowItWorks />
            {plans && <Pricing plans={plans} city={LandingData.city.name} />}
            <Video videos={LandingData.videos}/>
            <FAQ isStartBnt={true}/>
            <Offers offers={LandingData.offers[0]}/>
            <Brochure ebook={LandingData.ebook}/>
            <Blog blogs={LandingData.blogs}/>
            <Meet director={LandingData.director}/>
            <Contacts contact={LandingData.contact}/>
            <Hours hours={LandingData.office_hour}/>
            <Schools schools={LandingData.schools}/>
            <Events events={LandingData.events[0]}/>
            <LocalPartners partners={LandingData.partners}/>
            <Location city={LandingData.city}/>
            <ServicesAreas service_areas={LandingData.service_areas}/>
            <Services services={LandingData.services} city={LandingData.city.name} />
          </LazyLoad>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({  
  plans: state.Core.plans,
  LandingData: state.Core.LandingData
});

export default connect(mapStateToProps, {
  getPlanAction,
  getHomeDataAction,
})(withRouter(CityLandingPage));
