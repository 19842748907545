import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";

export default function SubjectItem({
  info, onClick
}) {

  return (
    <div className='bg-wrapper'>
      <div
        className="subject-item-component center shadow-object"
        onClick={onClick}>
        <img src={process.env.REACT_APP_IMAGE_DOMAIN + info.picture } className='logo-img'/>
        <div className='subject-name mt-1'>{info.name}</div>
      </div>
      <div className='dotted-bg'></div>
    </div>
  );
}