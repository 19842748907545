import React from "react";
import "./UniversityLandingPage.scss";
import { connect } from "react-redux";
import LazyLoad from 'react-lazyload';
import {
  StartArea,
  About,
  TutorsInCity,
  ClientSaying,
  Pricing,
  Video,
  FAQ,
  ChooseUniversityTutor,
  Offers,
  Brochure,
  Blog,
  Meet,  
  HowItWorks,
  Location,
  ServicesAreas,
  Services
} from "../../Components";
import {
  getPlanAction,
  getUniversityLandingDataAction
} from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";
import { InitialLandingData } from "../../Constant";

class UniversityLandingPage extends React.Component {

  componentDidMount() {
    this.props.getPlanAction();
    const { university } = this.props.match.params;
    const route_university = university.replace(/-/g, ' ');
    this.props.getUniversityLandingDataAction(route_university);
  };

  render() {
    const { plans, universityLadingData } = this.props;
    return (
      <main>
        <Helmet>
          <title>#1 Online Christian Tutoring Company | GradeGetter</title>
          <meta name="title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta name="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta itemprop="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta property="og:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="#1 Online Christian Tutoring Company | GradeGetter"/>
          <meta name="twitter:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College"/>
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
        </Helmet>
        <div className="home-page" ref={this.workContainer}>
          <StartArea title={universityLadingData.title}/>
          <About homeData={universityLadingData}/>
          <LazyLoad height={200} offset={100} once>
            <TutorsInCity tutor_list={universityLadingData.tutor_list}/>
            <ClientSaying reviews={universityLadingData.reviews} />
            <HowItWorks />
            {plans && <Pricing plans={plans} />}
            <Video videos={universityLadingData.videos}/>
            <ChooseUniversityTutor school_faq={universityLadingData.faq}/>
            <FAQ isStartBnt={true}/>
            <Offers offers={universityLadingData.offers[0]}/>
            <Brochure ebook={universityLadingData.ebook}/>
            <Blog blogs={universityLadingData.blogs}/>
            <Meet director={universityLadingData.director}/>
            <Location city={universityLadingData.city}/>
            <ServicesAreas service_areas={universityLadingData.service_areas}/>
            <Services services={universityLadingData.services}/>
          </LazyLoad>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.Core.plans,  
  universityLadingData: state.Core.universityLadingData
});

export default connect(mapStateToProps, {
  getPlanAction,
  getUniversityLandingDataAction
})(withRouter(UniversityLandingPage));
