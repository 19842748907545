import React from 'react';
import './style.scss';
// import { togglePlanModal } from 'src/Redux/Actions';
import { useDispatch } from 'react-redux';
import { ReactSVG } from "react-svg";
import Logo from 'src/Assets/Header/logo.png';

export default function QuestionSummary({
  className = ''
}) {
  const dispatch = useDispatch();

  const onTryNow = () => {
    // dispatch(togglePlanModal(true));
  }

  return (
    <div className={`question-summary-component ${className}`}>
      <div className='right-description-content'>
        <img src={Logo} className='small-logo' />
        <div className='num-wrapper'>
           <h2 className='questions-num'>Free</h2>
        </div>
        <h2>Tutoring Lesson</h2>
        <span>{`Practice questions with video \nexplorations`}</span>
      </div>
      <div className='btn-3 mt-2' onClick={onTryNow}>GET FREE TRIAL LESSON</div>
    </div>
  );
}