import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import { NavLink } from 'react-router-dom';
import logo from 'src/Assets/CommonQuestions/footer/gg-logo.png'
import googlePlay from 'src/Assets/CommonQuestions/footer/google-play-icon.svg';
import appleStore from 'src/Assets/CommonQuestions/footer/app-store-icon.svg';

import facebook from 'src/Assets/CommonQuestions/footer/Social/facebook.png';
import twitter from 'src/Assets/CommonQuestions/footer/Social/twitter.png';
import instagram from 'src/Assets/CommonQuestions/footer/Social/instagram.png';
import yt from 'src/Assets/CommonQuestions/footer/Social/yt.png';
import tiktok from 'src/Assets/CommonQuestions/footer/Social/tiktok.png';
import linkedin from 'src/Assets/CommonQuestions/footer/Social/linkedin.png';
import google from 'src/Assets/CommonQuestions/footer/Social/google.png';
import { connect } from "react-redux";
import {
  getCQSubjectsAction,
  getMiMSTopicsAction
} from 'src/Redux/Actions';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class CQFooter extends React.Component {
  state = {
    subjects: null,
    mims_topics: null
  };

  onBecomeTutor = () => {
    window.location.href = "https://gradegetter.com/tutor-home"
  }

  hideFooter() {
    const pathName = this.props.location.pathname;
    if (
        pathName === "/sign-in" ||
        pathName === "/forgot-password" ||
        pathName === "/new-password" ||
        pathName === "/sign-up" ||
        pathName === "/tutoring" ||
        pathName === "/toptutors" ||
        pathName === "/gradeTv"
        )
    {
        return true;
    }
    return false;
  }

  componentDidMount() {
    var subjects = sessionStorage.getItem("cached_subjects");
    var mims_topics = sessionStorage.getItem("cached_mims_topics");
    if (subjects !== null) {
      this.setState({ subjects: JSON.parse(subjects) })
    } else {
      this.props.getCQSubjectsAction().then(() => {
        subjects = this.props.subjects
        sessionStorage.setItem("cached_subjects", JSON.stringify(subjects))
        this.setState({ subjects: subjects })
      })
    }
    if (mims_topics !== null) {
      this.setState({ mims_topics: JSON.parse(mims_topics) })
    } else {
      this.props.getMiMSTopicsAction().then(() => {
        mims_topics = this.props.mims_topics
        sessionStorage.setItem("cached_mims_topics", JSON.stringify(mims_topics))
        this.setState({ mims_topics: mims_topics })
      })
    }
  }

  render() {
    const { subjects, mims_topics } = this.state;

    return (
      <div
        className="cq-footer-component"
        style={{ display: this.hideFooter() && "none" }}
      >
        <div className="container">
          <div className='col-lg-12'>
            <div className='header'>
              <NavLink className="menu-item" exact to="/"><img src={logo} className="logo" alt="logo-active" /></NavLink>
              <div className='btn-3' onClick={() => this.onBecomeTutor()}>BECOME A TUTOR</div>
            </div>
          </div>
          <div className='body'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-3'>
                    <div className='tag-title'>Company</div>
                    <div className='tag-body'>
                      <a href="https://gradegetter.com/contact-us" className="tag" target="_blank" rel="noopener noreferrer">Contact Us</a>
                      <a href="https://gradegetter.com/team" className="tag" target="_blank" rel="noopener noreferrer">Team</a>
                      <a href="https://gradegetter.com/faqs" className="tag" target="_blank" rel="noopener noreferrer">FAQ</a>
                      <a href="https://gradegetter.com/refund" className="tag" target="_blank" rel="noopener noreferrer">Refund</a>
                      <a href="https://gradegetter.com/terms" className="tag" target="_blank" rel="noopener noreferrer">Terms</a>
                      <a href="https://gradegetter.com/blog" className="tag" target="_blank" rel="noopener noreferrer">Blog</a>
                      <a href="https://gradegetter.com/contact-us" className="tag" target="_blank" rel="noopener noreferrer">Press</a>
                      <a href="https://gradegetter.com/contact-us" className="tag" target="_blank" rel="noopener noreferrer">Careers</a>
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className='tag-title'>Know More</div>
                    <div className='tag-body'>
                      <NavLink className="tag" exact to="/coming-soon">Pricing</NavLink>
                      <NavLink className="tag" exact to="/coming-soon">Stories</NavLink>
                      <a href="https://gradegetter.com/demo" className="tag" target="_blank" rel="noopener noreferrer">Demo</a>
                      <a href="https://gradegetter.com/features" className="tag" target="_blank" rel="noopener noreferrer">Features</a>
                      <NavLink className="tag" exact to="/coming-soon">Parents</NavLink>
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className='tag-title'>Partners</div>
                    <div className='tag-body'>
                      <NavLink className="tag" exact to="/coming-soon">Co Operations</NavLink>
                      <NavLink className="tag" exact to="/coming-soon">K-12</NavLink>
                      <NavLink className="tag" exact to="/coming-soon">Higher Ed</NavLink>
                      <NavLink className="tag" exact to="/coming-soon">None Profits</NavLink>
                    </div>
                  </div>
                  { subjects &&
                   (<div className='col-lg-3'>
                      <div className='tag-title'>Top Subjects</div>
                      <div className='tag-body'>
                      {subjects.map(item => 
                        <NavLink className="tag" key={item.id} exact to={`/tutors-subject/${item.name}`}>{item.name} Tutors</NavLink>
                        )}
                      </div>
                    </div>)
                  }
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-3'>
                    <div className='tag-title'>Resources</div>
                    <div className='tag-body'>
                      <NavLink className="tag" exact to="/worksheets">WorkSheets</NavLink>
                      <NavLink className="tag" exact to="/flashcards">Flashcards</NavLink>
                      <NavLink className="tag" exact to="/common-questions">Common Questions</NavLink>
                      <NavLink className="tag" exact to="/practice-questions">Practice Questions</NavLink>
                      <NavLink className="tag" exact to="/practice-tests">Practice Tests</NavLink>
                      <NavLink className="tag" exact to="/coming-soon">Resource Center</NavLink>
                      <NavLink className="tag" exact to="/blog">Blog</NavLink>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='tag-title'>Our Brands</div>
                    <div className='tag-body'>
                      <a href="https://makeitmakesense.gradegetter.com/" className="tag" target="_blank" rel="noopener noreferrer">Make it Make Sense</a>
                      <a href="https://icodeedu.com/" className="tag" target="_blank" rel="noopener noreferrer">iCode Edu</a>
                      <a href="https://gradegetter.com/gradetv" className="tag" target="_blank" rel="noopener noreferrer">Grade TV</a>
                      <a href="https://gradegetter.com/" className="tag" target="_blank" rel="noopener noreferrer">Grade Getter</a>
                      <a href="https://onlinemathtutor.com/" className="tag" target="_blank" rel="noopener noreferrer">Online Math Tutor</a>
                    </div>
                  </div>
                  {
                    mims_topics && 
                     (<div className='col-lg-5'>
                      <div className='tag-title'>Make it Make Sense</div>
                      <div className='tag-body'>
                        {
                          mims_topics.map(topic => 
                            (<a className="tag" target="_blank" rel="noopener noreferrer" key={topic.id} href={`https://makeitmakesense.gradegetter.com/make-it-make-sense/topic_id/${topic.id}`} dangerouslySetInnerHTML={{ __html: topic.description }}></a>)
                        )}
                    </div>
                  </div>)
                 }
                </div>
              </div>
            </div>
          </div>
          <div className='footer'>
            <div className='stores'>
              <a href="https://play.google.com/store/apps/details?id=com.gradegetter&pli=1" target="_blank" rel="noopener noreferrer"><ReactSVG src={googlePlay} /></a>
              <a href="https://play.google.com/store/apps/details?id=com.gradegetter&pli=1" target="_blank" rel="noopener noreferrer"><ReactSVG src={appleStore} className='app-store' /></a>
            </div>
            <div className='footer-middle'>
              <NavLink className="footer__link" exact to="/" >© 2022 OnlineMathTutor</NavLink>
              <NavLink className="footer__link" exact to="/terms" >Terms of Service</NavLink>
              <NavLink className="footer__link" exact to="/privacy" >Privacy Policy</NavLink>
            </div>
            <div className='footer-right v-c'>
              <a href="https://www.facebook.com/gradegetter" className="social" target="_blank" rel="noopener noreferrer"><img src={facebook} alt='facebook' /></a>
              <a href="https://twitter.com/GradeGetter" className="social" target="_blank" rel="noopener noreferrer"><img src={twitter} alt='twitter' /></a>
              <a href="https://www.instagram.com/gradegetter" className="social" target="_blank" rel="noopener noreferrer"><img src={instagram} alt='instagram' /></a>
              <a href="https://www.youtube.com/channel/UC6howHvJniKu7YypUvFps9Q/?guided_help_flow=5" className="social" target="_blank" rel="noopener noreferrer"><img src={yt} alt='yt' /></a>
              <a href="https://www.tiktok.com/@onlinemathtutor?lang=en" className="social" target="_blank" rel="noopener noreferrer"><img src={tiktok} alt='tiktok' /></a>
              <a href="https://www.linkedin.com/in/online-math-tutor-484ba0258/" className="social" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt='linkedin' /></a>
              <a href="https://gradegetter.com" className="social" target="_blank" rel="noopener noreferrer"><img src={google} alt='google' /></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subjects: state.Question.cq_subjects,
  mims_topics: state.Core.mims_topics
})

export default connect(mapStateToProps, {
  getCQSubjectsAction,
  getMiMSTopicsAction
})(withRouter(CQFooter))

