import React from 'react'
import './style.scss'
import {
    PQuestionHeader,
    PQResourceList
} from 'src/Components'

export default function PQQuestionResource() {

    return (
        <div className='pq-question-resource-page'>
            <PQuestionHeader />
            <PQResourceList />
        </div>
    )
} 