import React from "react";
import "./Meet.scss";
import { StartButton } from "../../";
import { NavLink } from "react-router-dom";
import _ from 'lodash'

export default class Meet extends React.Component {
  
  render() {
    const { director } = this.props;
    const alttext = `${director.name} is an Enrollment Advisor With GradeGetter`;
    return (
      <>
        {!_.isEmpty(director) && <div className="meet-component">
          <div className="container meet-container">
            <h2 className="title">{director.title}</h2>
            <div className="row main-row">
              <div className="col-lg-6 left-area ">
                <div className="image-container">
                  <img src={director.image} alt={alttext} />
                </div>
              </div>
              <div className="col-lg-6 right-area">
                <div className="meet-details">{director.text}</div>
                <div className="meet-category">
                  <div className="name">{director.name}</div>
                  <div className="place">{director.job}</div>
                </div>
              </div>
            </div>
            <NavLink style={{ textDecoration: "none" }} to="/sign-up">
              <StartButton className="start-btn" startText="FREE TRIAL LESSON" />
            </NavLink>
          </div>
        </div>}
      </>
    );
  }
}
