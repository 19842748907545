import React from "react";
import "./StartAreaV3.scss";
import arrow from "../../../Assets/HomeV2/arrow-2.png";
import homeV3_img from "../../../Assets/HomeV2/homeV3_img.png";
import "react-alice-carousel/lib/alice-carousel.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class StartAreaV3 extends React.Component {

  trialLesson(){
    this.props.history.push(`/sign-up`);
  }
  render() {
    const { subject } = this.props;
    return (
      <div className="homestart-v3-component">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 order-lg-first order-second">
                    <h1>1:1 {subject && <strong className="strong-bg">{subject}</strong>} Online Tutoring With  High Performing Tutors From Ivy league's & Other Top Universities</h1>
                    <div className="description">
                        <div className="sub-title mt-3">Start with FREE trial lesson!</div>
                        <img src={arrow} alt="arrow" className="arrow" />
                        <div className="no-credit v-c h-c mt-3">No credit card is required!</div>
                        <div className="trial-lesson-btn shadow-object v-c h-c mt-3" onClick={()=>this.trialLesson()}>CLAIM FREE TRIAL LESSON</div> 
                        <div className="sub-description shadow-object v-c h-c mt-3">Our tutors are awesome. You will love them :)</div>                   
                    </div>
                </div>
                <div className="col-lg-6 right-area order-lg-second order-first">
                    <div>
                      <img src={homeV3_img} alt="home-img" className="home-img" />
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default connect()(
  withRouter(StartAreaV3)
);
