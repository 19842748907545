import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { PQuestionSummary, SearchInput } from 'src/Components';
import QuestionItem from './TopicItem';
import { Topics } from './topics';

export default function QuestionList() {
  let history = useHistory();

  useEffect(() => {
    console.log(history)
  },[])

  const onTopicDetail = () => {
    let params = history.location.pathname.split("/")
    const subject = params[params.length - 1]
    history.push(`/practice-questions/files`);
  }

  return (
    <div className="question-list-component">
      <div className="container home-start-container">
        <div className="row mt-4">
          <div className="col-lg-8 left-content">
            <h2>Topics</h2>
            <div className='sub-description'>Select Questions you want to discover.</div>
            <div className='subject-list'>
              {Topics.map((item, index) => (
                index < 5 && <QuestionItem
                  key={index}
                  info={item}
                  onClick={onTopicDetail}
                />
              ))}
            </div>
            <div className='btn-3 load-more-btn'>LOAD MORE</div>
          </div>
          <div className="col-lg-4 right-content">
            <PQuestionSummary />
          </div>
        </div>
      </div>
    </div>
  );
}