import React from "react";
import "./SignUpPage.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import numeral from 'numeral';
import arrow from "../../Assets/Common/left-arrow.svg";
import { ReactSVG } from "react-svg";
import { getDetailsDays } from "../../Util/util";
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import {
  Times,
  PaymentInformations,
  InfosAfterPayment,
  Payments,
  Shares,
  PaymentPlan,
} from "./assets";
import {
  SelectBox,
  StartButton,
  Toggle,
  ClientFeedback,
  InputBox,
  CardInputBox,
  PhoneNumberInput,
  Accordion,
  WeekHourSlider,
  HourlyRateSlider,
  StartButtonDisable,
  CheckBox
} from "../../Components";
import shoppingList from "../../Assets/Common/shopping-list.svg";
import tick from "../../Assets/Common/tick.svg";
import user from "../../Assets/Common/user.svg";
import email from "../../Assets/Common/email.svg";
import key from "../../Assets/Common/key.svg";
import telephone from "../../Assets/Common/telephone.svg";
import checkmark from "../../Assets/Common/checkmark.png";
import rightArrow from "../../Assets/Common/arrow-point-to-right.svg";
import creditcard from "../../Assets/Common/credit-card.svg";
import home from "../../Assets/Common/home.svg";
import place from "../../Assets/Common/place.svg";
import narrow from "../../Assets/Common/navigation-arrow.svg";
import tutorVideo from "../../Assets/Video/math.mp4";
import newClient from "../../Assets/Video/newClient.mp4";
import Media from "react-media";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import Loadable from "@loadable/component";
import $ from "jquery";
import { toast } from "react-toastify";
import {
  getLevelAction,
  getSubjectAction,
  getConnectionAction,
  getPlanAction,
  getBraintreekeyAction,
  signUpAction,
  profileUpdateAction,
  signUpHubspotAction,
  sendNoneToServerAction,
  firstChargeAction
} from "../../Redux/Actions";
import { CarouselLeftButton, CarouselRightButton, GetDashboardUrl } from "../../Constant";
import { braintainService } from '../../services/paymentServices';
import Helper from '../../Util/Helper';

const OwlCarousel = Loadable(() => import("react-owl-carousel"));
const Dates = getDetailsDays(60);

class SignUpPage extends React.Component {
  state = {
    currentProgress: 1,
    plan_tutoring_hour: "",
    plan_rate_per_hour: "",
    progress_1: {
      grade_level: "",
      subject: "",
      tutor_type: "",
      payment_frequency: {
        name: "",
        min_hour: "",
        max_hour: "",
        min_rate: "",
        max_rate: ""
      },
      additional_information: ""
    },
    progress_2: {
      tutor_date: "",
      tutor_date_index: 0,
      tutor_time: "",
      schedule: false,
    },
    progress_4: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone: "",
      photo: null,
    },
    progress_6: {
      payment_method: "",
    },
    progress_7: {
      card_number: "",
      month: "",
      year: "",
      cvv2: "",
      card_name: "",

      billing_address: "",
      zip_code: "",
      city: "",

      paypal_email: "",
      paymentQuestion: false,
    },
    isPayClick: false,
    part: 3,
    terms_checked: false,
    isCouponCode: false,
    coupon_code: '',
    shareURL: '',
    tutor_name: '',
    referrer_code: ''
  };

  constructor(props) {
    super(props);

    this.workContainer = React.createRef();
    this.myUrl = React.createRef();
  }

  componentDidMount() {
    if (window.Intercom) {
      window.intercomSettings = {
        "hide_default_launcher": true
      };
    }

    window.addEventListener("keydown", this.checkEnterPress);
    this.props.getLevelAction();
    this.props.getSubjectAction();
    this.props.getConnectionAction();
    this.props.getPlanAction().then(() => {
      const { plans } = this.props;
      var progress_1 = {
        grade_level: "",
        subject: "",
        tutor_type: "",
        payment_frequency: {
          name: plans[0].name,
          min_hour: plans[0].min_hour,
          max_hour: plans[0].max_hour,
          min_rate: plans[0].min_rate,
          max_rate: plans[0].max_rate
        },
        additional_information: ""
      };

      this.setState({
        progress_1,
        plan_tutoring_hour: plans[0].min_hour,
        plan_rate_per_hour: plans[0].min_rate,
      });

      const { isUser, user } = this.props.location;

      if (isUser) {
        this.setState({ currentProgress: 10 });
        this.setState({ tutor_name: user.trial_lesson.tutor })
      }
    });

    //--------referrer code--------//
    var referrer_code;
    if (this.props.location.search && this.props.location.search.length > 0) {
      const params = new URLSearchParams(this.props.location.search);
      referrer_code = params.get('referrer_code');
      localStorage.setItem("referrer_code", referrer_code);
    }
    referrer_code = localStorage.getItem("referrer_code");
    this.setState({ referrer_code })
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.checkEnterPress);
  }

  componentDidUpdate(progress1) {

  }

  onSubmit() {
    Helper.showSpinner();
    const payload = {
      level_id: this.state.progress_1.grade_level.id,
      subject_id: this.state.progress_1.subject.id,
      tutoring_need: this.state.progress_1.additional_information,

      start_date: this.state.progress_2.tutor_date ? this.state.progress_2.tutor_date : "",
      start_time: this.state.progress_2.tutor_time ? this.state.progress_2.tutor_time : "",

      first_name: this.state.progress_4.first_name,
      last_name: this.state.progress_4.last_name,
      phone: this.state.progress_4.phone,
      email: this.state.progress_4.email,
      password: this.state.progress_4.password,
      referrer_code: this.state.referrer_code,
      send_alert: "not"
    };

    this.props.signUpAction(payload).then((res) => {
      if (this.props.user && this.props.token) {
        this.props.signUpHubspotAction(payload).then(() => {
          if (this.props.signupHubspotStatus === "Success") {
            Helper.hideSpinner();
            toast("Successfully!")
            this.setState({ currentProgress: 7 });
            this.workContainer.current.scrollTo(0, 0);
          } else {
            Helper.hideSpinner();
          }
        })
      } else {
        Helper.hideSpinner();
        toast(this.props.signErr.errors.email.toString() || this.props.signErr.message)
      }
    })
  }

  reSubmit() {
    this.setState({ currentProgress: 4 });
  };

  checkEnterPress = (e) => {
    if (e.keyCode === 13) {
      if (this.state.currentProgress === 1) {
        if (this.state.progress_1.subject !== "") {
          this.scrollToDom(1, 3)
        }
        if (this.state.progress_1.additional_information !== "") {
          this.scrollToDom(0, 0)
        }
      }
      if (this.state.currentProgress === 10) {
        const { part } = this.state;
        if (this.state.part < 5) {
          if (part === 3) {
            // this.scrollToDom(10, part)
          } else {
            this.scrollToDom(10, part)
          };
          this.setState({ part: part + 1 })
        } else {
          this.scrollToDom(0, 0)
        }
      }
    }
  };

  goBack = () => {
    this.props.history.push("/");
  };

  alreayLogin = () => {
    this.props.history.push("/sign-in");
  };

  changeProgress_1 = (property, value, movePart = 0) => {
    this.setState({
      progress_1: { ...this.state.progress_1, [property]: value },
    });
    movePart === 0 ? this.scrollToDom(0, 0) : this.scrollToDom(1, movePart);
  };

  selectSubject = (e) => {
    this.setState({ progress_1: { ...this.state.progress_1, subject: e } });
  };

  additionalInformation = (v) => {
    this.setState({ progress_1: { ...this.state.progress_1, additional_information: v } });
  }

  changePricingPlan = (property, value, movePart = 0) => {
    this.setState({
      plan_tutoring_hour: value.min_hour,
      plan_rate_per_hour: value.min_rate,
      progress_1: { ...this.state.progress_1, [property]: value },
    });
    // movePart === 0 ? this.scrollToDom(0, 0) : this.scrollToDom(1, movePart);
  };

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  };

  selectDate = async (item, index) => {
    const itemWidth = $(`#carousel${index}`)[0].offsetWidth;
    const transformValue = -parseInt(
      $(".owl-stage")[0]
        .style.transform.split("(")[1]
        .split(",")[0]
        .slice(0, -2),
      10
    );
    const passedCount = parseInt(transformValue / itemWidth, 10);
    const itemCount = parseInt(
      $(`#carousel${index}`)[0].offsetParent.offsetLeft / itemWidth,
      10
    );
    const finalIndex = index - itemCount + passedCount;
    await this.setState({
      progress_2: {
        ...this.state.progress_2,
        tutor_date: item,
        tutor_date_index: finalIndex,
      },
    });
    await this.setState({
      progress_2: {
        ...this.state.progress_2,
        tutor_date: item,
        tutor_date_index: finalIndex,
      },
    });
    this.scrollToDom(2, 2);
  };

  changeProgress_2 = async (property, value, movePart = 0) => {
    await this.setState({
      progress_2: { ...this.state.progress_2, [property]: value },
    });
    await this.setState({
      progress_2: { ...this.state.progress_2, [property]: value },
    });
    movePart === 0 ? this.scrollToDom(0, 0) : this.scrollToDom(2, movePart);
  };

  // getSubjectById = (id) => {
  //   const state = this.state.progress_1.grade_level
  //   if(state === "Middle School") {

  //   }
  // }

  changeLevel = (property, value, movePart = 0) => {
    this.setState({
      progress_1: {
        ...this.state.progress_1,
        [property]: value,
        // subject: { id: null, name: "" },
      },
    });
    movePart === 0 ? this.scrollToDom(0, 0) : this.scrollToDom(1, movePart);
    this.props.getSubjectAction(value.id);
  };

  inputProgress_4 = (property, value) => {
    this.setState({
      progress_4: { ...this.state.progress_4, [property]: value },
    });
  };

  inputProgress_7 = (property, value) => {
    this.setState({
      progress_7: { ...this.state.progress_7, [property]: value },
    });
  };

  scrollToDom = (section, part) => {
    const heightFromTop =
      (window.outerHeight -
        this.refs[`section${section}${part}`].offsetHeight) /
      2;
    const yToReach =
      this.refs[`section${section}${part}`].offsetTop - heightFromTop;
    this.workContainer.current.scrollTo({
      left: 0,
      top: yToReach,
      behavior: "smooth",
    });
  };

  moveSection = (flag) => {
    const user = JSON.parse(window.sessionStorage.getItem("user"));
    const current = this.state.currentProgress;
    const next =
      flag === "next" ?
        (
          current === 2 ? 4 :
            current === 5 ? 10 :
              current === 10 ? 7 : current + 1
        )
        :
        (
          current === 1 ? 1 :
            current === 4 ? 2 :
              current === 10 && user ? 2 :
                current === 10 ? 4 :
                  current === 7 ? 10 : current - 1
        )
    if (next !== current) {
      this.setState({ currentProgress: next });
      this.workContainer.current.scrollTo(0, 0);
    }
    window.scrollTo(0, 0)
  };

  pickFileForPhoto = (e) => {
    e.preventDefault();
    if (!e.target.files[0]) return;
    this.setState({
      progress_4: {
        ...this.state.progress_4,
        photo: URL.createObjectURL(e.target.files[0]),
      },
    });
  };

  changeProgress_6 = (property, value) => {
    this.setState({
      progress_6: { ...this.state.progress_6, [property]: value },
    });
    this.moveSection("next");
  };

  copyMyLink = () => {
    this.myUrl.select();
    document.execCommand("copy");
  };

  weeklyHour = (value) => {
    this.setState({ plan_tutoring_hour: value });
  };

  hourlyRate = (value) => {
    this.setState({ plan_rate_per_hour: value });
  };

  paymentQuestionToggle = () => {
    this.setState({ paymentQuestion: !this.state.paymentQuestion });
  };

  getFirstConnection(client) {
    return {
      name: client.tutor.name,
      address: client.tutor.location,
      level: 1,
      knowledge: 3.5,
      presentation: 4.7,
      comment_date: new Date(client.reviewed_on).toDateString(),
      description: client.review_content,
    };
  }

  paymentFunction = async () => {
    Helper.showSpinner();
    const { progress_7 } = this.state;
    var cardDetail = progress_7;

    //--------braintreeKey--------//

    this.props.getBraintreekeyAction().then(() => {
      const { braintreeKey } = this.props;
      braintainService(braintreeKey, cardDetail).then((res) => {
        this.sendNonce(res);
      })
        .catch(err => {
          Helper.hideSpinner();
          toast("Invalid Card Info")
        });
    })
  }

  sendNonce = (nonce) => {
    const formData = new FormData()
    formData.append('todo', 'new');
    formData.append('nonce', nonce);

    this.props.sendNoneToServerAction(formData).then(() => {
      const { nonceUpdate } = this.props;
      if (nonceUpdate) {
        toast(nonceUpdate)
        this.firstCharge()
        // this.profileUpdate();
      } else {
        Helper.hideSpinner();
        toast("card update error!");
      };
    });
  };

  firstCharge = () => {
    this.props.firstChargeAction().then(() => {
      const { firstChargeError, firstChargeResMessage } = this.props;
      Helper.hideSpinner();
      if (!firstChargeError) {
        this.setState({ currentProgress: 8 });
      }
      toast(firstChargeResMessage);
    })
  }

  profileUpdate = () => {
    Helper.showSpinner();

    const payload = {
      // level_id: this.state.progress_1.grade_level.id,
      // subject_id: this.state.progress_1.subject.id,
      // tutoring_need: this.state.progress_1.additional_information,

      // start_date: this.state.progress_2.tutor_date? this.state.progress_2.tutor_date : "",
      // start_time: this.state.progress_2.tutor_time? this.state.progress_2.tutor_time : "",

      plan_frequency: this.state.progress_1.payment_frequency.name,
      plan_tutoring_hour: this.state.plan_tutoring_hour,
      plan_rate_per_hour: this.state.plan_rate_per_hour,
    };
    this.props.profileUpdateAction(payload).then(() => {
      const { profileUpdate, profileUpdateErr } = this.props;
      Helper.hideSpinner();

      if (this.props.profileUpdate) {
        this.setState({ shareURL: `https://gradegetter.com/sign-up?referrer_code=${profileUpdate.id}` });
        toast("Successfully Updating!")
        // this.setState({ currentProgress: 8});
        this.goToDashboard()
      } else {
        toast(profileUpdateErr);
      }
    })
  }

  paymentTokenError = (error) => {
    this.setState({ isPayClick: false });
    Helper.hideSpinner();

    toast(error)
  }

  goToDashboard = () => {
    window.location.href = GetDashboardUrl(this.props.user, this.props.token)
  }

  render() {
    const {
      currentProgress,
      progress_1,
      progress_2,
      progress_4,
      progress_6,
      progress_7,
      terms_checked,
      isCouponCode,
      coupon_code,
      shareURL,
      plan_tutoring_hour,
      plan_rate_per_hour,
      referrer_code
    } = this.state;
    const { subjects, levels, connections, plans, signErr } = this.props;

    return (
      <div className="signup-page">
        <div className="progress-explain">
          <ReactSVG
            src={arrow}
            style={{
              fill: "white",
              cursor: "pointer",
              top: "32px",
              left: "31px",
              position: "absolute",
            }}
            onClick={(e) => this.goBack()}
          />
          <div className="bg-dot custom-bg" />
          <div className="explain-content">
            {currentProgress === 1 && (
              <div id="section-explain-1">
                <h1>Academic & tutoring needs</h1>
                <p>
                  Over 10,000 verified and background-checked tutors based in the U.S ready to work with you!
                </p>
              </div>
            )}
            {currentProgress === 2 && (
              <div id="section-explain-2">
                <h1>Date & Time</h1>
                <p>
                  This can be a solid or a tentative start date. You can always adjust your timing once you are connected with a tutor.
                </p>
              </div>
            )}
            {currentProgress === 3 && (
              <div id="section-explain-3">
                <h1>Clients Are Saying</h1>
                <p>
                  Finding a reliable tutor or study coach is hard — but
                  GradeGetter is here to make it easier! All of our tutors are
                  verified, have passed background checks, and have been
                  screened and interviewed by our hiring team!
                </p>
              </div>
            )}
            {currentProgress === 4 && (
              <div id="section-explain-4">
                <h1>Create your account </h1>
                <p>
                  Your information is protected a high level. GradeGetter does not share any personal information with third parties.
                </p>
              </div>
            )}
            {currentProgress === 5 && (
              <div id="section-explain-5">
                <h1>All About Results</h1>
                <p>
                  If you like the tutor you are assigned - great! We can’t wait
                  to hear all about your success. However, if you feel the tutor
                  assigned was not a good fit, we will place you with another
                  instructor free of charge.
                </p>
              </div>
            )}
            {currentProgress === 10 && (
              <div id="section-explain-5">
                <h1>Choose your price</h1>
                <p>
                  At GradeGetter, We let our customers choose what they can pay
                  for online and one-on-one tutoring. Paying a little more helps
                  to offset the cost for families who might not be able to
                  afford tutoring regularly. With this approach, we are able to
                  make private tutoring more accessible and affordable for
                  everyone
                </p>
              </div>
            )}
            {(currentProgress === 6 || currentProgress === 7) && (
              <div id="section-explain-6">
                <h1>Payment Information</h1>
                <div className="provide-contents">
                  {PaymentInformations.map((item, index) => (
                    <div className="provide" key={index}>
                      <img src={checkmark} alt="checkmark" />
                      <div className="explain">{item}</div>
                    </div>
                  ))}
                </div>
                <div className="next-contents">
                  {/* <div
                    className="question-header"
                    onClick={() => this.paymentQuestionToggle()}
                  >
                    <div className="explain">
                      What next after entering payment information?
                    </div>
                    <ReactSVG
                      src={rightArrow}
                      className={`icon ${
                        this.state.paymentQuestion
                          ? "has-icon-close"
                          : "icon-close"
                      }`}
                    />
                  </div> */}
                  {this.state.paymentQuestion ? (
                    <div className="content-list">
                      {InfosAfterPayment.map((item, index) => (
                        <Accordion
                          className="accordion"
                          title={item.title}
                          content={item.content}
                          icon={rightArrow}
                          key={index}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            {currentProgress === 8 && (
              <div id="section-explain-8">
                <h1>Training Video</h1>
                <p>
                  This video gives you a quick overview on how our program
                  works.{" "}
                </p>
              </div>
            )}
            {currentProgress === 9 && (
              <div id="section-explain-9">
                <h1>Share Better Grades</h1>
                <p>
                  Share this exclusive link with your friends!
                  Give $25 in GradeGetter credit and get $25 in
                  credit for yourself when your friend completes
                  their first tutoring lesson using your link.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="work-area" ref={this.workContainer}>
          <div className="work-header show-web-flex">
            <div className="container work-container">
              <div className="row header-row">
                <div className="col-lg-2 header-col">
                  <div
                    className={`col-name ${currentProgress >= 1 ? "active" : ""
                      } ${currentProgress > 1 ? "passed" : ""}`}
                  >
                    ACADEMICS
                  </div>
                </div>
                <div className="col-lg-2 header-col">
                  <div
                    className={`col-name ${currentProgress >= 2 ? "active" : ""
                      } ${currentProgress > 2 ? "passed" : ""}`}
                  >
                    DATE
                  </div>
                </div>
                <div className="col-lg-2 header-col">
                  <div
                    className={`col-name ${currentProgress >= 4 ? "active" : ""
                      } ${currentProgress > 5 ? "passed" : ""}`}
                  >
                    PROFILE
                  </div>
                </div>
                <div className="col-lg-2 header-col">
                  <div
                    className={`col-name ${currentProgress >= 6 ? "active" : ""
                      } ${currentProgress >= 6 && currentProgress < 10
                        ? "passed"
                        : ""
                      }`}
                  >
                    PLAN
                  </div>
                </div>
                <div className="col-lg-2 header-col">
                  <div
                    className={`col-name ${currentProgress >= 6 && currentProgress < 10
                      ? "active"
                      : ""
                      } ${currentProgress > 7 && currentProgress < 10
                        ? "passed"
                        : ""
                      }`}
                  >
                    PAYMENT
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container work-container">
            {/* Level and Subject  */}
            {currentProgress === 1 && (
              <div className="work-content" id="section-1">
                <div className="row show-mobile-flex" id="section-1-title">
                  <div className="col-lg-12">
                    <h2>Academic & tutoring needs</h2>
                  </div>
                </div>
                <div className="row" id="section-1-1">
                  <div className="claim-container mb-5">
                    <div className="claim-content">
                      Claim Your Trial Lesson At $1! No Package Purchase Required
                    </div>
                    <h5>1-on-1 tutoring with high performing college students</h5>
                  </div>
                  <div className="col-lg-12">
                    <h1>What Do You Need Help With?</h1>
                  </div>
                  {levels
                    ? levels.map((item, index) => (
                      <div
                        className="col-lg-6 col-md-6 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeLevel("grade_level", item, 2)
                        }
                      >
                        <div
                          className={`grade-level center-item ${progress_1.grade_level === item ? "active" : ""
                            }`}
                        >
                          {item.name}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))
                    : null}
                </div>
                <div className="row" id="section-1-2" ref="section12">
                  <div className="col-lg-12">
                    <h1>Subjects</h1>
                  </div>
                  <div className="col-lg-12">
                    <SelectBox
                      icon={shoppingList}
                      placeholder="Type subject"
                      options={progress_1.grade_level !== "" ? subjects || [] : []}
                      selectedText={progress_1.subject !== "" ? progress_1.subject.name : ""}
                      onChange={(e) => this.selectSubject(e)}
                    />
                  </div>

                  {(progress_1.grade_level !== "" && progress_1.subject !== "") && (
                    <div
                      className="col-lg-12 show-web-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        className="ok-btn center-item"
                        onClick={(e) => this.scrollToDom(1, 3)}
                      >
                        OK{" "}
                        <ReactSVG
                          src={tick}
                          style={{ fill: "white", marginLeft: "15px" }}
                        />
                      </div>
                      <div
                        className="center-item"
                        style={{ height: "100%", margin: "30px 0 0 20px" }}
                      >
                        Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row" id="section-1-3" ref="section13">
                  <div className="col-lg-12" >
                    <h1>Additional Information</h1>
                  </div>
                  <div className="col-lg-12">
                    <textarea placeholder="what additional information would you like your tutor to know?" value={progress_1.additional_information} onChange={(event) => this.additionalInformation(event.target.value)} />
                    <div className="under-dot additional-bg" />
                  </div>
                  {progress_1.additional_information !== "" && (
                    <div
                      className="col-lg-12 show-web-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        className="ok-btn center-item"
                        onClick={(e) => this.scrollToDom(0, 0)}
                      >
                        OK{" "}
                        <ReactSVG
                          src={tick}
                          style={{ fill: "white", marginLeft: "15px" }}
                        />
                      </div>
                      <div
                        className="center-item"
                        style={{ height: "100%", margin: "30px 0 0 20px" }}
                      >
                        Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Date & Time */}
            {currentProgress === 2 && (
              <div className="work-content" id="section-2">
                <div className="row show-mobile-flex" id="section-2-title">
                  <div className="col-lg-12">
                    <h2>Date & Time</h2>
                  </div>
                </div>

                <div className="row" id="section-2-1">
                  <div className="col-lg-12">
                    <div className="step-description">
                      This can be a solid or a tentative start date. You can always adjust your timing once you are match with a tutor.
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h1>When would you like to start tutoring?</h1>
                  </div>
                  <div className="col-lg-12">
                    <Media
                      queries={{
                        lg: "(min-width: 1200px)",
                        md: "(min-width: 992px) and (max-width: 1199px)",
                        sm: "(max-width: 991px)",
                      }}
                    >
                      {(matches) => (
                        <OwlCarousel
                          className="owl-theme"
                          rewind
                          nav
                          stagePadding={0}
                          margin={20}
                          items={matches.lg ? 4 : matches.md ? 3 : 2}
                          dots={false}
                          navText={[CarouselLeftButton, CarouselRightButton]}
                          startPosition={progress_2.tutor_date_index}
                        >
                          {Dates.map((item, index) => (
                            <div
                              key={index}
                              className="date-container"
                              id={`carousel${index}`}
                            >
                              <div
                                className={`tutor-date normal-item ${progress_2.tutor_date === item.tutor_date
                                  ? "active"
                                  : ""
                                  }`}
                                onClick={(e) =>
                                  this.selectDate(item.tutor_date, index)
                                }
                              >
                                <div className="month">{item.month}</div>
                                <div className="day">{item.day}</div>
                                <div className="weekday">{item.weekday}</div>
                              </div>
                              <div className="under-dot tutor-date-bg" />
                            </div>
                          ))}
                        </OwlCarousel>
                      )}
                    </Media>
                  </div>
                </div>
                <div className="row" id="section-2-2" ref="section22">
                  <div className="col-lg-12">
                    <h1>select a time</h1>
                  </div>
                  {Times.map((item, index) => (
                    <div
                      className="col-lg-4 col-md-6 col-6 tutor-time-col"
                      key={index}
                      style={{ position: "relative" }}
                      onClick={(e) =>
                        this.changeProgress_2("tutor_time", item.partOfDay, 3)
                      }
                    >
                      <div
                        className={`tutor-time normal-item center-item ${progress_2.tutor_time === item.partOfDay
                          ? "active"
                          : ""
                          }`}
                      >
                        <div className="part-of-day">{item.partOfDay}</div>
                        <div className="duration">{item.duration}</div>
                      </div>
                      <div className="under-dot tutor-time-bg" />
                    </div>
                  ))}
                </div>
                <div className="row" id="section-2-3" ref="section23">
                  <div className="col-lg-12 schedule-col">
                    <Toggle
                      value={progress_2.schedule}
                      onChange={(value) =>
                        this.changeProgress_2("schedule", value)
                      }
                      disable={progress_2.tutor_date !== "" && progress_2.tutor_time !== "" ? false : true}
                    />
                    <p>I need a tutor, but I am not ready to schedule my first lesson</p>
                  </div>
                </div>
              </div>
            )}

            {/* Clients Are Saying (not need)*/}
            {currentProgress === 3 && (
              <div className="work-content" id="section-3">
                <div className="row show-mobile-flex" id="section-3-title">
                  <div className="col-lg-12">
                    <h2>Clients Are Saying</h2>
                  </div>
                </div>
                <div className="row" id="section-3-1">
                  <div className="col-lg-9">
                    {connections && connections.length ? (
                      <ClientFeedback
                        item={this.getFirstConnection(connections[0])}
                        className="custom-feedback"
                      />
                    ) : null}
                  </div>
                  <div className="col-lg-9 remarks">
                    *Students who use GradeGetter are likely to develop better
                    study habits and improve grades
                  </div>
                </div>
              </div>
            )}

            {/* Create account */}
            {currentProgress === 4 && (
              <div className="work-content" id="section-4">
                <div className="row show-mobile-flex" id="section-4-title">
                  <div className="col-lg-12">
                    <h2>Create your account</h2>
                  </div>
                </div>
                <div className="row" id="section-4-1">
                  <div className="col-lg-12">
                    <div className="step-description">
                      We need this info to set up your account and match you with a tutor for your free trial lesson.
                      Your privacy is important to us. This is why we take great care of your data.
                      No junk emails
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h1>Profile</h1>
                  </div>
                  <div className="col-lg-12 show-mobile-flex profile-col">
                    <div className="center-item initial-name">
                      {progress_4.photo ? (
                        <img
                          src={progress_4.photo}
                          style={{ width: "100%", height: "100%" }}
                          alt="avatar"
                        />
                      ) : (
                        <span>
                          {progress_4.first_name[0] || "A"}
                          {progress_4.last_name[0] || "L"}
                        </span>
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      id="image_picker"
                      style={{ display: "none" }}
                      onChange={(e) => this.pickFileForPhoto(e)}
                    ></input>
                    <label htmlFor="image_picker">
                      <div className="upload-btn center-item">Upload Photo</div>
                    </label>
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={user}
                      placeholder="Type Your First Name"
                      onChange={(e) => this.inputProgress_4("first_name", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Type Your Last Name"
                      onChange={(e) => this.inputProgress_4("last_name", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      icon={email}
                      placeholder="Type Your E-mail"
                      onChange={(e) => this.inputProgress_4("email", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      icon={key}
                      placeholder="Create Password"
                      type="password"
                      onChange={(e) => this.inputProgress_4("password", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {referrer_code && <div className="referal-code-title">referal code</div>}
                    <InputBox
                      value={referrer_code}
                      placeholder="Have a referal code"
                      onChange={(e) => this.setState({ referrer_code: e })}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    <PhoneNumberInput
                      icon={telephone}
                      placeholder="Type Your Phone"
                      onChange={(e) => this.inputProgress_4("phone", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col already-login" onClick={() => this.alreayLogin()}>
                    Already have an account? Log in
                  </div>
                </div>
              </div>
            )}

            {/* All About Results (not need)*/}
            {currentProgress === 5 && (
              <div className="work-content" id="section-5">
                <div className="row show-mobile-flex" id="section-5-title">
                  <div className="col-lg-12">
                    <h2>All About Results</h2>
                  </div>
                </div>
                <div className="row center-item" id="section-5-1">
                  <div className="col-lg-9">
                    <div className="">
                      <Player autoPlay
                        fluid={false}
                        width='100%'
                      >
                        <source src={tutorVideo} />
                      </Player>
                    </div>
                  </div>
                  <div className="col-lg-9 remarks">
                    *Students who use GradeGetter are likely to develop better
                    study habits and improve grades
                  </div>
                </div>
              </div>
            )}

            {/* Plan */}
            {currentProgress === 10 && (
              <div className="work-content" id="section-10">
                <div className="row" id="section-1-1">
                  <div className="col-lg-12">
                    <h1>Your first lesson was FREE</h1>
                  </div>
                  <div className="col-lg-12">
                    <div className="done-free-trial">
                      Wow! You completed your free trial lesson with {this.state.tutor_name}.
                      Please select a tutoring plan to continue tutoring.
                      You can always request a new tutor or cancel your plan at any time.
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <p className="plan-small-title">
                      Select your payment frequency:
                    </p>
                  </div>
                  {plans
                    ? plans.map((item, index) => (
                      <div
                        className="col-lg-3 col-md-3 col-sm-6 col-6 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changePricingPlan("payment_frequency", item, 2)
                        }
                      >
                        <div
                          className={`grade-level center-item ${progress_1.payment_frequency.name === item.name
                            ? "active"
                            : ""
                            }`}
                        >
                          {item.name}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    ))
                    : null}
                </div>
                <div className="row" id="section-1-1" ref="section12">
                  <div className="col-lg-12">
                    <p className="plan-small-title">
                      Select how many tutoring hours you need: {" "}
                      {progress_1.payment_frequency.name}
                    </p>
                    <div className="slider-bar">
                      <div className="slider-des-body text-center">
                        {plan_tutoring_hour}{plan_tutoring_hour === 1 ? " hour" : " hours"}
                      </div>
                      <WeekHourSlider
                        weeklyValue={plan_tutoring_hour}
                        weeklyHour={(e) => this.weeklyHour(e)}
                        min={progress_1.payment_frequency.min_hour}
                        max={progress_1.payment_frequency.max_hour}
                      />
                    </div>
                  </div>
                </div>

                <div className="row" id="section-1-1" ref="section12">
                  <div className="col-lg-12">
                    <p className="plan-small-title">
                      Choose your price (hourly rate):
                    </p>
                    <div className="col-lg-12 price-note">
                      At GradeGetter, we let our customers choose what they can pay for online and one-on-one tutoring.
                      Paying a little more helps to offset the cost for families who might not be able to afford tutoring regularly.
                      With this approach, we are able to make private tutoring more accessible and affordable for everyone.
                    </div>
                    <div className="slider-bar">
                      <div className="slider-des-body text-center">
                        ${plan_rate_per_hour}/hour
                      </div>
                      <HourlyRateSlider
                        hourlyValue={plan_rate_per_hour}
                        hourlyRate={(e) => this.hourlyRate(e)}
                        min={progress_1.payment_frequency.min_rate}
                        max={progress_1.payment_frequency.max_hrate}
                      />
                    </div>
                  </div>
                </div>

                <div className="row" id="section-10-2">
                  <div className="col-lg-12 note">
                    <p>
                      {progress_1.subject.name}cost for{" "}
                      {plan_tutoring_hour}{plan_tutoring_hour === 1 ? " hour" : " hours"}:{" "}
                      <span className="totoal-price">
                        ${plan_tutoring_hour * plan_rate_per_hour}
                      </span>{" "}
                      (No payment due today.){" "}
                    </p>
                  </div>

                  <div className="col-lg-12"></div>
                  {(progress_1.payment_frequency !== "") && (
                    <div
                      className="col-lg-12 show-web-flex"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        className="ok-btn center-item"
                        onClick={(e) => this.scrollToDom(10, 4)}
                      >
                        OK{" "}
                        <ReactSVG
                          src={tick}
                          style={{ fill: "white", marginLeft: "15px" }}
                        />
                      </div>
                      <div
                        className="center-item"
                        style={{ height: "100%", margin: "30px 0 0 20px" }}
                      >
                        Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="row" id="section-10-3" ref="section103">
                  <div className="col-lg-12">
                    <div className="free-lesson">
                      <div className="free-lesson-header">
                        <p className="text-center">One FREE lesson</p>
                        <h1 className="lesson-title text-center">
                          $0.00 due today
                        </h1>
                      </div>
                      <div className="free-lesson-body">
                        {FreeLesson.map((item, index) => {
                          return (
                            <div className="body-item" key={index}>
                              <div>
                                <img src={checkmark} alt="checkmark" />
                              </div>
                              <div className="explain">{item.des}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="under-dot additional-bg" />
                  </div>
                  <div
                    className="col-lg-12 show-web-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="ok-btn center-item"
                      onClick={(e) => this.scrollToDom(10, 4)}
                    >
                      OK{" "}
                      <ReactSVG
                        src={tick}
                        style={{ fill: "white", marginLeft: "15px" }}
                      />
                    </div>
                    <div
                      className="center-item"
                      style={{ height: "100%", margin: "30px 0 0 20px" }}
                    >
                      Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                    </div>
                  </div>
                </div> */}

                <div className="row" id="section-10-3" ref="section104">
                  <div className="coupon-area">
                    <div className="coupon-row-item">
                      <div className="coupon-txt">Coupon code?</div>
                      <Toggle
                        className="coupon-toggle"
                        value={isCouponCode}
                        onChange={(value) => this.setState({ isCouponCode: value })}
                      />
                    </div>
                    <div className="coupon-row-item">
                      {isCouponCode && <div>
                        <InputBox
                          placeholder="Enter coupon"
                          value={coupon_code}
                          onChange={(e) => this.setState({ coupon_code: e })}
                        />
                      </div>}
                      {(isCouponCode && coupon_code) && <div
                        className="ok-btn center-item coupon-ok-btn"
                        onClick={(e) => this.scrollToDom(10, 5)}
                      >
                        OK{" "}
                        <ReactSVG
                          src={tick}
                          style={{ fill: "white", marginLeft: "15px" }}
                        />
                      </div>}
                    </div>
                  </div>
                </div>

                <div className="row" id="section-10-3" ref="section105">
                  <div className="col-lg-12">
                    <div className="free-lesson">
                      <div className="free-lesson-header">
                        <p className="text-center">{progress_1.payment_frequency.name} Payment Plan</p>
                        <h1 className="lesson-title text-center">
                          ${numeral(plan_tutoring_hour * plan_rate_per_hour).format('0,0.00')}/
                          {progress_1.payment_frequency.name === 'Weekly' ? 'Week' :
                            progress_1.payment_frequency.name === 'Biweekly' ? 'Biweek' :
                              progress_1.payment_frequency.name === 'Monthly' ? 'Month' : 'One Time'
                          }
                        </h1>
                      </div>
                      <div className="free-lesson-body">
                        <div className="body-item">
                          <div>
                            <img src={checkmark} alt="checkmark" />
                          </div>
                          <div className="explain">
                            {" "}
                            ${plan_rate_per_hour}/hour
                          </div>
                        </div>
                        <div className="body-item">
                          <div>
                            <img src={checkmark} alt="checkmark" />
                          </div>
                          <div className="explain">
                            {" "}
                            {plan_tutoring_hour} &nbsp; tutoring {plan_tutoring_hour === 1 ? " hour" : " hours"} &nbsp;
                            {progress_1.payment_frequency.name === 'Weekly' ? 'weekly' :
                              progress_1.payment_frequency.name === 'Biweekly' ? 'biweekly' :
                                progress_1.payment_frequency.name === 'Monthly' ? 'monthly' : 'one time'
                            }
                          </div>
                        </div>
                        {PaymentPlan.map((item, index) => {
                          return (
                            <div className="body-item" key={index}>
                              <div>
                                <img src={checkmark} alt="checkmark" />
                              </div>
                              <div className="explain">{item.des}</div>
                            </div>
                          );
                        })}
                        <div className="body-item">
                          <div>
                            <img src={checkmark} alt="checkmark" />
                          </div>
                          <div className="explain">
                            {" "}
                            {progress_1.payment_frequency.name !== 'One Time' ? "Recurring" : ""} &nbsp;
                            {progress_1.payment_frequency.name === 'Weekly' ? 'weekly' :
                              progress_1.payment_frequency.name === 'Biweekly' ? 'biweekly' :
                                progress_1.payment_frequency.name === 'Monthly' ? 'monthly' : 'one time'
                            }
                            &nbsp; payment
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="under-dot additional-bg2" />
                  </div>
                  <div
                    className="col-lg-12 show-web-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="ok-btn center-item"
                      onClick={(e) => this.scrollToDom(0, 0)}
                    >
                      OK{" "}
                      <ReactSVG
                        src={tick}
                        style={{ fill: "white", marginLeft: "15px" }}
                      />
                    </div>
                    <div
                      className="center-item"
                      style={{ height: "100%", margin: "30px 0 0 20px" }}
                    >
                      Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Payment Method (not need)*/}
            {currentProgress === 6 && (
              <div className="work-content" id="section-6">
                <div className="row show-mobile-flex" id="section-6-title">
                  <div className="col-lg-12">
                    <h2>Payment Information</h2>
                  </div>
                </div>
                <div className="row" id="section-6-1">
                  <div className="col-lg-12">
                    <h1>Choose Payment Method</h1>
                  </div>
                  {Payments.map((item, index) => (
                    index === 0 && (
                      <div
                        className="col-lg-6 col-12 grade-level-col"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={(e) =>
                          this.changeProgress_6("payment_method", item.type)
                        }
                      >
                        <div className={`grade-level center-item`}>
                          <img src={item.picture} alt="tutor" />
                          {item.type}
                        </div>
                        <div className="under-dot grade-level-bg" />
                      </div>
                    )
                  ))}
                </div>
              </div>
            )}

            {/* Payment Card*/}
            {currentProgress === 7 && (
              <div className="work-content" id="section-7">
                <div className="row show-mobile-flex" id="section-1-title">
                  <div className="col-lg-12">
                    <h2>Payment Information</h2>
                  </div>
                </div>

                <div className="row" id="section-7-1" ref="section71">
                  <div className="col-lg-12">
                    <div className="step-description">
                      Payment of $1 for your trial lesson is processed and secured by Braintree Payments, a PayPal company.
                      It is also the payment platform for some of the world's leading brands - Uber, Airbnb, Yelp, Krispy Kreme, etc. No additional payment is requirement until you 100% happy with your trial lesson
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h1>Card Details</h1>
                  </div>

                  <div className="col-lg-12 profile-col">
                    {" "}
                    <CardInputBox
                      icon={creditcard}
                      type="number"
                      onChange={(e) => this.inputProgress_7("card_number", e)}
                    />
                    {" "}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Month"
                      type="number"
                      onChange={(e) => this.inputProgress_7("month", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 profile-col">
                    {" "}
                    <InputBox
                      placeholder="Year"
                      type="number"
                      onChange={(e) => this.inputProgress_7("year", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 profile-col">
                    {" "}
                    <InputBox
                      placeholder="CVV2"
                      onChange={(e) => this.inputProgress_7("cvv2", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      icon={creditcard}
                      placeholder="Name on card"
                      onChange={(e) => this.inputProgress_7("card_name", e)}
                    />{" "}
                  </div>
                  {progress_7.card_number.length > 0 &&
                    progress_7.month.length > 0 &&
                    progress_7.year.length > 0 &&
                    progress_7.cvv2.length > 0 &&
                    progress_7.card_name.length > 0 && (
                      <div
                        className="col-lg-12 show-web-flex"
                        style={{ alignItems: "center" }}
                      >
                        <div
                          className="ok-btn center-item"
                          onClick={(e) => this.scrollToDom(7, 2)}
                        >
                          OK{" "}
                          <ReactSVG
                            src={tick}
                            style={{ fill: "white", marginLeft: "15px" }}
                          />
                        </div>
                        <div
                          className="center-item"
                          style={{ height: "100%", margin: "30px 0 0 20px" }}
                        >
                          Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                        </div>
                      </div>
                    )}
                </div>


                <div className="row" id="section-7-2" ref="section72">
                  <div className="col-lg-12">
                    <h1>Billing address</h1>
                  </div>
                  <div className="col-lg-12 profile-col">
                    {" "}
                    <InputBox
                      icon={home}
                      placeholder="Address"
                      onChange={(e) =>
                        this.inputProgress_7("billing_address", e)
                      }
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={place}
                      placeholder="Zipcode"
                      type="number"
                      onChange={(e) => this.inputProgress_7("zip_code", e)}
                    />{" "}
                  </div>
                  <div className="col-lg-6 profile-col">
                    {" "}
                    <InputBox
                      icon={narrow}
                      placeholder="City"
                      onChange={(e) => this.inputProgress_7("city", e)}
                    />{" "}
                  </div>
                  {progress_7.billing_address.length > 0 &&
                    progress_7.zip_code.length > 0 &&
                    progress_7.city.length > 0 && (
                      <div
                        className="col-lg-12 show-web-flex"
                        style={{ alignItems: "center" }}
                      >
                        <div
                          className="ok-btn center-item"
                          onClick={(e) => this.scrollToDom(0, 0)}
                        >
                          OK{" "}
                          <ReactSVG
                            src={tick}
                            style={{ fill: "white", marginLeft: "15px" }}
                          />
                        </div>
                        <div
                          className="center-item"
                          style={{ height: "100%", margin: "30px 0 0 20px" }}
                        >
                          Press <b style={{ marginLeft: "5px" }}>ENTER</b>
                        </div>
                      </div>
                    )}
                  <div className="inner-area">
                    <CheckBox
                      className="check-box"
                      value={terms_checked}
                      onChange={(e) => this.setState({ terms_checked: e })}
                    />
                    <p>On the GradeGetter platform, your subscription includes a set number of tutoring minutes for a specified period of time. The tutoring minutes do not roll over from one period to another. Cancel at any time. You are billed either weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually. Your membership will be renewed automatically at the beginning of each cycle until you cancel.
                      Tutor satisfaction guaranteed: If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost </p>
                  </div>
                </div>


              </div>
              // </Elements>

            )}

            {/* Training Video */}
            {currentProgress === 8 && (
              <div className="work-content" id="section-8">
                <div className="row show-mobile-flex" id="section-8-title">
                  <div className="col-lg-12">
                    <h2>Training Video</h2>
                  </div>
                </div>
                <div className="row" id="section-8-1">
                  <div className="col-lg-12">
                    {/* <div className="video-area shadow-object"> */}
                    <div className="">
                      <Player autoPlay
                        fluid={false}
                        width='100%'
                      >
                        <source src={newClient} />
                      </Player>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            )}

            {/* share Better Grades (not need)*/}
            {currentProgress === 9 && (
              <div className="work-content" id="section-9">
                <div className="row show-mobile-flex" id="section-9-title">
                  <div className="col-lg-12">
                    <h2 >Share Better Grades</h2>
                  </div>
                </div>
                <div className="row" id="section-9-1">
                  <div className="col-lg-12 grade-level-col">
                    <div className="custom-input">
                      <div className="inputbox-container">
                        <input
                          type="text"
                          className="search-text"
                          value={shareURL}
                          readOnly
                          ref={(input) => (this.myUrl = input)}
                        />
                        <div
                          className="predend-text"
                          onClick={(e) => this.copyMyLink()}
                        >
                          COPY
                        </div>
                      </div>
                      <div className="under-dot inputbox-bg" />
                    </div>
                  </div>
                  {Shares.map((item, index) => (
                    <div
                      className="col-lg-6 grade-level-col"
                      key={index}
                      style={{ position: "relative" }}
                    >
                      {index === 0 ?
                        <EmailShareButton url={shareURL} className={`grade-level center-item`}>
                          <div className={`grade-level center-item`}>
                            <img src={item.picture} alt="tutor" />
                            {item.type}
                          </div>
                        </EmailShareButton>
                        :
                        index === 1 ?
                          <FacebookShareButton url={shareURL} className="share-logo grade-level center-item" >
                            <div className={`grade-level center-item`}>
                              <img src={item.picture} alt="tutor" />
                              {item.type}
                            </div>
                          </FacebookShareButton>
                          :
                          <TwitterShareButton url={shareURL} className="share-logo grade-level center-item" >
                            <div className={`grade-level center-item`}>
                              <img src={item.picture} alt="tutor" />
                              {item.type}
                            </div>
                          </TwitterShareButton>
                      }
                      <div className="under-dot grade-level-bg" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {currentProgress !== 6 && (
            <div
              className={`submit-area ${(currentProgress >= 3 && currentProgress < 6) ||
                currentProgress === 8
                ? "custom-submit"
                : ""
                } ${currentProgress === 9 ? "final-submit" : ""}`}
            >
              <div
                className="container submit-container"
                // style={{ paddingBottom: window.outerHeight / 2 + 100 }}
                style={{ paddingBottom: 50 }}
              >
                {currentProgress < 7 && (
                  <div className="btn-area" ref="section00">
                    <div
                      className="back-btn"
                      onClick={(e) => this.moveSection("previous")}
                    >
                      <div className="back-icon center-item">
                        <ReactSVG src={arrow} style={{ fill: "#C0C5C9" }} />
                      </div>
                      <div className="back-text center-item">Back</div>
                    </div>
                    {currentProgress === 1 && progress_1.subject !== "" ||
                      currentProgress === 2 ||
                      currentProgress === 3 ||
                      currentProgress === 4 && progress_4.first_name !== "" && progress_4.last_name !== "" && progress_4.email !== "" && progress_4.password !== "" && progress_4.phone !== "" ||
                      currentProgress === 5 ||
                      currentProgress === 6
                      ?
                      <div>
                        <StartButton
                          className="start-btn show-web-flex"
                          height="65px"
                          startText={`Continue ${currentProgress}`}
                          onClick={
                            (e) => currentProgress === 4 ? this.onSubmit() :
                              currentProgress === 2 ? this.reSubmit() : this.moveSection("next")
                          }
                        />
                        <StartButton
                          className="start-btn show-mobile-flex"
                          height="50px"
                          startText={`Continue ${currentProgress}`}
                          onClick={
                            (e) => currentProgress === 4 ? this.onSubmit() :
                              currentProgress === 2 ? this.reSubmit() : this.moveSection("next")
                          }
                        />
                      </div>
                      :
                      <div>
                        <StartButtonDisable
                          className="start-btn show-web-flex"
                          height="65px"
                          startText={`Continue ${currentProgress}`}
                        />
                        <StartButtonDisable
                          className="start-btn show-mobile-flex"
                          height="50px"
                          startText={`Continue ${currentProgress}`}
                        />
                      </div>
                    }
                  </div>
                )}
                <div>
                  {/* {signErr ? (
                    <span className="text-danger float-right">{signErr}</span>
                  ) : null} */}
                </div>
                <br />
                {currentProgress === 7 && (
                  <div className="btn-area" ref="section00">
                    <div
                      className="back-btn"
                      onClick={(e) => this.moveSection("previous")}
                    >
                      <div className="back-icon center-item">
                        <ReactSVG src={arrow} style={{ fill: "#C0C5C9" }} />
                      </div>
                      <div className="back-text center-item">Back</div>
                      {" "}
                    </div>
                    {progress_7.card_number.length > 0 &&
                      progress_7.month.length > 0 &&
                      progress_7.year.length > 0 &&
                      progress_7.cvv2.length > 0 &&
                      progress_7.card_name.length > 0 &&
                      progress_7.billing_address.length > 0 &&
                      progress_7.zip_code.length > 0 &&
                      progress_7.city.length > 0 &&
                      terms_checked ? (
                      <div>
                        <StartButton
                          className="start-btn show-web-flex"
                          height="65px"
                          startText="Continue 7"
                          onClick={(e) => this.paymentFunction()}
                        />
                        <StartButton
                          className="start-btn show-mobile-flex"
                          height="50px"
                          startText="Continue 7"
                          onClick={(e) => this.paymentFunction()}
                        />
                      </div>
                    ) : (
                      <div>
                        <StartButtonDisable
                          className="start-btn show-web-flex"
                          height="65px"
                          startText="Continue 7"
                        />
                        <StartButtonDisable
                          className="start-btn show-mobile-flex"
                          height="50px"
                          startText="Continue 7"
                        />
                      </div>
                    )}
                  </div>
                )}
                {currentProgress === 10 && (
                  <div className="plan-continue-btn" ref="section00">
                    {/* <div
                      className="back-btn"
                      onClick={(e) => this.moveSection("previous")}
                    >
                      <div className="back-icon center-item">
                        <ReactSVG src={arrow} style={{ fill: "#C0C5C9" }} />
                      </div>
                      <div className="back-text center-item">Back</div>
                    </div> */}
                    {progress_1.payment_frequency !== "" ?
                      <div>
                        <StartButton
                          className="start-btn show-web-flex"
                          height="65px"
                          startText="Continue 6"
                          // onClick={(e) => this.moveSection("next")}
                          onClick={() => this.profileUpdate()}
                          profileUpdate
                        />
                        <StartButton
                          className="start-btn show-mobile-flex"
                          height="50px"
                          startText="Continue 6"
                          onClick={(e) => this.profileUpdate("next")}
                        />
                      </div>
                      :
                      <div>
                        <StartButtonDisable
                          className="start-btn show-web-flex"
                          height="65px"
                          startText="Continue 6"
                        />
                        <StartButtonDisable
                          className="start-btn show-mobile-flex"
                          height="50px"
                          startText="Continue 6"
                        />
                      </div>
                    }
                  </div>
                )}
                {currentProgress === 8 && (
                  <div className="btn-area" ref="section00">
                    <div
                      className="back-btn back-btn2"
                      onClick={(e) => this.goToDashboard()}
                    >
                      {" "}
                      <div className="back-text center-item">Skip</div>{" "}
                    </div>
                    <StartButton
                      className="start-btn show-web-flex"
                      height="65px"
                      startText="Done Watching"
                      onClick={(e) => this.goToDashboard()}
                    />
                    <StartButton
                      className="start-btn show-mobile-flex"
                      height="50px"
                      startText="Done Watching"
                      onClick={(e) => this.goToDashboard()}
                    />
                  </div>
                )}
                {currentProgress === 9 && (
                  <div className="btn-area" style={{ justifyContent: 'flex-end' }} ref="section00">
                    {/* <div
                      className="back-btn back-btn3"
                      onClick={(e) => this.goToDashboard()}
                    >
                      {" "}
                      <div className="back-text center-item">Skip</div>{" "}
                    </div> */}
                    <StartButton
                      className="start-btn show-web-flex"
                      height="65px"
                      startText="Skip"
                      onClick={(e) => this.goToDashboard()}
                    />
                    <StartButton
                      className="start-btn show-mobile-flex"
                      height="50px"
                      startText="Skip"
                      onClick={(e) => this.goToDashboard()}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subjects: state.Core.subjects,
  levels: state.Core.levels,
  connections: state.Core.connections,
  plans: state.Core.plans,
  braintreeKey: state.Core.braintreeKey,
  token: state.Auth.token,
  user: state.Auth.user,
  signupHubspotStatus: state.Auth.signupHubspotStatus,
  hubspotErr: state.Auth.hubspotErr,
  signErr: state.Auth.signErr,
  profileUpdate: state.Auth.profileUpdate,
  profileUpdateErr: state.Auth.profileUpdateErr,
  nonceUpdate: state.Auth.nonceUpdate,
  firstChargeError: state.Payment.firstChargeError,
  firstChargeResMessage: state.Payment.firstChargeResMessage,
});

export default connect(mapStateToProps, {
  getLevelAction,
  getSubjectAction,
  getConnectionAction,
  getPlanAction,
  getBraintreekeyAction,
  signUpAction,
  profileUpdateAction,
  signUpHubspotAction,
  sendNoneToServerAction,
  firstChargeAction,
})(withRouter(SignUpPage));
