import React from "react";
import "./ServicesAreas.scss";
import Loadable from "@loadable/component";
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import { LocationList } from "../../";
import _ from 'lodash'

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default class ServicesAreas extends React.Component {

  render() {
    const { service_areas } = this.props;
    return (
      <>
         {!_.isEmpty(service_areas) && <div className="services-areas-component">
          <div className="container services-areas-container">
            <h2 className="title">Services Areas</h2>
            <div className="col-lg-12 main-row">
            <Media
                queries={{
                  lg: "(min-width: 1200px)",
                  md: "(min-width: 992px) and (max-width: 1199px)",
                  sm: "(min-width: 768px) and (max-width: 991px)",
                  normal: "(max-width: 767px)",
                }}
              >
                {(matches) => (
                  <OwlCarousel
                    className="owl-theme"
                    nav
                    stagePadding={
                      matches.lg ? 15 : matches.md ? 5 : matches.sm ? 5 : 15
                    }
                    margin={
                      matches.lg ? 28 : matches.md ? 33 : matches.sm ? 45 : 20
                    }
                    items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                    dots={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {service_areas.map((item, index) => {
                      return <LocationList key={index} data={item.data}/>;
                    })}
                  </OwlCarousel>
                )}
              </Media>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
