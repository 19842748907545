import React from "react";
import "./HeaderV3.scss";
import logo from "../../../Assets/Header/logo.png";
import logo2 from "../../../Assets/Header/logo2.png";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class HeaderV3 extends React.Component {
  state = {
    transform: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    this.setState({
      transform: window.scrollY > 0 ? true : false,
    });
  };

  isHomeV3 = () => {
    if (
      this.props.location.pathname.replaceAll("/", "") === "tutoring"
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { user, token } = this.props;
    return (
      <React.Fragment>
        <div
            className={`header-v3-component ${
              this.state.transform
                ? "transform-header-v3"
                : ""
            }`}
            style={{display: !this.isHomeV3() && 'none'}}
          >
            <div className="container normal-container">
              <NavLink className="menu-item show-web-flex" exact to="/">
                <img src={logo} alt="logo" />
              </NavLink>
              <NavLink className="menu-item show-mobile" exact to="/">
                <img src={logo2} alt="logo" />
              </NavLink>              

              <div className="menu extra-menu">
                <NavLink
                  className="menu-item sign-up shadow-object"
                  exact
                  to="/sign-up"
                >
                  Start FREE trial
                </NavLink>
              </div>              
            </div>
          </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, { 

})(withRouter(HeaderV3));
