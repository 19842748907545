import React from "react";
import "./ClientSayingV2.scss";
import Loadable from "@loadable/component";
import Media from "react-media";
import { ClientFeedbackV2 } from "../../";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import arrow from '../../../Assets/HomeV2/arrow.png';
import { StartButtonV2 } from "../../";
import { NavLink } from "react-router-dom";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default class ClientSayingV2 extends React.Component {

    getConnection() {
        return this.props.reviews.map((client) => {
            return {
                name: client.tutor.name,
                address: client.tutor.location,
                level: 1,
                knowledge: client.rating1,
                presentation: client.rating2,
                comment_date: new Date(client.reviewed_on).toDateString(),
                description: client.review_content,
            };
        });
    }

    render() {
        const clients = this.getConnection();
        return (
            <div className="reviewsarea-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 v-r sub-col">
                            <div className="arrow show-web" >
                                <img src={arrow} alt="arrow" />
                            </div>
                            <div className="">
                                <h1>Our parents and student loves us as much as we love them</h1>
                                {/* <div className="btn-request v-c h-c btn" onClick={()=>this.request()}>REQUEST TUTORING INFO</div> */}
                                <NavLink style={{ textDecoration: "none" }} to="/sign-up">
                                    <StartButtonV2
                                        className="start-btn"
                                        startText="START FREE TRIAL LESSON"
                                    />  
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <Media queries={{
                                ex: "(min-width: 1200px)",
                                lg: "(min-width: 992px)",
                                md: "(min-width: 768px)",
                                }}>
                                {matches => (
                                    <OwlCarousel
                                        className="owl-theme"
                                        rewind
                                        nav
                                        stagePadding={0}
                                        margin={matches.ex ? 30 : 15}
                                        items={ matches.lg ? 2 : 1}
                                        dots={false}
                                        loop={false}
                                        navText={[CarouselLeftButton, CarouselRightButton]}
                                        >
                                        { clients.map((item, index) => <ClientFeedbackV2 info={item} index={index} key={index} />)}
                                    </OwlCarousel>
                                )}
                            </Media>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
