import React, { useEffect } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import SubjectLogo from 'src/Assets/CommonQuestions/logo-subject.svg';
import SubjectItem from './SubjectItem';
import { PQuestionSummary } from 'src/Components';

const SubjectListInfo = [
  { id: "1", name: "Chemistry", logo: SubjectLogo, },
  { id: "2", name: "Biology", logo: SubjectLogo, },
  { id: "3", name: "Physics", logo: SubjectLogo, },
  { id: "4", name: "Reading", logo: SubjectLogo, },
  { id: "5", name: "Accounting", logo: SubjectLogo, },
  { id: "6", name: "Economics", logo: SubjectLogo, },
  { id: "7", name: "Anatomy and Physiology", logo: SubjectLogo, },
  { id: "8", name: "History", logo: SubjectLogo, },
  { id: "9", name: "Economics", logo: SubjectLogo, },
  { id: "10", name: "History", logo: SubjectLogo, },
  { id: "11", name: "Reading", logo: SubjectLogo, },
  { id: "12", name: "MATH", logo: SubjectLogo, },
  { id: "13", name: "Reading", logo: SubjectLogo, },
  { id: "14", name: "Accounting", logo: SubjectLogo, },
  { id: "15", name: "Reading", logo: SubjectLogo, },
  { id: "16", name: "MATH", logo: SubjectLogo, },
]

export default function SubjectList() {
  let history = useHistory();

  const onSubject = (item) => {
    history.push(`/practice-questions/topics`);
  }

  return (
    <div className="subject-lists-component">
      <div className="container home-start-container">
        <h2>Subjects</h2>
        <div className='sub-description'>Select subject you want to discover</div>
        <div className="row mt-4">
          <div className="col-lg-8 left-content">
            <div className='subject-list'>
              {SubjectListInfo.map((item, index) => (
                index < 12 && <SubjectItem
                  key={index}
                  info={item}
                  onClick={() => onSubject(item) }
                />
              ))}
            </div>
            <div className='btn-3 load-more-btn'>LOAD MORE</div>
          </div>
          <div className="col-lg-4 right-content">
            <PQuestionSummary />
          </div>
        </div>
      </div>
    </div>
  );
}