import React from "react";
import "./BrandV2.scss";
import brand1 from "../../../Assets/HomeV2/brand1.png";
import brand2 from "../../../Assets/HomeV2/brand2.png";
import brand3 from "../../../Assets/HomeV2/brand3.png";
import brand4 from "../../../Assets/HomeV2/brand4.png";
import brand5 from "../../../Assets/HomeV2/brand5.png";
import brand6 from "../../../Assets/HomeV2/brand6.png";

const BrandList = [
  brand1, brand2, brand3, brand4, brand5, brand6,
];

export default class BrandV2 extends React.Component {
  render() {
    return (
      <div className="brand-v2-component">
        <div className="container brand-container">
          <div className="row main-row show-web">
            <div className="col-lg-12 left-area ">
              {BrandList.map((item, index)=> (
                <div key={index} className="item-area">
                  <div>
                    <img src={item} alt="logo" />
                  </div>                  
                </div>
              ))}
            </div>
          </div>
          <div className="row main-row show-mobile">
            <div className="col-lg-6 left-area ">
              {BrandList.map((item, index)=> (
                index < 3 && <div key={index} className="item-area">
                  <div>
                    <img src={item} alt="logo" />
                  </div>                  
                </div>
              ))}
            </div>            
            <div className="col-lg-6 right-area ">
              {BrandList.map((item, index)=> (
                index > 2 && <div key={index} className="item-area">
                  <div>
                    <img src={item} alt="logo" />
                  </div>                  
                </div>
              ))}
            </div>            
          </div>
        </div>
      </div>
    );
  }
}
