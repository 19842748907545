import React from 'react';
import './style.scss';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helper from '../../../Util/Helper';
import {
  SubjectHeader,
  SubjectList,
} from 'src/Components';

import {
  getCQSubjectsAction
} from '../../../Redux/Actions'
import { Helmet } from 'react-helmet';

class CQSubjectsPage extends React.Component {

  componentDidMount() {
    Helper.showSpinner()
    this.props.getCQSubjectsAction().then(() => {
      Helper.hideSpinner()
    })
  }

  render() {
    const { subjects } = this.props
    const title = "Common Questions on Test Prep | Answers from Experts | GradeGetter"
    const description = "Visit our Common Questions page at GradeGetter.com for detailed answers to all your queries about various test preps. Let our experts guide you to success in your academic journey."

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/logos/common-questions.png" />

           {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content="https://gradegetter.com/logos/common-questions.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content="https://gradegetter.com/logos/common-questions.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content="https://gradegetter.com/logos/common-questions.png" />
        </Helmet>
      <div className="cq-subjects-page">
        <SubjectHeader />
        { subjects && <SubjectList subjects={subjects} />}
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subjects: state.Question.cq_subjects
})

export default connect(mapStateToProps, {
  getCQSubjectsAction
})(withRouter(CQSubjectsPage))