import React from 'react';
import './style.scss';
import QuestionImg from 'src/Assets/CommonQuestions/question-img.png';

function innerFunc(item) {
  return { __html: item }
}

export default function QuestionDescription({ Question }) {

  return (
    <div className="question-description-component">
      <img src={process.env.REACT_APP_IMAGE_DOMAIN + Question.picture} alt='question-img' />
      <p dangerouslySetInnerHTML={innerFunc(Question.full_answer)}></p>
    </div>
  );
}