import React from 'react';
import './style.scss';
import SubjectHeaderImg from 'src/Assets/PracticeTest/Header-img.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function SubjectHeader({ subject }) {
  const history = useHistory()
  
  const onNavigate = () => {
    history.push('/practice-tests')
  }

  return (
    <div className="practice-test-header-component">
      <div className="container subject-start-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <div className='bread-crumb'>
              <span className={subject === undefined ? 'opacity' : ''} onClick={() => onNavigate()}>Practice Tests</span>
              {subject !== undefined && (
                <>
                  <span className='opacity'>/</span>
                  <span className='opacity'>{subject}</span>
                </>
              )}
            </div>
            <h1>{subject === undefined ? '' : `${subject} `}Practice Test</h1>
          </div>
          <div className="col-lg-6 show-web right-content">
            <div>
              <img src={SubjectHeaderImg} alt="header-img" className="header-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}