import React from 'react';
import './style.scss';
import SubjectHeaderImg from 'src/Assets/CommonQuestions/subject-header-img.png';

export default function SubjectHeader() {

  return (
    <div className="subject-header-component">
      <div className="container subject-start-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <h1>Common Questions</h1>
          </div>
          <div className="col-lg-6 show-web right-content">
            <div>
              <img src={SubjectHeaderImg} alt="header-img" className="header-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}