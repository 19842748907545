import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {
  HomeV2Page,
  HomeV3Page,
} from "../Pages";
class AdsRoutes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/toptutors" component={HomeV2Page} />
          <Route exact path="/tutoring" component={HomeV3Page} />
        </Switch>
      </div>
    )
  }
}

export default connect()(withRouter(AdsRoutes));