import React, { useState } from "react";
import './style.scss';
import RightArrowIcon from 'src/Assets/CommonQuestions/arrow-right-icon.svg';
import { ReactSVG } from "react-svg";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import QuestionSummary from "src/Components/CommonQuestions/QuestionSummary";

export default function TopicList({topics}) {
    const history = useHistory();
    const params = useParams();
    const [topicCount, setTopicCount] = useState(10)

    const onTopicClick = (item) => {
        const subject = params.subject.replace(/\s+/g, '-')
        const topic = item.name.replace(/\s+/g, '-')
        history.push(`/flashcard-questions/${subject}/${params.id}/${topic}/${item.id}`)
    }

    return (
        <div className="fc-list-section">
            <div className="container">
                <div className="row row-md fc-wrapper">
                    <div className="col-lg-8 left-section">
                        <div className="row row-sm">
                            {topics
                                .slice(0, topicCount)
                                .map(item => (
                                <div className="col-lg-6" key={item.id}>
                                    <div className="topic-item-wrapper">
                                        <div className="topic-item-card" onClick={() => onTopicClick(item)}>
                                            <div className="item-container">
                                                <div className="title">{item.name}</div>
                                                <ReactSVG
                                                    src={RightArrowIcon}
                                                    className='open-close-icon'
                                                />
                                            </div>
                                        </div>
                                        <div className="dotted-bg"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="right-section col-lg-4">
                        { topics.length > 0 && <QuestionSummary banner={topics[0].banner} /> }
                    </div>   
                </div>
                 { topicCount < topics.length && <div className='btn-3 load-more-btn' onClick={() => setTopicCount(topicCount + 10)}>LOAD MORE</div>}
            </div>
        </div>
    )
}