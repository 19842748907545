import {
  FIRST_CHARGE,
  FIRST_CHARGE_SUCCESS,
  FIRST_CHARGE_FAIL,
} from "../Type";
const INITIAL = {
  firstChargeError: true,
  firstChargeResMessage: "",
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    // FIRST CHARGE
    case FIRST_CHARGE:
      return {
        ...state,
        firstChargeError: true,
        firstChargeResMessage: ""
      };
    case FIRST_CHARGE_SUCCESS:
      return {
        ...state,
        firstChargeError: action.payload.error,
        firstChargeResMessage: action.payload.message
      };
    case FIRST_CHARGE_FAIL:
      return {
        ...state,
        firstChargeError: action.payload.error,
        firstChargeResMessage: action.payload.message
      };

    default:
      return state;
  }
};
