import React from 'react';
import './TutorHomePage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TutorStartArea, TutorFeatures, TutorJobs } from '../../Components';
import { Helmet } from 'react-helmet';

class TutorHomePage extends React.Component {

    getStarted = (formobject) => {
        this.props.history.push({
            pathname : '/tutor-signup',
            state: formobject
        })
    } 
    render() {
        return (
            <>
                <Helmet>
                    <title>Access Hundreds Of Online Tutoring Jobs | GradeGetter</title>
                    <meta name="title" content="Access Hundreds Of Online Tutoring Jobs | GradeGetter" />
                    <meta name="description" content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College" />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content="Access Hundreds Of Online Tutoring Jobs | GradeGetter" />
                    <meta itemprop="description" content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College" />
                    <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Access Hundreds Of Online Tutoring Jobs | GradeGetter" />
                    <meta property="og:description" content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College" />
                    <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Access Hundreds Of Online Tutoring Jobs | GradeGetter"/>
                    <meta name="twitter:description" content="Become A Tutor In Under 30 Minutes And Access Hundreds Of Online Tutoring Jobs For K-12, Test Prep, and College"/>
                    <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
                </Helmet>
                <div className="tutorhome-page v-r">
                    <TutorStartArea onNext={this.getStarted} />
                    <TutorFeatures />
                    <TutorJobs onNext={this.getStarted}/>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps, { })(withRouter(TutorHomePage));