import React from "react";
import "./ChooseUniversityTutor.scss";
import { Accordion } from "../../../Components";
import _ from 'lodash'

export default class ChooseUniversityTutor extends React.Component {
  
  render() {
    const { school_faq } = this.props;
    return (
      <>
        {!_.isEmpty(school_faq) && <div className="topics-component">
          <div className="container topics-container">
            <div className="title">{school_faq.title}</div>
            <div className="topics-list shadow-object">
              {school_faq.topics.map((item, index) => {
                return (
                  <Accordion
                    className="accordion"
                    title={item.title}
                    content={item.content}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </div>}
      </>
    );
  }
}
