import React from 'react';
import './Comment.scss';

export default class Comment extends React.Component {
    render() {
        return (
            <div className="comment-component">
                <div className="comment-header">
                    <div className="select-section">
                        
                    </div>
                </div>
            </div>
        );
    }
}