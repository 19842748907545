import React from "react";
import "./SubjectLandingPage.scss";
import { connect } from "react-redux";
import LazyLoad from 'react-lazyload';
import {
  StartArea,
  About,
  TutorsInCity,
  ClientSaying,
  Pricing,
  Video,
  FAQ,
  Topics,
  Offers,
  Brochure,
  Blog,
  Meet,
  Hours,
  HowItWorks,
  Services
} from "../../Components";
import {  
  getPlanAction,
  getHomeDataAction,
} from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";

class SubjectLandingPage extends React.Component {
  state = {
    route_subject: '',
  };

  componentDidMount() {
    this.props.getPlanAction();    
    const { subject } = this.props.match.params;
    const route_subject = subject.replace(/-/g, ' ');
    this.setState({route_subject});
    this.props.getHomeDataAction("", route_subject);
  };

  render() {
    const { plans, LandingData } = this.props;
    const { route_subject } = this.state;
    const metaTitle = `#1 Online ${route_subject} Tutors & ${route_subject} Near You | 1 FREE Lesson`
    const alttext = `Hire Online ${route_subject} tutors from top U.S Universities, and your first lesson is free`
    const offer_title = `Promotional Offer For Online ${route_subject} Tutoring`
    const metaDescription = `Your first 30 minutes is FREE with our online ${route_subject} tutors and ${route_subject} Tutors near you. You pay what you can afford and we’re a Christian Company.`
    return (
      <main>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={metaTitle} />
          <meta itemprop="description" content={metaDescription} />
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle}/>
          <meta name="twitter:description" content={metaDescription}/>
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
        </Helmet>
        <div className="home-page" ref={this.workContainer}>
          <StartArea title={LandingData.title} imageText={alttext}/>
          <About homeData={LandingData}/>
          <LazyLoad height={200} offset={100} once>
            <TutorsInCity tutor_list={LandingData.tutor_list}/>
            <ClientSaying reviews={LandingData.reviews} />
            <HowItWorks title={LandingData.hiwtitle}/>
            {plans && <Pricing plans={plans} />}
            <Video videos={LandingData.videos} title={LandingData.videotitle}/>
            <FAQ />
            <Topics topics={LandingData.faq}/>
            <Offers offers={LandingData.offers[0]} title={offer_title}/>
            <Brochure ebook={LandingData.ebook}/>
            <Blog blogs={LandingData.blogs}/>
            <Meet director={LandingData.director}/>
            <Hours hours={LandingData.office_hour}/>
            <Services services={LandingData.services}/>
          </LazyLoad>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({  
  plans: state.Core.plans,
  LandingData: state.Core.LandingData
});

export default connect(mapStateToProps, {
  getPlanAction,
  getHomeDataAction,
})(withRouter(SubjectLandingPage));
