import React, {useState} from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import ArrowPointRight from 'src/Assets/Common/arrow-point-right.svg';

export default function QuestionItem({
  info, onClick
}) {

  return (
    <div className='topic-item-wrapper'>
      <div className="topic-item-component shadow-object">
        <div className='question-header'>
          <div className='question-title mb-2'>{info.title}</div>
          <ReactSVG
            src={ArrowPointRight}
            className='open-close-icon'
            onClick={onClick}
          />
        </div>
      </div>
      <div className='dotted-bg'></div>
     </div>
  );
}