import React from "react";
import "./Brochure.scss";
import { StartButton } from "../../";
import _ from 'lodash'

export default class Brochure extends React.Component {

  render() {
    const { ebook } = this.props;
    return (
      <>
        {!_.isEmpty(ebook) && <div className="brochure-component">
          <div className="container brochure-container">
            <h2 className="title">{ebook.name}</h2>
            <div className="description">{ebook.description}</div>
            <div className="row main-row">
              <div className="col-lg-6 order-lg-first order-second left-area">
                <StartButton 
                  className="start-btn" 
                  startText="Download"
                  isOtherBtn={true}
                  onClick={()=>window.open(ebook.link)}
                />
              </div>
              <div className="col-lg-6 order-lg-second order-first right-area ">
                <div className="image-container">
                  <img src={ebook.image} alt="brochure-img" />
                </div>
              </div>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
