import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import './BlogStartArea.scss';
import blogHeaderBG from '../../../Assets/Blog/headerbg.png'
import {mailchimpAction} from "../../../Redux/Actions";

class BlogStartArea extends React.Component {
    state = {
        mail: "",
        selectMail: null
    }

    changeMail = (value) => {
        if(value.length > 7) {
            this.setState({selectMail: true});
        } else {
            this.setState({selectMail: false});
        }
        this.setState({mail: value})
    }

    navigateToSignup = () => {
        const payload = {
            email: this.state.mail
        }
        if(this.state.selectMail){

            this.props.mailchimpAction(payload).then(()=> {
                if(this.props.mailchimpStatus === "Success"){
                    this.props.history.push(`/sign-up`);
                }
            })
        } else {
            this.setState({selectMail: false});
        }
    }

    render() {
        return (
            <div className="blogstartarea-component">
                <img src={blogHeaderBG} alt="right-bg" className="blog-header-bg show-web" />
                <div className="container blogstartarea-container">
                    <div className="col-lg-7 left-area">
                        <h1>The latest on for all things educations</h1>
                        <div className="form-area">
                            <div className="email-area">
                                <p>Subscribe and get our weekly newsletter in your index.</p>
                                <div className="send-box" >
                                    <input className={`${this.state.selectMail || this.state.selectMail === null ? "email-input" : "email-input-not-fill" }`}
                                        placeholder="Enter your Email"
                                        value={this.state.mail}
                                        onChange= {(event)=>this.changeMail(event.target.value)}
                                    />
                                    <button className="send-btn" onClick={(e) => this.navigateToSignup()}>Get Started</button>
                                    <div className="bg-dot" alt="bgdot"/>
                                </div>
                            </div>
                        </div>
                        <img src={blogHeaderBG} alt="right-bg" className="blog-header-bg show-mobile" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mailchimpStatus: state.Auth.mailchimpStatus,
});

export default connect(mapStateToProps, {
    mailchimpAction
})(withRouter(BlogStartArea));
