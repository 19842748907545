export const Privacy = [
  {
    title: "Clients",
    content: `GradeGetter collects information such as names, addresses, email addresses, phone numbers, grade levels, and referrals. We collect this information mainly for the purpose of facilitating the job duties of our tutors and administrative staff. Tutors use this information to contact their assigned families and travel to their families’ preferred tutoring locations. Administrative staff uses customer information for billing purposes. We do not disclose personal information to individuals outside of GradeGetter or to tutors who are not assigned to our customers. All online transactions are encrypted. GradeGetter may also send out surveys to current or previous customers to follow up on services and exercise quality assurance. Questions are related to previous or current GradeGetter services. This personal information is only disclosed to GradeGetter administrators.`,
  },
  {
    title: "Tutors",
    content: `Information provided by the tutor will not be disclosed to any entity outside of GradeGetter. Information apart from customer names will not be disclosed to other tutors. A tutor’s name will be disclosed to the tutor’s assigned families. Other personal information will be kept private unless authorized by the tutor.
`,
  },
  {
    title: "Applicants",
    content: `Information provided by an applicant seeking a career with Frog Tutoring or its affiliates, such as GradeGetter, will be used to verify credentials and provide a basis upon which administrators will make a decision on hiring the individual. If the applicant is not hired, information is either locked or destroyed.
`,
  },
  {
    title: "Security",
    content: `GradeGetter is committed to making sure personal information is secure. We do not sell information to third parties. Visitors under 13 years of age should not fill out provided contact forms without consent of a parent or guardian.

`,
  },
];

export const Refund = [
  {
    title: "",
    content: `On the GradeGetter platform, your subscription includes a set number of tutoring minutes for a specified period. The tutoring minutes do not roll over from one period to another. We offer refunds within the subscription period if the credit is not used. Cancel at any time. You are billed weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually. Your membership will be renewed automatically at the beginning of each cycle until you cancel.`,
  },
  {
    title: "",
    content: `Tutor satisfaction guaranteed: If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost.`,
  },
];

export const Term = [
  {
    title: "THE PARTIES TO THIS AGREEMENT ARE:",
    content: `<b>GradeGetter</b>, of 1751 River Run Suite 200, Fort Worth, Texas 
        76107, telephone (877) 904 0134, e-mail info@gradegetter.com (<b>hereinafter referred to
         as the “Tutor”</b>)and a client of GradeGetter or its affiliate companies, 
         such as GradeGetter (hereafter referred to as “Student”).Services will be provided to 
         Student through online lessons (<b>the “Lessons”</b>), conducted by the individual tutors 
         assigned to the Student by GradeGetter `,
  },
  {
    title: "FEES PAYABLE TO THE TUTOR",
    content: `The Tutor's current rate plans subject to the limitations and conditions expressed in the plan.
        No additional fees shall be charged for preparation time of the Lesson Tutors. 
         The fees charged by Tutor may be adjusted from time to time and shall become effective 
         after having given the Student thirty (30) days written notice.

        <br /><br />Student acknowledges and agrees to pay all costs associated with 
        transacting tutoring business through Tutor.  These costs include, but are not limited to,
        1) the hourly rate listed, 2) travel fees, if applicable, and 3) cancellation fees as 
        described below.
`,
  },
  {
    title: "PAYMENT",
    content: `Tutor will accept payment by check or credit card. 
        A fee of $35.00 will be charged for any dishonored check.  
        Payment for all lessons included in the plan chosen shall be made in advance.
        <b>. For any additional lessons requested by Student not included in the plan,
        </b> Tutor shall invoice Student separately:  
        Student shall pay such invoices within 14 days of the invoice date.
        Student agrees that Tutor shall charge interest at the rate of 18% per 
        annum on any late payments.
`,
  },
  {
    title: "CANCELLATION OF LESSONS BY STUDENT",
    content: `The Student may cancel a lesson by giving at least 24 hours prior notice to 
        the Tutor; if such notice is given, no lesson fees will be incurred. Lessons not 
        attended by the student without giving 24 hours prior notice to the Tutor shall be 
        charged at 50% of the hourly rate. If Student does not arrive at the tutoring location 
        or to the online lesson within 15 minutes of Tutor’s arrival, Student will be billed 
        100% of the lesson.
`,
  },
  {
    title: "CANCELLATION OF LESSONS BY TUTOR",
    content: `The Tutor may cancel lessons by giving 24 hours prior notice to the Student. 
        Where a lesson was pre-paid, the Tutor shall reschedule the appointment at a time
         agreeable to both parties, failing which the Student shall be refunded the fee for
          the missed lesson, if prepaid.
`,
  },
  {
    title: "LATE ARRIVAL",
    content: `Fees are calculated according to the times stipulated in the schedule and 
        no adjustment shall be made for time lost because of late arrival by the Student.
         Any lost time because of the late arrival of the Tutor shall be compensated for by
          extending a lesson by mutual agreement and for the amount of time that was lost.
`,
  },
  {
    title: "OBLIGATIONS OF THE TUTOR",
    content: `The Tutor and Lesson Tutors undertake to do all preparation prior to 
        lessons and to structure lessons to tutor Student effectively.
         <br />The Tutor and Lesson Tutors shall keep all information of the Student 
         confidential and shall contact other parties involved in the education of the 
         Student only if given written permission by the Student to do so.
         <br />The Tutor and Lesson Tutors shall not assign any obligations under this 
         tutoring contract to a third party not subject to this agreement without the written
          permission of the Student.
<br />The Tutor and Lesson Tutors shall at no time be required or obliged to execute homework 
or assignments on behalf of the Student.
`,
  },
  {
    title: "OBLIGATIONS OF THE STUDENT",
    content: `The Student undertakes to assist the Tutor and Lesson Tutors in identifying 
        problem areas in which the Student needs specific tutoring.
<br />The Student agrees that assignments, exercises or homework form an integral part of
 tutoring and undertakes to complete such work in a timely manner.
`,
  },
  {
    title: "INTELLECTUAL PROPERTY",
    content: `The Student agrees that any intellectual property or other learning aids 
        provided by Tutor in connection with the Services may only be used by the Tutor and
         Student for the Student’s educational purposes, and that Student shall obtain rights 
         to the intellectual property and learning aids, which shall remain the express property 
         of Tutor.
`,
  },
  {
    title: "PRIVACY",
    content: `Student agrees not to discuss salary information or lesson fees 
        with Lesson Tutors. By purchasing tutoring services through the Tutor, Student 
        agrees not to directly or indirectly compete with Tutor and its successors, both
         during the period of lesson tutor use and for a period of one year following 
         termination of this tutoring agreement, by any of the following methods: 1) offering 
         Lesson Tutors any tutoring or related work outside of this agreement, 2) offering to 
         pay Lesson Tutors less in order for them to work outside Tutor’s business, 3) referring 
         Lesson Tutors to other potential clients who intent to hire Lesson Tutors outside the
          Tutor business, or 4) any other direct propositioning of Lesson Tutors to do any work 
          outside of the Tutor business.
`,
  },
  {
    title: "NO WARRANTIES",
    content: `The Tutor makes no promises or warranties with regards to a Student’s 
        performance as a result of any tutoring provided.
   `,
  },
  {
    title: "TERMINATION",
    content: `Student may terminate this contract at any time by giving Tutor seven (7) 
        days prior written notice. In the event of such a termination, Student will forfeit 
        any specials applied at the time of signing this contract, and lessons already provided
         will be assessed at a prorated rate based on the number of hours used, which will be 
         subtracted from any prepayment provided to Tutor. Tutor shall refund the remaining 
         balance, if any, to Student. A termination does not terminate any amounts owed by 
         Student to Tutor.
`,
  },
  {
    title: "WAIVER OF TERMS",
    content: `No relaxation, indulgence, waiver or release by any party of any of the 
        rights in terms of this agreement on one occasion shall prevent the subsequent 
        enforcement of such rights and shall not be deemed to be a waiver of any subsequent
         breach of any of the terms.
`,
  },
  {
    title: "WHOLE AGREEMENT",
    content: `This agreement constitutes the entire understanding between the parties with 
        regard to the subject matter hereof and the parties waive the right to rely on any 
        alleged expressed or implied provision not contained herein. Any alteration to this
         agreement must be in writing and signed by both parties.
`,
  },
  {
    title: "LIABILITY",
    content: `The Student understands that tutorial lessons will be received from 
        Lesson Tutors who are independently contracted to, and are not employees of, 
        Tutor. As such, Student waives and forever releases Tutor from any claims or
         damages which may result from actions or events outside the scope of this agreement,
          including but not limited to injuries, emotional damages, physical harm, and all 
          other consequential and special damages.
`,
  },
  {
    title: "CHOICE OF LAW",
    content: `Student agrees that the laws of Texas will govern the validity, 
        interpretation, and effect of this Agreement, as well as any other disputes 
        arising out of or relating to the contract between Student and Tutor.
`,
  },
  {
    title: "JURISDICTION AND VENUE",
    content: `Student and Tutor agree to have all disputes arising out of this Agreement 
        resolved in the courts of Tarrant County, Texas. Tutor and Student hereby consent to 
        jurisdiction in said court(s). The Tutor and Student hereby waive all questions of 
        personal jurisdiction and venue of such courts, including, without limitation, the 
        claim or defense that such court(s) constitute an inconvenient forum.
`,
  },
  {
    title: "MINORS",
    content: `Where the Student is a legal minor, the Parent/Guardian shall enter into
         this tutoring contract on behalf of the Student and shall accept and agree to all
          the terms and conditions contained herein on behalf of the Student.
`,
  },
  {
    title: "TUTORING PACKAGE",
    content: `Student has one year from the date of purchase to use all the tutoring 
        hours available in their tutoring package.
`,
  },
  {
    title: "REFUND",
    content: `You owe us absolutely nothing if you are not satisfied with your first 
        tutoring session. We will assign another tutor to work with you at no extra cost.
         If you’re still not pleased with your tutor, we will refund you the full amount 
         of your initial payment within one month.You get a 100% refund for your unused 
         hours within 30 days after the discounted amount is subtracted from your initial payment.
`,
  },
];
