import React, { useState } from 'react';
import './style.scss';
import { QuestionSummary, SearchInput } from 'src/Components';
import QuestionItem from './QuestionItem';

export default function QuestionList({ questions }) {
  const [searchText, setSearchText] = useState("");
  const [questionCount, setQuestionCount] = useState(10);
  const [filterQuestions, setFilterQuestions] = useState(questions)

  const onChangeSearch = value => {
    setSearchText(value);

    const _filterQuestions = questions.filter(item => (item.name.toLowerCase()).includes(value.toLowerCase()));
    setFilterQuestions(_filterQuestions);
  }

  return (
    <div className="question-list-component">
      <div className="container home-start-container">
        <div className="row mt-4">
          <div className="col-lg-8 left-content">
            <h2>Questions</h2>
            <div className='sub-description'>Select Questions you want to discover.</div>
            <SearchInput
              value={searchText}
              onChange={(value) => onChangeSearch(value)}
            />
            <div className='subject-list'>
              {filterQuestions
                .slice(0, questionCount)
                .map(item => (
                  <QuestionItem
                    key={item.id}
                    question={item}
                  />
                ))}
            </div>
            { questionCount < filterQuestions.length && <div className='btn-3 load-more-btn' onClick={() => setQuestionCount(questionCount + 10)}>LOAD MORE</div>}
          </div>
          <div className="col-lg-4 right-content">
            { filterQuestions.length > 0 && <QuestionSummary banner={filterQuestions[0].subject.banner}/>}
          </div>
        </div>
      </div>
    </div>
  );
}