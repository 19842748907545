import React, { useEffect, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import SubjectItem from './SubjectItem';
import SubjectLogo from 'src/Assets/CommonQuestions/logo-subject.svg';


export default function SubjectList({ subjects }) {
  let history = useHistory();
  const [subjectCount, setSubjectCount] = useState(15)

  const onSubject = (item) => {
    // const name = item.name.replace(/\s+/g, '-')
    history.push(`/worksheet-files`);
  }

  const SubjectListInfo = [
    { id: "1", name: "Chemistry", logo: SubjectLogo, },
    { id: "2", name: "Biology", logo: SubjectLogo, },
    { id: "3", name: "Physics", logo: SubjectLogo, },
    { id: "4", name: "Reading", logo: SubjectLogo, },
    { id: "5", name: "Accounting", logo: SubjectLogo, },
    { id: "6", name: "Economics", logo: SubjectLogo, },
    { id: "7", name: "Anatomy and Physiology", logo: SubjectLogo, },
    { id: "8", name: "History", logo: SubjectLogo, },
    { id: "9", name: "Economics", logo: SubjectLogo, },
    { id: "10", name: "History", logo: SubjectLogo, },
    { id: "11", name: "Reading", logo: SubjectLogo, },
    { id: "12", name: "MATH", logo: SubjectLogo, },
    { id: "13", name: "Reading", logo: SubjectLogo, },
    { id: "14", name: "Accounting", logo: SubjectLogo, },
    { id: "15", name: "Reading", logo: SubjectLogo, },
      { id: "16", name: "MATH", logo: SubjectLogo, }
  ]

  return (
    <div className="subject-lists-component">
      <div className="container home-start-container">
        <h2 className='text-center'>Categories</h2>
        <div className='sub-description text-center'>Select category you want to discover</div>
        <div className='list-wrapper mt-4'>
          <div className='subject-list'>
            {SubjectListInfo
              .slice(0, subjectCount)
              .map(item => (
                <SubjectItem
                  key={item.id}
                  subject={item}
                  onClick={() => onSubject(item) }
                />
              ))}
          </div>
          { subjectCount < SubjectListInfo.length && <div className='btn-3 load-more-btn' onClick={() => setSubjectCount(subjectCount + 10)}>LOAD MORE</div> }
        </div>
      </div>
    </div>
  );
}