import React from 'react'
import './style.scss'
import { ReactSVG } from "react-svg";
import DownloadIcon from 'src/Assets/PracticeQuestions/download.svg'
import PDFIcon from 'src/Assets/PracticeQuestions/pdf-document.svg'

export default function ResourceItem({resource, onClick }) {
 
    return (
        <div className='bg-item-wrapper'>
            <div className='dotted-bg'></div>
            <div className='resource-item-component shadow-object'>
                <div className='resource-container'>
                    <div className='file-info'>
                         <ReactSVG src={PDFIcon} />
                        <div className='info'>
                            <div className='resource-title'>{resource.name}</div>
                            <div className='resource-size'>{ resource.file_size }</div>
                        </div>
                    </div>
                    <ReactSVG src={DownloadIcon} className='pointer' onClick={onClick}/>
                </div>
            </div>
        </div>
    )
}