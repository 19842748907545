import { get, getWikipediaApi } from "./Api";

export const getTutors = () => {
  return get("/users/tutors");
};
export const getPlans = () => {
  return get("/plans");
};

export const getJobs = () => {
  return get("/jobs");
};
export const getConnections = () => {
  return get("/connections");
};

export const getLevels = () => {
  return get("/levels");
};

export const getSubjects = (level_id = 1) => {
  return get(`/subjects?level_id=${level_id}`);
};

export const getSchools = () => {
  return get("/schools");
};

export const getSortSchool = (alphabet) => {
  return get(`/schools?sortby=${alphabet}`);
};

export const getCities = (city = '') => {
  return get(`/cities?name=${city}`);
};
export const getProfile = () => {
  return get("/users/profile");
};

export const getBlogListing = () => {
  return get("/blogs/listing");
};

export const getBraintreekey = () => {
  return get(`/payments/braintreekey`);
};

export const getChannels = () => {
  return get(`/channels`);
};

export const getChannelDetail = (channel_id) => {
  return get(`/channels/${channel_id}`);
};

export const getVideoDetail = (id) => {
  return get(`/videos/${id}`);
};

export const getHomeData = (city, subject) => {
  return get(`/landingpage?region=${city}&subject=${subject}`);
};

export const getGradeTvData = () => {
  return get(`/gradetv`);
};

export const getUniversityLandingData = (university) => {
  return get(`/landingpage?school=${university}`);
};

export const getWikipedia = (city_name) => {
  return getWikipediaApi(city_name);
};

export const getMiMSTopics = () => {
  return get('/mimstopics');
}



