import React from "react";
import "./LocalPartners.scss";
import Loadable from "@loadable/component";
import Media from "react-media";
import { Partner } from "../../";
import { CarouselLeftButton, CarouselRightButton } from "../../../Constant";
import _ from 'lodash'

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default class LocalPartners extends React.Component {

  render() {
    const { partners } = this.props;
    return (
      <>
        {!_.isEmpty(partners) && <div className="local-partners-component">
          <div className="container local-partners-container">
            <h2 className="title">Local Partners</h2>
            <div className="description">We Offer 5% discount to anyone who is referred by our local partners listed below </div>
            <div className="col-lg-12 main-row">
              <Media
                queries={{
                  lg: "(min-width: 1200px)",
                  md: "(min-width: 992px) and (max-width: 1199px)",
                }}
              >
                {(matches) => (
                  <OwlCarousel
                    className="owl-theme"
                    rewind
                    nav
                    stagePadding={matches.lg ? 20 : matches.md ? 20 : 15}
                    margin={matches.lg ? 28 : matches.md ? 33 : 20}
                    items={matches.lg ? 3 : matches.md ? 2 : 1}
                    dots={false}
                    navText={[CarouselLeftButton, CarouselRightButton]}
                  >
                    {partners.map((item, index) => (
                      <Partner key={index} item={item} />
                    ))}
                  </OwlCarousel>
                )}
              </Media>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
