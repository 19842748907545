import React from 'react';
import './style.scss';
import {
  QuestionHeader,
  QuestionList,
} from 'src/Components';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helper from "../../../Util/Helper"
import {
  getCQQuestionsAction
} from "../../../Redux/Actions"
import { Helmet } from 'react-helmet';
import { QueueSharp } from '@material-ui/icons';

class CQQuestionsListPage extends React.Component {

  componentDidMount() {
    Helper.showSpinner()
    this.props.getCQQuestionsAction(this.props.match.params.id).then(() => {
      Helper.hideSpinner()
    })
  }

  getSubject() {
    const subject = this.props.match.params.subject
    return subject.replace(/-/g, ' ')
  }

  getStructuralData(questions) {
    const structuralData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": questions.map(question => {
        return {
        "@type": "Question",
        "name": question.name,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>"+ question.short_answer +"</p>"
          }
        }
      })
    }
    return structuralData;
  }

  render() {
    const { questions } = this.props
    const subject = this.getSubject()

    const title = `Commonly Asked ${subject} Questions`
    const description = `This is a detailed list of commonly asked ${subject} questions`

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/logos/common-questions.png" />

           {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content="https://gradegetter.com/logos/common-questions.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content="https://gradegetter.com/logos/common-questions.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content="https://gradegetter.com/logos/common-questions.png" />
          
          { questions && <script type="application/ld+json">{ JSON.stringify(this.getStructuralData(questions))}</script>}
        </Helmet>
        <div className="cq-question-list-page">
          <QuestionHeader subject={subject}/>
        { questions &&  <QuestionList questions={questions}/>}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  questions: state.Question.cq_questions
});

export default connect(mapStateToProps, {
  getCQQuestionsAction
})(withRouter(CQQuestionsListPage));