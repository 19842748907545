import React from "react";
import "./UniversityListPage.scss";
import { connect } from "react-redux";
import {
  getSortSchoolAction,
} from "../../Redux/Actions";
import { withRouter } from "react-router-dom";
import Helper from '../../Util/Helper';
import _ from 'lodash'

const Alphabet = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P",  "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
]

class UniversityListPage extends React.Component {
  state = {
    current_index: 0,
  };

  componentDidMount() {
    Helper.showSpinner();

    this.props.getSortSchoolAction("A").then(()=>{
      Helper.hideSpinner();
    });
  }

  selectIndex = (item, index) => {
    Helper.showSpinner();
    this.setState({current_index: index});
    this.props.getSortSchoolAction(item).then(()=>{
      Helper.hideSpinner();
    });
  }

  selectUniversity = (university) => {
    var route_university = university.replace(/\s+/g, '-');
    this.props.history.push(`/USA/University/${route_university}`);
  };

  render() {
    const { schools,  } = this.props;
    const { current_index } = this.state;

    return (
      <main>
        <div className="university-page">
          <div className="container"> 
            <h1>Universities</h1>
            <div className="alphabet-content">
              {Alphabet.map((item, index)=>(
                <div className={`alpha-item ${current_index === index? "active" : ""}`} key={index} onClick={()=>this.selectIndex(item, index)}>
                  {item}
                </div>
              ))}
            </div>
            {!_.isEmpty(schools) && <div className="university-list">
              {schools.map((item, index)=> (
                <div className="university-item v-c mr-5" key={index} onClick={()=>this.selectUniversity(item.name)}>
                  <div className="v-c h-c mr-3">•</div>
                  <div>{item.name}</div>                  
                </div>
              ))}
            </div>}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  schools: state.Core.schools,
});

export default connect(mapStateToProps, {
  getSortSchoolAction
})(withRouter(UniversityListPage));
