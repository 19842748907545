import React from 'react'
import './style.scss'
import {
    PQSubjectHeader,
    PQSubjectList
} from 'src/Components'

export default function PQSubjectsPage() {
    return (
        <div className='pq-subjects-page'>
            <PQSubjectHeader />
            <PQSubjectList />
        </div>
    )
}