import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";

export default function SubjectItem({
  subject, onClick
}) {

  return (
    <div className='fc-bg-wrapper'>
      <div
        className="fc-subject-item-component center shadow-object"
        onClick={onClick}>
        <img src={process.env.REACT_APP_IMAGE_DOMAIN + subject.picture}></img>
        <div className='subject-name'>{subject.name}</div>
      </div>
      <div className='dotted-bg'></div>
    </div>
  );
}