import React, { useEffect } from 'react';
import './style.scss';
import QuestionHeaderImg from 'src/Assets/CommonQuestions/question-header-img.png';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function QuestionHeader({ subject, answer }) {
  const params = useParams();
  const history = useHistory();

  const onNavigate = (path) => {
    switch (path) {
      case 'subjects':
        history.push('/common-questions/subjects'); break;
      case 'question-list':
        if (params.question === undefined && isQuestionList()) {
          const { subject, id } = params
          history.push(`/common-questions/questions-list/${subject}/${id}`)
        } else if (answer !== null) {
          history.push(`/common-questions/questions-list/${params.subject}/${answer.subject.id}`)
        } break;
      default:
        return;
    }
  }

  const isQuestionList = () => {
    return history.location.pathname.includes('questions-list')
  }

  return (
    <div className="question-header-component">
      <div className="container question-header-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <div className='pointer'>
              <span onClick={() => onNavigate('subjects')}>Common Questions</span>
              <span className='opacity'>/</span>
              <span className={ isQuestionList() ? 'opacity' : ''} onClick={() => onNavigate('question-list')}>{subject} Commonly Asked Questions</span>
              {answer && (
                <>
                  <span className='opacity'>/</span>
                  <span className='opacity'>{answer.id}</span>
                </>
              )}
            </div>
            <div>
              {answer === undefined || answer === null ? (<h1>{subject} Commonly Asked Questions</h1>) : (answer && <h1>{answer.name}</h1>)}
            </div>
          </div>
          <div className="col-lg-6 show-web right-content">
            <div>
              <img src={QuestionHeaderImg} alt="header-img" className="header-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}