import React from "react";
import "./HomePage.scss";
import { connect } from "react-redux";
import {
  StartArea,
  Instructors,
  ClientSaying,
  Tutors,
  Pricing,
  HowItWorks,
  WhyUs,
  OurCore,
  TutoringPhilosophy,
} from "../../Components";
import {
  getTutorAction,
  getConnectionAction,
  getPlanAction,
  initAppAction,
  signOutAction
} from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";

class HomePage extends React.Component {
  state = {};

  componentDidMount() {
    this.props.initAppAction();
    this.props.getTutorAction();
    this.props.getConnectionAction();
    this.props.getPlanAction();
    // this.workContainer.current.scrollTo(1, 5);
  }

  componentDidUpdate() {
    const params = new URLSearchParams(this.props.location.search);
    const signout = params.get('signout');

    if (signout) {
      this.props.signOutAction();
      window.location.href = "/"
    } else {
      const { user, token } = this.props
      if (user && token) {
        if (user.plan_frequency || user.can_book_trial) {
          // window.location.href = GetDashboardUrl(user, token)
        };
      };
    }
  }

  render() {
    const { tutors, connections, plans } = this.props;
    return (
      <main>
        <Helmet>
          <title>#1 Online Christian Tutoring Company | GradeGetter</title>
          <meta name="title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta name="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.origin} />
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta itemprop="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.origin} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta property="og:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta name="twitter:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png" />
        </Helmet>
        <div className="home-page" ref={this.workContainer}>
          <StartArea />
          <HowItWorks />
          <OurCore />
          <WhyUs />
          {/*plans &&  <Pricing plans={plans} />*/}
          <Instructors />
          {tutors && <Tutors tutors={tutors} />}
          <TutoringPhilosophy />
          {connections && <ClientSaying reviews={connections} />}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  tutors: state.Core.tutors,
  connections: state.Core.connections,
  plans: state.Core.plans,
  token: state.Auth.token,
  user: state.Auth.user,
});

export default connect(mapStateToProps, {
  initAppAction,
  getTutorAction,
  getConnectionAction,
  getPlanAction,
  signOutAction
})(withRouter(HomePage));
