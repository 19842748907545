import React from 'react'
import {
    PQuestionHeader,
    PQTopicList
} from 'src/Components'


export default function PQQuestionsListPage() {

    return (
        <div className='pq-questions-list'>
            <PQuestionHeader />
            <PQTopicList />
        </div>
    )
}