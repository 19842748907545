import React from "react";
import "./CitySubjectLadingPage.scss";
import { connect } from "react-redux";
import LazyLoad from 'react-lazyload';
import {
  StartAreaSearch,
  About,
  TutorsInCity,
  ClientSaying,
  Pricing,
  Video,
  FAQ,
  Topics,
  Offers,
  Brochure,
  Blog,
  Meet,
  Contacts,
  Schools,
  Events,
  LocalPartners,
  Hours,
  HowItWorks,
  Location,
  ServicesAreas,
  Services
} from "../../Components";
import {  
  getPlanAction,
  getHomeDataAction,
} from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";
import Helper from '../../Util/Helper';
import _ from 'lodash'
import { InitialLandingData } from "../../Constant";

class CitySubjectLadingPage extends React.Component {
  state = {
    route_region: '',
    route_subject: '',
  };

  componentDidMount() {
    this.props.getPlanAction();    
    if(!_.isEmpty(this.props.match.params)){
      const { region, subject } = this.props.match.params;
      const route_region = region.replace(/-/g, ' ');
      const route_subject = subject.replace(/-/g, ' ');

      this.setState({route_region, route_subject});
      this.getLandingData(route_region, route_subject);

    } else {
      this.getLandingData("", "");
    };
  };

  getLandingData(route_region, route_subject){
    this.props.getHomeDataAction(route_region, route_subject).then(()=> {
      Helper.hideSpinner();
    });
  };

  componentDidUpdate(prevProps, prevState) {   

    const { region, subject } = this.props.match.params;
    if(prevProps && region !== prevProps.match.params.region || subject !== prevProps.match.params.subject){
      Helper.showSpinner();
      const route_region = region.replace(/-/g, ' ');
      const route_subject = subject.replace(/-/g, ' ');

      this.setState({route_region, route_subject});
      this.getLandingData(route_region, route_subject);
    };
  };

  findTutor = (region, subject) => {
    if(region){
      var route_region = region.replace(/\s+/g, '-');
    };
    if(subject){
      var route_subject = subject.replace(/\s+/g, '-');
    };

    if(region && subject ){
      this.props.history.push(`/tutors/${route_region}/${route_subject}`);
    } else if (region) {
      this.props.history.push(`/tutors-region/${route_region}`);
    } else if (subject) {
      this.props.history.push(`/tutors-subject/${route_subject}`);
    };
  };

  render() {
    const { plans, LandingData } = this.props;
    const { region, subject } = this.props.match.params;
    const { route_subject, route_region,  } = this.state;
    const metaTitle = `#1 ${route_subject} Tutors In ${route_region} & Online Math Tutors | 1 FREE Lesson`
    const metaDescription = `Your first 30 minutes is FREE for your 1-on-1 online ${route_subject} tutoring with our ${route_subject} tutors near you in  ${route_region}. We’re a Christian company and you pay what you can afford starting at $27.5/hr`
    return (
      <main>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription}/>
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={metaTitle} />
          <meta itemprop="description" content={metaDescription}/>
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription}/>
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle}/>
          <meta name="twitter:description" content={metaDescription}/>
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
        </Helmet>
        <div className="home-page" ref={this.workContainer}>
          <StartAreaSearch 
            homeData={LandingData} 
            findTutor={(region, subject) =>this.findTutor(region, subject)}
            region={region}
            subject={subject}
          />
          <About homeData={LandingData}/>
          <LazyLoad height={200} offset={100} once>
            <TutorsInCity tutor_list={LandingData.tutor_list}/>
            <ClientSaying reviews={LandingData.reviews} />
            <HowItWorks />
            {plans && <Pricing plans={plans} city={LandingData.city.name} />}
            <Video videos={LandingData.videos}/>
            <FAQ />
            <Topics topics={LandingData.faq}/>
            <Offers offers={LandingData.offers[0]}/>
            <Brochure ebook={LandingData.ebook}/>
            <Blog blogs={LandingData.blogs}/>
            <Meet director={LandingData.director}/>
            <Contacts contact={LandingData.contact}/>
            <Hours hours={LandingData.office_hour}/>
            <Schools schools={LandingData.schools}/>
            <Events events={LandingData.events[0]}/>
            <LocalPartners partners={LandingData.partners}/>
            <Location city={LandingData.city}/>
            <ServicesAreas service_areas={LandingData.service_areas}/>
            <Services services={LandingData.services} city={LandingData.city.name}/>
          </LazyLoad>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({    
  plans: state.Core.plans,  
  LandingData: state.Core.LandingData
});

export default connect(mapStateToProps, {
  getPlanAction,
  getHomeDataAction,
})(withRouter(CitySubjectLadingPage));
