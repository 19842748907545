import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
    WorksheetSubjectsPage,
    WorksheetFilesPage
} from '../Pages';

function WorksheetRoutes() {

    return (
        <div>
            <Switch>
                <Route exact path="/worksheets" component={WorksheetSubjectsPage} />
                <Route exact path="/worksheet-files" component={WorksheetFilesPage} />
            </Switch>
        </div>
    )
}

export default WorksheetRoutes;