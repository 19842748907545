import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    WSSubjectHeader,
    WSSubjectList
} from 'src/Components';

class WorkSheetSubjectsPage extends React.Component {

    render() {
        return (
            <div>
                <WSSubjectHeader />
                <WSSubjectList />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(withRouter(WorkSheetSubjectsPage))