import React from "react";
import "./Events.scss";
import events_img from "../../../Assets/Home/events-img.png";
import calendar from "../../../Assets/Icon/calendar.png";
import location_icon from "../../../Assets/Icon/location.png";
import _ from 'lodash'

export default class Events extends React.Component {

  render() {
    const { events } = this.props;
    return (
      <>
        {!_.isEmpty(events) && <div className="events-component">
          <div className="container events-container">
            <h2 className="title">Events</h2>
            <div className="description">Events happening right now</div>
            <div className="row main-row">
              <div className="col-lg-5 left-area">
                <div className="image-container">
                  <img src={events_img} alt="tutor_content" />
                  {/* <img src={events.image} alt="tutor_content" /> */}
                </div>
              </div>
              <div className="col-lg-7 right-area">
                <div className="event-name">{events.title}</div>
                <div className="date-location-category">
                  <div className="icon-container">
                    <img src={calendar} alt="calendar" />
                  </div>
                  <div className="category-title">Date: </div>
                  <span className="date-location-txt"> {events.date}</span>
                  </div>
                <div className="date-location-category">
                  <div className="icon-container">
                    <img src={location_icon} alt="lcoation" />
                  </div>
                  <div className="category-title">Location: </div>
                  <span className="date-location-txt"> {events.location}</span>
                </div>
                <div className="events-details">{events.text}</div>
              </div>
            </div>
          </div>
        </div>}
      </>
    );
  }
}
