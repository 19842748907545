import {
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAIL,
  GET_PLANS,
  GET_PLANS_FAIL,
  GET_PLANS_SUCCESS,
  GET_CONNECTIONS,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
  GET_TUTORS,
  GET_TUTORS_SUCCESS,
  GET_TUTORS_FAIL,
  GET_SUBJECTS,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  GET_SCHOOLS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_FAIL,
  GET_SORT_SCHOOLS,
  GET_SORT_SCHOOLS_SUCCESS,
  GET_SORT_SCHOOLS_FAIL,  
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_LEVELS,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAIL,
  GET_BRAINTREEKEY,
  GET_BRAINTREEKEY_SUCCESS,
  GET_BRAINTREEKEY_FAIL,
  INIT_APP,
  GET_BLOG_LIST_SUCCESS,
  GET_CHANNELS,
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_FAIL,
  GET_CHANNEL_DETAIL,
  GET_CHANNEL_DETAIL_SUCCESS,
  GET_CHANNEL_DETAIL_FAIL,
  GET_VIDEO_DETAIL,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_FAIL,
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAIL,
  GET_TV_DATA,
  GET_TV_DATA_SUCCESS,
  GET_TV_DATA_FAIL,
  GET_UNIVERSITY_LADING_DATA,
  GET_UNIVERSITY_LADING_DATA_SUCCESS,
  GET_UNIVERSITY_LADING_DATA_FAIL,  
  GET_WIKIPEDIA_DETAIL,
  GET_WIKIPEDIA_DETAIL_SUCCESS,
  GET_WIKIPEDIA_DETAIL_FAIL,  
  GET_MIMS_TOPICS,
  GET_MIMS_TOPICS_FAIL,
  GET_MIMS_TOPICS_SUCCESS
} from "../Type";
import { InitialLandingData } from "../../Constant";

const INITIAL = {
  tutors: null,
  connections: null,
  subjects: null,
  levels: null,
  plans: null,
  schools: null,
  cities: null,
  braintreeKey: null,
  channels: null,
  channelData: null,
  videoDetailData: null,
  LandingData: InitialLandingData,
  tvDatas: null,
  universityLadingData: InitialLandingData,
  wikipediaDetail: null,
  mims_topics: null
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    // JOB
    case GET_JOBS:
      return { ...state, jobs: null };
    case GET_JOBS_SUCCESS:
      return { ...state, jobs: action.payload };
    case GET_JOBS_FAIL:
      return { ...state, jobs: null };

    // PLANS
    case GET_PLANS:
      return { ...state, plans: null };
    case GET_PLANS_SUCCESS:
      return { ...state, plans: action.payload };
    case GET_PLANS_FAIL:
      return { ...state, plans: null };

    // CONNECTIONS
    case GET_CONNECTIONS:
      return { ...state, connections: null };
    case GET_CONNECTIONS_SUCCESS:
      return { ...state, connections: action.payload };
    case GET_CONNECTIONS_FAIL:
      return { ...state, connections: null };

    // TUTORS
    case GET_TUTORS:
      return { ...state, tutors: null };
    case GET_TUTORS_SUCCESS:
      return { ...state, tutors: action.payload };
    case GET_TUTORS_FAIL:
      return { ...state, tutors: null };

    // LEVELS
    case GET_LEVELS:
      return { ...state, levels: null };
    case GET_LEVELS_SUCCESS:
      return { ...state, levels: action.payload };
    case GET_LEVELS_FAIL:
      return { ...state, levels: null };

    // SUBJECTS
    case GET_SUBJECTS:
      return { ...state, subjects: null };
    case GET_SUBJECTS_SUCCESS:
      return { ...state, subjects: action.payload };
    case GET_SUBJECTS_FAIL:
      return { ...state, subjects: null };

    // SCHOOLS
    case GET_SCHOOLS:
      return { ...state, schools: null };
    case GET_SCHOOLS_SUCCESS:
      return { ...state, schools: action.payload };
    case GET_SCHOOLS_FAIL:
      return { ...state, schools: null };

    // SORT SCHOOLS
    case GET_SORT_SCHOOLS:
      return { ...state, schools: null };
    case GET_SORT_SCHOOLS_SUCCESS:
      return { ...state, schools: action.payload };
    case GET_SORT_SCHOOLS_FAIL:
      return { ...state, schools: null };

    // CITIES
    case GET_CITIES:
      return { ...state, cities: null };
    case GET_CITIES_SUCCESS:
      return { ...state, cities: action.payload };
    case GET_CITIES_FAIL:
      return { ...state, cities: null };

    // BLOS LIST
    case GET_BLOG_LIST_SUCCESS:
      return { ...state, bloglist: action.payload };
    default:
      return state;

    // BRAINTREEKEY
    case GET_BRAINTREEKEY:
      return { ...state, braintreeKey: null };
    case GET_BRAINTREEKEY_SUCCESS:
      const { token } = action.payload;
      return { ...state, braintreeKey: token };
    case GET_BRAINTREEKEY_FAIL:
      return { ...state, braintreeKey: null };

    // CHANNELS
    case GET_CHANNELS:
      return { ...state, channels: null };
    case GET_CHANNELS_SUCCESS:
      return { ...state, channels: action.payload };
    case GET_CHANNELS_FAIL:
      return { ...state, channels: null };

    // CHANNEL DETAIL
    case GET_CHANNEL_DETAIL:
      return { ...state, channelData: null };
    case GET_CHANNEL_DETAIL_SUCCESS:
      return { ...state, channelData: action.payload };
    case GET_CHANNEL_DETAIL_FAIL:
      return { ...state, channelData: null };

    // VIDEO DETAIL
    case GET_VIDEO_DETAIL:
      return { ...state, videoDetailData: null };
    case GET_VIDEO_DETAIL_SUCCESS:
      return { ...state, videoDetailData: action.payload };
    case GET_VIDEO_DETAIL_FAIL:
      return { ...state, videoDetailData: null };

    // HOME DATA
    case GET_HOME_DATA:
      return { ...state, LandingData: InitialLandingData };
    case GET_HOME_DATA_SUCCESS:
      return { ...state, LandingData: action.payload };
    case GET_HOME_DATA_FAIL:
      return { ...state, LandingData: null };

    // TV DATA
    case GET_TV_DATA:
      return { ...state, tvDatas: null };
    case GET_TV_DATA_SUCCESS:
      return { ...state, tvDatas: action.payload };
    case GET_TV_DATA_FAIL:
      return { ...state, tvDatas: null };

    //UNIVERSITY LANDING DATA
    case GET_UNIVERSITY_LADING_DATA:
      return { ...state, universityLadingData: InitialLandingData };
    case GET_UNIVERSITY_LADING_DATA_SUCCESS:
      return { ...state, universityLadingData: action.payload };
    case GET_UNIVERSITY_LADING_DATA_FAIL:
      return { ...state, universityLadingData: null };

    //WIKIPEDIA CITY DETAIL DATA
    case GET_WIKIPEDIA_DETAIL:
      return { ...state, wikipediaDetail: null };
    case GET_WIKIPEDIA_DETAIL_SUCCESS:
      return { ...state, wikipediaDetail: action.payload };
    case GET_WIKIPEDIA_DETAIL_FAIL:
      return { ...state, wikipediaDetail: null };
    
    /* MAKE MAKE SENSE TOPICS */
    case GET_MIMS_TOPICS:
      return { ...state, mims_topics: null };
    case GET_MIMS_TOPICS_SUCCESS:
      return { ...state, mims_topics: action.payload };
    case GET_MIMS_TOPICS_FAIL:
      return { ...state, mims_topics: null };
  }
};
