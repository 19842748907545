import React from "react";
import "./StartAreaV2.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import pen_web from "../../../Assets/HomeV2/pen-web.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toggleHomeV2Modal } from '../../../Redux/Actions';

class StartAreaV2 extends React.Component {  

  yes(){
    this.props.history.push(`/sign-up`);
  }

  no = () =>{
    this.props.toggleHomeV2Modal(true);
  }

  render() {
    const { subject } = this.props;
    return (
      <div className="homestart-v2-component">
        <div className="container main-content">
          <div className="row">
            <div className="col-lg-4 left-area">
              <div>
                <img src={pen_web} alt="pen" className="pen" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="description">
                <div className="no-credit v-c h-c mb-3">No credit card is required!</div>
                <h1>Would you like me to offer you a <strong className="strong-bg">FREE</strong> trial lesson for 1:1 online <strong className="strong-bg-2">{subject}</strong> tutoring with one of our high-performing tutors from an Ivy League or other top universities?</h1>
                <div className="v-c mt-3 request-btn-container">
                  <div className="request-btn shadow-object yes-btn v-c h-c" onClick={()=>this.yes()}>YES</div>
                  <div className="request-btn shadow-object no-btn v-c h-c" onClick={()=>this.no()}>NO</div>
                </div>
                <div className="sub-description shadow-object v-c">Only pay what you can afford, starting at $27.5/hr after your free trial!</div>                   
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, { 
  toggleHomeV2Modal
})(withRouter(StartAreaV2));
