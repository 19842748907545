import React from "react";
import "./Offers.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import offers_img from "../../../Assets/Home/transparency-right2.png";
import { StartButton } from "../../";
import _ from 'lodash'

function innerFunc(item) {
  return { __html: item };
}

class Offers extends React.Component {

  credit = () => {
    this.props.history.push(`/sign-up`);
  };
  
  render() {
    const { offers, title } = this.props;
    return (
      <>
        {!_.isEmpty(offers) && <div className="offers-component">
          <div className="container offers-container">
            <h2 className="title">{title}</h2>
            <div className="description">Promotional offer for new clients</div>
            <div className="row main-row">
              <div className="col-lg-5 left-area">
                <div className="image-container">
                  <img src={offers_img} alt="tutor_content" />
                </div>
              </div>
              <div className="col-lg-7 right-area">
                <div className="offer-title">{offers.name}</div>
                <div className="offer-detail-container">
                  {/* <div className="bonus-credit" >
                    <p>up to  </p>
                    <h1>{offers.credit}</h1>
                    <p>honus credit</p>
                  </div> */}
                  <div className="bonus-credit" dangerouslySetInnerHTML={innerFunc(offers.description)} />
                  <p>offer period {offers.offer_period}</p>
                  <StartButton 
                    className="start-btn" 
                    startText="Claim Credit"
                    isOtherBtn={true}
                    onClick={()=>this.credit()}         
                  />
                  <p>New Gradegetter sign ups only</p>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </>
    );
  }
}

export default connect()(
  withRouter(Offers)
);
