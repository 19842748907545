import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
    PTSubjectsPage,
    PTResourcePage
} from '../Pages'

export default function PTRoutes() {

    return (
        <div>
            <Switch>
                <Route exact path="/practice-tests" component={PTSubjectsPage} />
                <Route exact path="/practice-tests/files/:subject/:id" component={PTResourcePage} />
            </Switch>
        </div>
    )
}