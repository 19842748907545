import React from "react";
import "./ClientFeedback.scss";
import StarRatings from "react-star-ratings";
import { StarSVGViewBox, StarSVGIconPath, Avatars } from "../../../Constant";

export default class ClientFeedback extends React.Component {
  render() {
    const { item, className, index } = this.props;        
    return (
      <div className={`clientfeedback-component ${className || ""}`}>
        <div className="client-item">
          <div className="client-header">
            <img src={Avatars[index % Avatars.length]} alt="avatar" className="avatar" />
            <div className="user-info">
              <div className="basic">
                {" "}
                <span className="name">{item.name}</span> from {item.address}{" "}
              </div>
              <div className="sub-info">
                <div className="subject">Knowledge: </div>
                <StarRatings
                  rating={item.knowledge}
                  starDimension="16px"
                  starSpacing="2.14px"
                  starRatedColor="#23A4EF"
                  starEmptyColor="#D3EDFC"
                  svgIconViewBox={StarSVGViewBox}
                  svgIconPath={StarSVGIconPath}
                />
              </div>
              <div className="sub-info">
                <div className="subject">Presentation: </div>
                <StarRatings
                  rating={item.presentation}
                  starDimension="16px"
                  starSpacing="2.14px"
                  starRatedColor="#23A4EF"
                  starEmptyColor="#D3EDFC"
                  svgIconViewBox={StarSVGViewBox}
                  svgIconPath={StarSVGIconPath}
                />
              </div>
            </div>
          </div>
          <div className="client-body">
            <div className="description">{item.description}</div>
            <div className="comment-date">{item.comment_date}</div>
          </div>
        </div>
        <div className="bg-dot small-dot span-dot" alt="bgdot" />
      </div>
    );
  }
}
