import React, { useEffect } from "react";
import "./App.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { BrowserRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { initAppAction } from "./Redux/Actions";
import BeatLoader from 'react-spinners/BeatLoader'
import { css } from "@emotion/core";
import {
  Header,
  Footer,
  CQFooter,
  Sidebar,
  HeaderV2,
  HeaderV3,
  Header4,
  FeedbackModal,
  HomeV2Modal
} from "./Components";
import {
  CustomRoutes, 
  AdsRoutes,
  CQRoutes,
  PQRoutes,
  PTRoutes,
  FlashcardRoutes,
  WorksheetRoutes
} from 'src/Routes';
import { EventEmitter } from './Util/events';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class App extends React.Component {
  state = {
    isBlur: false,
    loading: false
  };

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur: props.isSidebarOpen,
    };
  }

  render() {
    const { isBlur, loading } = this.state;
    EventEmitter.subscribe('isLoading', (event) => this.setState({ loading: event }));

    if (isBlur) {
      if (document.body) {
        const body = document.body;
        body.classList.add("modal-open");
      }
    } else {
      if (document.body) {
        const body = document.body;
        body.classList.remove("modal-open");
      }
    }

    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="App">
          <Sidebar />
          <div id="main" className={`${isBlur ? "blur" : ""}`}>
            <Header4 />
            <Header />
            <HeaderV2 />
            <HeaderV3 />
            <Helmet>
              <title>#1 Online Christian Tutoring Company | GradeGetter</title>
              <meta name="title" content="#1 Online Christian Tutoring Company | GradeGetter" />
              <meta name="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
              <meta property="type" content="website" />
              <meta property="url" content={window.location.origin} />
              <meta property="image" content="https://gradegetter.com/gradegetter.png" />

              {/* <!-- Google / Search Engine Tags --> */}
              <meta itemprop="name" content="#1 Online Christian Tutoring Company | GradeGetter" />
              <meta itemprop="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
              <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

              {/* <!-- Facebook Meta Tags --> */}
              <meta property="og:url" content={window.location.origin} />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="#1 Online Christian Tutoring Company | GradeGetter" />
              <meta property="og:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
              <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

              {/* <!-- Twitter Meta Tags --> */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="#1 Online Christian Tutoring Company | GradeGetter" />
              <meta name="twitter:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
              <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png" />
            </Helmet>
            <div className="content">
              <CustomRoutes />
              <AdsRoutes />
              <CQRoutes />
              <PQRoutes />
              <PTRoutes />
              <FlashcardRoutes />
              <WorksheetRoutes />
            </div>
            {/* <Footer /> */}
            <CQFooter />
            <FeedbackModal />
            <HomeV2Modal />
          </div>
        </div>
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            css={override}
            size={30}
            color={"#FFF"}
            loading={loading}
          />
        </div>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSidebarOpen: state.Sidebar.isOpen,
  };
};

export default connect(mapStateToProps, { initAppAction })(App);
