
import {
    GET_CQ_SUBJECTS,
    GET_CQ_SUBJECTS_FAIL,
    GET_CQ_SUBJECTS_SUCCESS,
    GET_CQ_QUESTIONS,
    GET_CQ_QUESTIONS_FAILED,
    GET_CQ_QUESTIONS_SUCCESS,
    GET_CQ_FULL_ANSWER,
    GET_CQ_FULL_ANSWER_FAILED,
    GET_CQ_FULL_ANSWER_SUCCESS,
    GET_PT_SUBJECTS,
    GET_PT_SUBJECTS_FAIL,
    GET_PT_SUBJECTS_SUCCESS,
    GET_PT_FILES,
    GET_PT_FILES_FAIL,
    GET_PT_FILES_SUCCESS,
    GET_PT_FILE_DOWNLOAD_LINK, 
    GET_PT_FILE_DOWNLOAD_LINK_FAIL,
    GET_PT_FILE_DOWNLOAD_LINK_SUCCESS,
    GET_FLASHCARDS,
    GET_FLASHCARDS_FAIL,
    GET_FLASHCARDS_SUCCESS,
    GET_FLASHCARD_SUBJECTS,
    GET_FLASHCARD_SUBJECTS_FAIL,
    GET_FLASHCARD_SUBJECTS_SUCCESS,
    GET_FLASHCARD_TOPICS,
    GET_FLASHCARD_TOPICS_FAIL,
    GET_FLASHCARD_TOPICS_SUCCESS
} from "../Type"

const INITIAL = {
    cq_subjects: null,
    cq_questions: null,
    cq_full_answer: null,
    pt_subjects: null,
    pt_files: null,
    pt_file_download_link: null,
    fc_subjects: null,
    fc_topics: null,
    flashcards: null
};

export default (state = INITIAL, action) => {
    switch (action.type) {
        // COMMON QUESTIONS
        case GET_CQ_SUBJECTS:
            return { ...state, cq_subjects: null }
        case GET_CQ_SUBJECTS_SUCCESS:
            return { ...state, cq_subjects: action.payload }
        case GET_CQ_SUBJECTS_FAIL:
            return { ...state, cq_subjects: null }
        case GET_CQ_QUESTIONS:
            return { ...state, cq_questions: null }
        case GET_CQ_QUESTIONS_SUCCESS:
            return { ...state, cq_questions: action.payload }
        case GET_CQ_QUESTIONS_FAILED:
            return { ...state, cq_questions: null }
         case GET_CQ_FULL_ANSWER:
            return { ...state, cq_full_answer: null }
        case GET_CQ_FULL_ANSWER_SUCCESS:
            return { ...state, cq_full_answer: action.payload }
        case GET_CQ_FULL_ANSWER_FAILED:
            return { ...state, cq_full_answer: null }
        
        // PRACTICE TESTS
        case GET_PT_SUBJECTS:
            return { ...state, pt_subjects: null }
        case GET_PT_SUBJECTS_SUCCESS:
            return { ...state, pt_subjects: action.payload }
        case GET_PT_SUBJECTS_FAIL:
            return { ...state, pt_subjects: null }
        case GET_PT_FILES:
            return { ...state, pt_files: null }
        case GET_PT_FILES_SUCCESS:
            return { ...state, pt_files: action.payload }
        case GET_PT_FILES_FAIL:
            return { ...state, pt_files: null }
        case GET_PT_FILE_DOWNLOAD_LINK:
            return { ...state, pt_file_download_link: null }
        case GET_PT_FILE_DOWNLOAD_LINK_SUCCESS:
            return { ...state, pt_file_download_link: action.payload }
        case GET_PT_FILE_DOWNLOAD_LINK_FAIL:
            return { ...state, pt_file_download_link: null }
        
        /* FLASHCARDS */
        case GET_FLASHCARDS:
            return { ...state, flashcards: null }
        case GET_FLASHCARDS_SUCCESS:
            return { ...state, flashcards: action.payload }
        case GET_FLASHCARDS_FAIL:
            return { ...state, flashcards: null }
        case GET_FLASHCARD_TOPICS:
            return { ...state, fc_topics: null }
        case GET_FLASHCARD_TOPICS_SUCCESS:
            return { ...state, fc_topics: action.payload }
        case GET_FLASHCARD_TOPICS_FAIL:
            return { ...state, fc_topics: null }
        case GET_FLASHCARD_SUBJECTS:
            return { ...state, fc_subjects: null }
        case GET_FLASHCARD_SUBJECTS_SUCCESS:
            return { ...state, fc_subjects: action.payload }
        case GET_FLASHCARD_SUBJECTS_FAIL:
            return { ...state, fc_subjects: null }
        default:
            return state
    }
};