import React from 'react';
import './CeoV2.scss';
import Roland_photo from '../../../Assets/HomeV2/Roland_photo.png';
import marker from '../../../Assets/HomeV2/marker.png';

export default class CeoV2 extends React.Component {
    render() {
        return (
          <div className="ceo-component">
              <div className="container">
                  <div className="marker-content">
                      <img src={marker} alt="marker" className="marker" />  
                  </div>
                  <div className="ceo-content">
                      <div className="description text-center"> Stressing about poor grades, homework, cost of tutoring, and lack of academic confidence is a thing of the past for K - 12 and College students using <strong className="strong-bg">GradeGetter</strong></div>
                      <div className="v-c mt-3">
                          {/* <img src={Roland_photo} alt="avatar" className="avatar" /> */}
                          <p className="">Roland Omene, CEO of GradeGetter</p>
                      </div>
                  </div>
              </div>
          </div>
        )
    };
};