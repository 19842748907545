import React from "react";
import "./WhyTutorV2.scss";
import why_tutor_1 from "../../../Assets/HomeV2/why-tutor-1.png";
import why_tutor_2 from "../../../Assets/HomeV2/why-tutor-2.png";
import why_tutor_3 from "../../../Assets/HomeV2/why-tutor-3.png";
import why_tutor_4 from "../../../Assets/HomeV2/why-tutor-4.png";
import why_tutor_5 from "../../../Assets/HomeV2/why-tutor-5.png";
import why_tutor_6 from "../../../Assets/HomeV2/why-tutor-6.png";
import why_tutor_7 from "../../../Assets/HomeV2/why-tutor-7.png";
import why_tutor_8 from "../../../Assets/HomeV2/why-tutor-8.png";

const WhyTutorData = [
  [
    {logo: why_tutor_1, title: "Free trial tutoring lesson", description: "We want to ensure you're 100% happy with our tutor's quality before you start paying for tutoring."},
    {logo: why_tutor_2, title: "98% success rate", description: "Over 98% of our parents and students refer us to their friends and families and experience academic success with our 1-1 tutoring."},
    {logo: why_tutor_3, title: "Pay what you can afford starting at $27.4/hr", description: "We let our customers choose what they can pay for online and one-on-one tutoring. Paying a little more helps offset the cost for families who might not afford to tutor regularly. With this approach, we can make private tutoring more accessible and affordable for everyone."},
    {logo: why_tutor_4, title: "We believe in Christian values and philosophies", description: "It guides all of our business decisions, including how we price our services to those who would otherwise not be able to afford it. GradeGetter is meant to be a welcoming place for all students from all walks of life where each student feels that they are welcomed, valued, and honored."},
  ],
  [
    {logo: why_tutor_5, title: "100% tutor satisfaction or money back", description: "You only start paying for lessons after completing your free trial lesson with your assigned tutor, and you're 100% satisfied with how it went."},
    {logo: why_tutor_6, title: "No contract, no required packages, cancel any time", description: "There is no tutoring contract and no long-term commitment; you design your payment and rates as you see fit. You can either pay weekly, biweekly, monthly, or just make a one-time payment."},
    {logo: why_tutor_7, title: "Extensive background check", description: "All of our tutors have to complete an extensive background check."},
    {logo: why_tutor_8, title: "Tutoring for K-12, College and test prep", description: "We're you're one-stop-shop for all your tutoring needs in any academic subject, language, and test preparation at all levels."},
  ],
];

export default class WhyTutorV2 extends React.Component {
  render() {
    return (
      <div className="why-tutor-v2-component">
        <div className="container why-tutor-container">
          <h1>Why tutor with us?</h1>
          <div className="row main-row">
            <div className="col-lg-6 left-area">
              {WhyTutorData[0].map((item, index)=> (
                <div key={index} className="item-area">
                  <div>
                    <img src={item.logo} alt="logo" />   
                  </div>
                  <div className="ml-3">
                    <div className="title">{item.title}</div>
                    <div className="description">{item.description}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-6 right-area">
              {WhyTutorData[1].map((item, index)=> (
                <div key={index} className="item-area">
                  <div>
                    <img src={item.logo} alt="logo" />   
                  </div>
                  <div className="ml-3">
                    <div className="title">{item.title}</div>
                    <div className="description">{item.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
