import React from 'react';
import './SiteInfoPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Privacy, Refund, Term } from './infos';
import { Helmet } from 'react-helmet';

function innerFunc(item) {
    return {__html: item.content}
}

class SiteInfoPage extends React.Component { 
    render() {
        let title = '';
        let infos = [];

        if (this.props.location.pathname === '/privacy') {
            title = 'Privacy';
            infos = Privacy;
        } else if (this.props.location.pathname === '/refund') {
            title = 'Refund';
            infos = Refund;
        } else if (this.props.location.pathname === '/terms') {
            title = 'Terms And Conditions';
            infos = Term;
        }

        return (
            <div className="siteinfo-page">
                <Helmet>
                    <title>{`GradeGetter - ${title}`}</title>
                    <meta name="title" content={`GradeGetter - ${title}`} />
                    <meta name="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={`GradeGetter - ${title}`} />
                    <meta itemprop="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
                    <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`GradeGetter - ${title}`} />
                    <meta property="og:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
                    <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`GradeGetter - ${title}`}/>
                    <meta name="twitter:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College"/>
                    <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
                </Helmet>
                <div className="container siteinfo-container">
                    <div className="title">{title}</div>
                    <div className="siteinfo-list shadow-object">
                        {
                            infos.map((item, index) => {
                                return (
                                    <div className="siteinfo" key={index}>
                                        <div className="siteinfo-title">{item.title}</div>
                                        <div className="siteinfo-content" dangerouslySetInnerHTML={innerFunc(item)}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps, { })(withRouter(SiteInfoPage));