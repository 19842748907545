import React from 'react'
import './style.scss'
import { ReactSVG } from "react-svg";
import DownloadIcon from 'src/Assets/PracticeQuestions/download.svg'
import PDFIcon from 'src/Assets/PracticeQuestions/pdf-document.svg'

export default function ResourceItem({item}) {
 
    return (
        <div className='bg-item-wrapper'>
            <div className='dotted-bg'></div>
            <div className='resource-item-component shadow-object'>
                <div className='resource-container'>
                    <ReactSVG src={PDFIcon} />
                    <div className='info'>
                        <div className='resource-title'>{item.title}</div>
                        <div className='resource-size'>{ item.size }</div>
                    </div>
                    <ReactSVG src={DownloadIcon} className='pointer'/>
                </div>
            </div>
        </div>
    )
}