import React, { useEffect, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import SubjectItem from './SubjectItem';


export default function SubjectList({ subjects }) {
  let history = useHistory();
  const [subjectCount, setSubjectCount] = useState(10)

  const onSubject = (item) => {
    const name = item.name.replace(/\s+/g, '-')
    history.push(`/flashcard-topics/${name}/${item.id}`);
  }

  return (
    <div className="subject-lists-component">
      <div className="container home-start-container">
        <h2 className='text-center'>Subjects</h2>
        <div className='sub-description text-center'>Select subject you want to discover</div>
        <div className='list-wrapper mt-4'>
          <div className='subject-list'>
            {subjects
              .slice(0, subjectCount)
              .map(item => (
                <SubjectItem
                  key={item.id}
                  subject={item}
                  onClick={() => onSubject(item) }
                />
              ))}
          </div>
          { subjectCount < subjects.length && <div className='btn-3 load-more-btn' onClick={() => setSubjectCount(subjectCount + 10)}>LOAD MORE</div> }
        </div>
      </div>
    </div>
  );
}