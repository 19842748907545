/**
 * Sidebar
 */
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";

/**
 * Tutor
 */
export const TUTOR_MODAL_TOGGLE = "TUTOR_MODAL_TOGGLE";
export const TUTOR_SIGN_UP = "TUTOR_SIGN_UP";
export const TUTOR_SIGN_UP_SUCCESS = "TUTOR_SIGN_UP_SUCCESS";
export const TUTOR_SIGN_UP_FAIL = "TUTOR_SIGN_UP_FAIL";



export const GET_TUTOR_PROFILE_UPDATE = "GET_TUTOR_PROFILE_UPDATE";
export const GET_TUTOR_PROFILE_UPDATE_SUCCESS = "GET_TUTOR_PROFILE_UPDATE_SUCCESS";
export const GET_TUTOR_PROFILE_UPDATE_FAIL = "GET_TUTOR_PROFILE_UPDATE_FAIL";

/**
 * Auth
 */
export const GET_AUTH_SIGN_IN = "GET_AUTH_SIGN_IN";
export const GET_AUTH_SIGN_IN_SUCCESS = "GET_AUTH_SIGN_IN_SUCCESS";
export const GET_AUTH_SIGN_IN_FAIL = "GET_AUTH_SIGN_IN_FAIL";

export const GET_AUTH_SIGN_OUT = "GET_AUTH_SIGN_OUT";
export const GET_AUTH_SIGN_OUT_SUCCESS = "GET_AUTH_SIGN_OUT_SUCCESS";
export const GET_AUTH_SIGN_OUT_FAIL = "GET_AUTH_SIGN_OUT_FAIL";

export const GET_AUTH_SIGN_UP = "GET_AUTH_SIGN_UP";
export const GET_AUTH_SIGN_UP_SUCCESS = "GET_AUTH_SIGN_UP_SUCCESS";
export const GET_AUTH_SIGN_UP_FAIL = "GET_AUTH_SIGN_UP_FAIL";

export const GET_AUTH_HUBSPOT_SIGN_UP = "GET_AUTH_HUBSPOT_SIGN_UP";
export const GET_AUTH_HUBSPOT_SIGN_UP_SUCCESS = "GET_AUTH_HUBSPOT_SIGN_UP_SUCCESS";
export const GET_AUTH_HUBSPOT_SIGN_UP_FAIL = "GET_AUTH_HUBSPOT_SIGN_UP_FAIL";

export const GET_AUTH_FORGOT_PASSWORD = "GET_AUTH_FORGOT_PASSWORD";
export const GET_AUTH_FORGOT_PASSWORD_SUCCESS =
  "GET_AUTH_FORGOT_PASSWORD_SUCCESS";
export const GET_AUTH_FORGOT_PASSWORD_FAIL = "GET_AUTH_FORGOT_PASSWORD_FAIL";

export const GET_AUTH_RESET_PASSWORD = "GET_AUTH_RESET_PASSWORD";
export const GET_AUTH_RESET_PASSWORD_SUCCESS =
  "GET_AUTH_RESET_PASSWORD_SUCCESS";
export const GET_AUTH_RESET_PASSWORD_FAIL = "GET_AUTH_RESET_PASSWORD_FAIL";

export const AUTH_UPDATE_PLAN = "AUTH_UPDATE_PLAN";


/**
 * Connections
 */
export const GET_CONNECTIONS = "GET_CONNECTIONS";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTIONS_SUCCESS";
export const GET_CONNECTIONS_FAIL = "GET_CONNECTIONS_FAIL";

/**
 * Plans
 */
export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";

/**
 * Levels
 */
export const GET_LEVELS = "GET_LEVELS";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAIL = "GET_LEVELS_FAIL";
/**
 * Jobs
 */
export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAIL = "GET_JOBS_FAIL";

/**
 * TUTORS
 */
export const GET_TUTORS = "GET_TUTORS";
export const GET_TUTORS_SUCCESS = "GET_TUTORS_SUCCESS";
export const GET_TUTORS_FAIL = "GET_TUTORS_FAIL";

/**
 * SUBJECTS
 */
export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL";

/**
 * SCHOOLS
 */
export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAIL = "GET_SCHOOLS_FAIL";

export const GET_SORT_SCHOOLS = "GET_SORT_SCHOOLS";
export const GET_SORT_SCHOOLS_SUCCESS = "GET_SORT_SCHOOLS_SUCCESS";
export const GET_SORT_SCHOOLS_FAIL = "GET_SORT_SCHOOLS_FAIL";


/**
 * CITIES
 */
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

export const INIT_APP = "INIT_APP";

export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_BLOG_LIST_SUCCESS = "GET_BLOG_LIST_SUCCESS";
export const GET_BLOG_LIST_FAIL = "GET_BLOG_LIST_FAIL";


/**
 * Mailchimp
 */
export const MAILCHIMP = "MAILCHIMP";
export const MAILCHIMP_SUCCESS = "MAILCHIMP_SUCCESS";
export const MAILCHIMP_FAIL = "MAILCHIMP_FAIL";

/**
 * BRAINTREEKEY
 */
export const GET_BRAINTREEKEY = "GET_BRAINTREEKEY";
export const GET_BRAINTREEKEY_SUCCESS = "GET_BRAINTREEKEY_SUCCESS";
export const GET_BRAINTREEKEY_FAIL = "GET_BRAINTREEKEY_FAIL";

/**
 * SEND_NONCE_TO_SERVER
 */
export const GET_SEND_NONCE_TO_SERVER = "GET_SEND_NONCE_TO_SERVER";
export const GET_SEND_NONCE_TO_SERVER_SUCCESS = "GET_SEND_NONCE_TO_SERVER_SUCCESS";
export const GET_SEND_NONCE_TO_SERVER_FAIL = "GET_SEND_NONCE_TO_SERVER_FAIL";

/**
 * PROFILE UPDATE
 */
export const GET_PROFILE_UPDATE = "GET_PROFILE_UPDATE";
export const GET_PROFILE_UPDATE_SUCCESS = "GET_PROFILE_UPDATE_SUCCESS";
export const GET_PROFILE_UPDATE_FAIL = "GET_PROFILE_UPDATE_FAIL";


/**
 * CHANNELS
 */
export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_FAIL = "GET_CHANNELS_FAIL";

/**
 * CHANNEL DETAIL
 */
export const GET_CHANNEL_DETAIL = "GET_CHANNEL_DETAIL";
export const GET_CHANNEL_DETAIL_SUCCESS = "GET_CHANNEL_DETAIL_SUCCESS";
export const GET_CHANNEL_DETAIL_FAIL = "GET_CHANNEL_DETAIL_FAIL";

/**
 * VIDEO DETAIL
 */
export const GET_VIDEO_DETAIL = "GET_VIDEO_DETAIL";
export const GET_VIDEO_DETAIL_SUCCESS = "GET_VIDEO_DETAIL_SUCCESS";
export const GET_VIDEO_DETAIL_FAIL = "GET_VIDEO_DETAIL_FAIL";

/**
 * HOME DATA
 */
export const GET_HOME_DATA = "GET_HOME_DATA";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_FAIL = "GET_HOME_DATA_FAIL";

/**
 * TV DATA
 */
export const GET_TV_DATA = "GET_TV_DATA";
export const GET_TV_DATA_SUCCESS = "GET_TV_DATA_SUCCESS";
export const GET_TV_DATA_FAIL = "GET_TV_DATA_FAIL";

/**
 * TV SUBSCRIBE
 */
export const GET_TV_SUBSCRIBE = "GET_TV_SUBSCRIBE";
export const GET_TV_SUBSCRIBE_SUCCESS = "GET_TV_SUBSCRIBE_SUCCESS";
export const GET_TV_SUBSCRIBE_FAIL = "GET_TV_SUBSCRIBE_FAIL";

/**
 * UNIVERSITY LANDING DATA
 */
export const GET_UNIVERSITY_LADING_DATA = "GET_UNIVERSITY_LADING_DATA";
export const GET_UNIVERSITY_LADING_DATA_SUCCESS = "GET_UNIVERSITY_LADING_DATA_SUCCESS";
export const GET_UNIVERSITY_LADING_DATA_FAIL = "GET_UNIVERSITY_LADING_DATA_FAIL";

/**
 * Modal
 */
 export const FEEDBACK_MODAL_TOGGLE = 'FEEDBACK_MODAL_TOGGLE';
 export const HOMEV2_MODAL_TOGGLE = 'HOMEV2_MODAL_TOGGLE';
 

 /**
 * WIKIPEDIA CITY DETAIL
 */
export const GET_WIKIPEDIA_DETAIL = "GET_WIKIPEDIA_DETAIL";
export const GET_WIKIPEDIA_DETAIL_SUCCESS = "GET_WIKIPEDIA_DETAIL_SUCCESS";
export const GET_WIKIPEDIA_DETAIL_FAIL = "GET_WIKIPEDIA_DETAIL_FAIL";

 /**
 * FIRST CHARGE $1
 */
  export const FIRST_CHARGE = "FIRST_CHARGE";
  export const FIRST_CHARGE_SUCCESS = "FIRST_CHARGE_SUCCESS";
  export const FIRST_CHARGE_FAIL = "FIRST_CHARGE_FAIL";
  
/* COMMON QUESTIONS */
export const GET_CQ_SUBJECTS = "GET_CQ_SUBJECTS";
export const GET_CQ_SUBJECTS_SUCCESS = "GET_CQ_SUBJECTS_SUCCESS";
export const GET_CQ_SUBJECTS_FAIL = "GET_CQ_SUBJECTS_FAIL";

export const GET_CQ_QUESTIONS = "GET_CQ_QUESTIONS"
export const GET_CQ_QUESTIONS_FAILED = "GET_CQ_QUESTIONS_FAILED"
export const GET_CQ_QUESTIONS_SUCCESS = "GET_CQ_QUESTIONS_SUCCESS"

export const GET_CQ_FULL_ANSWER = "GET_CQ_FULL_ANSWER"
export const GET_CQ_FULL_ANSWER_FAILED = "GET_CQ_FULL_ANSWER_FAILED"
export const GET_CQ_FULL_ANSWER_SUCCESS = "GET_CQ_FULL_ANSWER_SUCCESS"

/* PRACTICE TESTS */
export const GET_PT_SUBJECTS = "GET_PT_SUBJECTS"
export const GET_PT_SUBJECTS_FAIL = "GET_PT_SUBJECTS_FAIL"
export const GET_PT_SUBJECTS_SUCCESS = "GET_PT_SUBJECTS_SUCCESS"

export const GET_PT_FILES = "GET_PT_FILES"
export const GET_PT_FILES_FAIL = "GET_PT_FILES_FAIL"
export const GET_PT_FILES_SUCCESS = "GET_PT_FILES_SUCCESS"

export const GET_PT_FILE_DOWNLOAD_LINK = "GET_PT_FILE_DOWNLOAD_LINK"
export const GET_PT_FILE_DOWNLOAD_LINK_FAIL = "GET_PT_FILE_DOWNLOAD_LINK_FAIL"
export const GET_PT_FILE_DOWNLOAD_LINK_SUCCESS = "GET_PT_FILE_DOWNLOAD_LINK_SUCCESS"

/* MAKE IT MAKE SENSE TOPICS */
export const GET_MIMS_TOPICS = "GET_MIMS_TOPICS"
export const GET_MIMS_TOPICS_FAIL = "GET_MIMS_TOPICS_FAIL"
export const GET_MIMS_TOPICS_SUCCESS = "GET_MIMS_TOPICS_SUCCESS"

/* FLASHCARDS */
export const GET_FLASHCARD_SUBJECTS = "GET_FLASHCARD_SUBJECTS"
export const GET_FLASHCARD_SUBJECTS_FAIL = "GET_FLASHCARD_SUBJECTS_FAIL"
export const GET_FLASHCARD_SUBJECTS_SUCCESS = "GET_FLASHCARD_SUBJECTS_SUCCESS"

export const GET_FLASHCARD_TOPICS = "GET_FLASHCARD_TOPICS"
export const GET_FLASHCARD_TOPICS_FAIL = "GET_FLASHCARD_TOPICS_FAIL"
export const GET_FLASHCARD_TOPICS_SUCCESS = "GET_FLASHCARD_TOPICS_SUCCESS"

export const GET_FLASHCARDS = "GET_FLASHCARDS"
export const GET_FLASHCARDS_FAIL = "GET_FLASHCARDS_FAIL"
export const GET_FLASHCARDS_SUCCESS = "GET_FLASHCARDS_SUCCESS"