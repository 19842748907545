import React from 'react'
import {
    PTQuestionHeader,
    PTSubjectList
} from 'src/Components'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helper from "../../../Util/Helper"
import {
    getPTSubjectsAction
} from '../../../Redux/Actions'
import { Helmet } from 'react-helmet';

class PTSubjectsPage extends React.Component {

    componentDidMount() {
        Helper.showSpinner()
        this.props.getPTSubjectsAction().then(() => {
            Helper.hideSpinner()
        })
    }
   
    render() {
        const { subjects } = this.props
        const title = "Download Practice Questions for Various Subjects | GradeGetter.com" 
        const description = "Boost your study routine with GradeGetter.com. Download comprehensive practice questions across a range of subjects and levels. Sharpen your knowledge and ace your exams with our expertly crafted resources."

        return (
            <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href}/>
                <meta property="image" content="https://gradegetter.com/logos/practice-test.png" />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={title} />
                <meta itemprop="description" content={description} />
                <meta itemprop="image" content="https://gradegetter.com/logos/practice-test.png" />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://gradegetter.com/logos/practice-test.png" />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image" content="https://gradegetter.com/logos/practice-test.png" />       
            </Helmet>    
            <div className='ptest-subjects-page'>
                <PTQuestionHeader />
                {subjects && <PTSubjectList subjects={ subjects } />}
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    subjects: state.Question.pt_subjects
})

export default connect(mapStateToProps, {
    getPTSubjectsAction
})(withRouter(PTSubjectsPage))