import React from 'react';
import './VideoDetail.scss';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Helper from '../../../Util/Helper';
import { withRouter } from "react-router-dom";
import { PlayArrow } from '@material-ui/icons';
import { SubscribeModal } from "../../../Components";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Disqus from "disqus-react"
import valFacebookIcon from "../../../Assets/Home/val-facebook.png";
import valLinkedIn from "../../../Assets/Home/valLinkedIn.png";
import valTwitter from "../../../Assets/Home/valTwitter.png";
import {
  tvSubscribeAction,
} from "../../../Redux/Actions";

function innerFunc(item) {
  return { __html: item }
}

class VideoDetail extends React.Component {

  state = {
    embed_code: '',
    videoTitle: '',
    videoDescription: '',
    playing: false,
    submit_email: '',
    is_submit_email: true,
    isSubscribeModal: false
  };

  componentDidMount = async () => {
    const { videoDetailData } = this.props;
    this.setState({
      embed_code: videoDetailData.embed_code,
      videoTitle: videoDetailData.name,
      videoDescription: videoDetailData.description
    });
  };


  componentDidUpdate = async (prevProps) => {

    setTimeout(function () {
      const script = document.createElement("script");
      script.src = "https://muse.ai/static/js/embed-player.min.js";
      script.async = true;
      document.body.appendChild(script);
    }.bind(this), 2000)
  };


  playVideo = (e) => {
    this.videoPlayer.play();
  };

  relatedVideoPlay = (data) => {
    this.props.history.push(`/gradeVideo/${data.id}`);
  };

  subscribe = () => {
    this.setState({ isSubscribeModal: true });
  };

  subscribeConfirm = (status, email) => {
    this.setState({ isSubscribeModal: false });
    if (status) {
      Helper.showSpinner();

      const formData = new FormData()
      formData.append('email', email);
      formData.append('tag', this.props.videoDetailData.subscribe_tag);

      this.props.tvSubscribeAction(formData).then(() => {
        const { subscribeRes } = this.props;

        if (subscribeRes) {
          toast(subscribeRes);
        } else {
          toast("Error!");
        };
        Helper.hideSpinner();
      });
    };
  };

  submitSubscribe = () => {
    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const { submit_email } = this.state;

    if (regEmail.test(submit_email)) {
      this.setState({ is_submit_email: true });
      this.subscribeConfirm(true, submit_email)
    } else {
      this.setState({ is_submit_email: false });
    };
  };



  render() {
    const ShareURL = process.env.REACT_APP_PUBLIC_DOMAIN + window.location.pathname;
    const { videoTitle, videoDescription, playing, submit_email, embed_code, is_submit_email } = this.state;
    const { videoDetailData } = this.props;

    const disqusShortname = "GradeGetter"
    const disqusConfig = {
      url: ShareURL,
      identifier: embed_code,
      title: videoTitle
    }

    return (
      <div className="video-detail-page">
        <div className="row">
          <div className="col-lg-9 video-player-area text-center">
            <div
              className="muse-video-player"
              data-video={embed_code}
              data-width="1200"
              // data-height="1200" 
              data-links="0"
              data-logo="https://api.gradegetter.com/img/full_logo.png"
            />
            {/* <iframe
              src={`https://muse.ai/embed/${embed_code}?start=20&volume=75`}
              height={"100%"}
              width={"100%"}
              frameborder="0"
              allowfullscreen
              title='video'
            >
            </iframe> */}
          </div>
          <div className="col-lg-3">
            <div className="video-submit  text-center">
              <h2>{videoDetailData.subscribe_message}</h2>
              <img src={videoDetailData.subscribe_image} alt="subscribing-img" className="subscribing-img" />
              <div className="submit-container">
                <div className="submit-note">Where should we email the note to ?</div>
                <input className={`email-input ${is_submit_email ? "" : "email-input-error"}`}
                  placeholder="Email"
                  value={submit_email}
                  onChange={(event) => this.setState({ submit_email: event.target.value })}
                />
                <div className="submit-btn" onClick={() => this.submitSubscribe()}>Submit</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-9 ">
            <div className="video-title">
              {videoTitle}
            </div>
            <div className="video-share-subscribe">
              <div className="video-share">
                <div className="share-description">Share</div>
                <FacebookShareButton url={ShareURL} className="share-btn" >
                  <img src={valFacebookIcon} alt="facebook" />
                </FacebookShareButton>
                <LinkedinShareButton url={ShareURL} className="share-btn" >
                  <img src={valLinkedIn} alt="linkedin" />
                </LinkedinShareButton>
                <TwitterShareButton url={ShareURL} className="share-btn" >
                  <img src={valTwitter} alt="twitter" />
                </TwitterShareButton>
              </div>
              <div className="subscribe-btn"
                onClick={() => this.subscribe()}>
                SUBSCRIBED
              </div>
            </div>
            <div className="video-description" dangerouslySetInnerHTML={innerFunc(videoDescription)} />

            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div>
          <div className="col-lg-3 ">
            <div className="related-videos-container text-center">
              <div className="related-video-head-title">
                RELATED VIDEOS
              </div>
              {(videoDetailData.related_videos && videoDetailData.related_videos.length > 0) && videoDetailData.related_videos.map((data, index) => (
                <div className="col-12 related-video-list" key={index}>
                  <img src={data.meta_image} alt="video" className="video-image" />

                  <div className="play-btn-bg" onClick={e => this.relatedVideoPlay(data)}>
                    <PlayArrow className="play-icon" />
                  </div>
                  <div className="related-video-title mt-1">
                    {data.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <SubscribeModal
          isSubscribeModal={this.state.isSubscribeModal}
          subscribeConfirm={(e, r) => this.subscribeConfirm(e, r)}
          title="Get expert scaling strategies delivered to your inbox weekly 👇"
          btnTxt="Subscribe for updates"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscribeRes: state.Auth.subscribeRes,
  subscribeErr: state.Auth.subscribeErr,
});

export default connect(mapStateToProps, {
  tvSubscribeAction
})(withRouter(VideoDetail));