import React from "react";
import "./HomeV2Page.scss";
import { connect } from "react-redux";
import {
  StartAreaV2,
  BrandV2,
  WhyTutorV2,
  ClientSayingV2,
  TutorsV2,
  HowItWorksV2,
  OurCoreV2,
  CeoV2,
  FooterV2
} from "../../Components";
import {
  getTutorAction,
  getConnectionAction,
} from "../../Redux/Actions";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";

class HomeV2Page extends React.Component {
  state ={
    subject: ""
  }

  componentDidMount() {
    window.intercomSettings = {
      "hide_default_launcher": true
    };

    this.props.getTutorAction();
    this.props.getConnectionAction();

    if (this.props.location.search && this.props.location.search.length > 0) {
      const params = new URLSearchParams(this.props.location.search);
      const subject = params.get('subject');
      if(subject){
        this.setState({subject});
      };
    };
  };

  componentWillUnmount(){
    window.intercomSettings = {
      "hide_default_launcher": false
    };
  }

  render() {
    const { tutors, connections } = this.props;
    const { subject } = this.state;
    return (
      <main>
        <Helmet>
          <title>#1 Online Christian Tutoring Company | GradeGetter</title>
          <meta name="title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta name="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta itemprop="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="#1 Online Christian Tutoring Company | GradeGetter" />
          <meta property="og:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="#1 Online Christian Tutoring Company | GradeGetter"/>
          <meta name="twitter:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College"/>
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
        </Helmet>
        <div className="home-page" ref={this.workContainer}>
          <StartAreaV2 subject={subject}/>
          <BrandV2 />
          <WhyTutorV2 />
          {connections && <ClientSayingV2 reviews={connections}/>}
          {tutors && <TutorsV2 tutors={tutors} />}
          <HowItWorksV2 />
          <OurCoreV2 />
          <CeoV2 />
          <FooterV2 />          
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  tutors: state.Core.tutors,
  connections: state.Core.connections,
});

export default connect(mapStateToProps, {
  getTutorAction,
  getConnectionAction,
})(withRouter(HomeV2Page));
