import React from 'react';
import './ContactUsPage.scss';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { Helmet } from 'react-helmet';

export class ContactUsPage extends React.Component {
    render() {
        return (
            <div className="contactus-page">
                <Helmet>
                    <title>GradeGetter - Contact Us</title>
                    <meta name="title" content="GradeGetter - Contact Us" />
                    <meta name="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content="GradeGetter - Contact Us" />
                    <meta itemprop="description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
                    <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="GradeGetter - Contact Us" />
                    <meta property="og:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College" />
                    <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="GradeGetter - Contact Us"/>
                    <meta name="twitter:description" content="Your First 30 Minutes Is FREE And You Pay What You Can Afford for One-on-One Online Tutoring For K -12, Test Preparation & College"/>
                    <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
                </Helmet>
                <div className="container contactus-container">
                    <div className="title">Contact Us</div>
                    <div className="row shadow-object main-row">
                        <div className="col-lg-6 left-area">
                            GradeGetter Corporate Office <br />
                            1751 River Run Suite 200 <br />
                            Fort Worth, TX 76107 <br /> <br />
                            {/* <b>Phone: (877) 904 0134</b><br /> */}
                            <b>Email: info@GradeGetter.com</b>
                        </div>
                        <div className="col-lg-6 right-area">
                            <div className="map-wrapper">
                                <div className="map-container">
                                    <Map
                                        google={this.props.google}
                                        zoom={8}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        initialCenter={{ lat: 32.726789, lng: -97.359885 }}
                                    >
                                        <Marker position={{ lat: 32.726789, lng: -97.359885 }} />
                                    </Map>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper(
    (props) => ({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        language: 'en',
        region: 'us'
    }
))(ContactUsPage)