import React from 'react';
import './StartButtonV2.scss';
import arrow from '../../../Assets/Common/arrow.svg';
import { ReactSVG } from 'react-svg'

export default class StartButtonV2 extends React.Component {

     constructor(props){
        super(props);
        this.state = {
            btnText: ""
        }
     }
    
    render() {
        const { className, height, startText, onClick, isOtherBtn} = this.props;
        let btnHeight = height || '60px';
        let btnText = startText || 'Get Started';

        return (
            <div className={`startbutton-v2-component shadow-object-2 ${className}`} onClick={onClick}>
                <div className="btn-area" style={{height: btnHeight}}>
                    <div className="btn-text btn-element">
                        {btnText}
                    </div>
                </div>
            </div>
        );
    }
}
