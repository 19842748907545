import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    FlashcardTopicHeader,
    FlashcardTopicList
} from 'src/Components'
import {
    getFcTopicsAction
} from '../../../Redux/Actions';
import Helper from "src/Util/Helper";
import { Helmet } from "react-helmet";

class FlashcardTopicsPage extends React.Component {

    getSubject() {
        const subject = this.props.match.params.subject
        return subject.replace(/-/g, ' ')
    }

    componentDidMount() {
        Helper.showSpinner()
        this.props.getFcTopicsAction(this.props.match.params.id).then(() => {
            Helper.hideSpinner()
        })
    }

    render() {
        const subject = this.getSubject()
        const { topics } = this.props
        const title = `Flashcards for ${subject} - By GradeGetter `;
        const description = `Explore our ${subject} flashcards for efficient topic reviews. Perfect for exam prep and ${subject} review. Start mastering today!`
        
        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title} />
                    <meta name="description" content={description} />
                    <meta property="type" content="website" />
                    <meta property="url" content={window.location.href}/>
                    <meta property="image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Google / Search Engine Tags --> */}
                    <meta itemprop="name" content={title} />
                    <meta itemprop="description" content={description} />
                    <meta itemprop="image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Facebook Meta Tags --> */}
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content="https://gradegetter.com/logos/flashcard.png" />

                    {/* <!-- Twitter Meta Tags --> */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>
                    <meta name="twitter:image" content="https://gradegetter.com/logos/flashcard.png" />
                </Helmet>
                <FlashcardTopicHeader subject={subject} />
                { topics && <FlashcardTopicList topics={topics} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    topics: state.Question.fc_topics
});

export default connect(mapStateToProps, {
    getFcTopicsAction
})(withRouter(FlashcardTopicsPage))