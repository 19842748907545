import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import './BlogFooter.scss';
import {mailchimpAction} from "../../../Redux/Actions";

class BlogFooter extends React.Component {

    state = {
        mail: "",
        selectMail: null
    }

    changeMail = (value) => {
        if(value.length > 7) {
            this.setState({selectMail: true});
        } else {
            this.setState({selectMail: false});
        }
        this.setState({mail: value})

    }

    navigateToSignup = () => {
        const payload = {
            email: this.state.mail
        }
        if(this.state.selectMail){
            this.props.mailchimpAction(payload).then(()=> {
                if(this.props.mailchimpStatus === "Success"){
                    this.props.history.push(`/sign-up`);
                }
            })
        } else {
            this.setState({selectMail: false});
        }
    }

    render() {
        return (
            <div className="blogfooter-component">
                <div className="container blogfooter-container">
                    <div className="row main-row">
                        <div className="col-lg-6 left-area">
                            <h1>No contract.</h1>
                            <h1>No minimum commitment.</h1>
                            {/* <img src={footerPrice} className="show-mobile" alt="footer-price" /> */}
                            <p>One On One Online Tutoring for K-12, College, and Test Prep</p>
                            <div className="form-area">
                                <div className="email-area">
                                    <div className="send-box">
                                        <input 
                                            className={`${this.state.selectMail || this.state.selectMail === null ? "email-input" : "email-input-not-fill" }`}
                                            placeholder="Enter your Email"
                                            value={this.state.mail}
                                            onChange= {(event)=>this.changeMail(event.target.value)}
                                        />
                                        <button className="send-btn" onClick={(e) => this.navigateToSignup()}>Get Started</button>
                                    </div>
                                    <div className="explain">Over 10,00 verified and checked US-based tutors ready to work with you!</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-area show-web-flex">
                            {/* <img src={blogRight} alt="right-bg" /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    mailchimpStatus: state.Auth.mailchimpStatus,
});

export default connect(mapStateToProps, {
    mailchimpAction
})(withRouter(BlogFooter));

