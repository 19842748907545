import React from 'react';
import './style.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function TopicHeader({ subject, topic }) {
  const history = useHistory();
  const params = useParams();

  const onNavigate = (path) => {
    switch (path) {
      case 'flashcard':
        history.push('/flashcards'); break;
      case 'topics':
        if (params.topic !== undefined && isQuestionsPage()) {
          const { subject, subjectId } = params;
          history.push(`/flashcard-topics/${subject}/${subjectId}`)
        } break;
      default: 
        return
    }
  }

  const isQuestionsPage = () => {
    return history.location.pathname.includes('flashcard-questions')
  }

  return (
    <div className="ws-question-header-component">
      <div className="container question-header-container">
        <div className="row">
          <div className="col-lg-6 left-content">
            <div className='pointer'>
              <span onClick={() => onNavigate('flashcard')}>Flashcard</span>
              <span className={topic === undefined ? 'opacity' : ''}>/</span>
              <span className={topic === undefined ? 'opacity' : ''} onClick={() => onNavigate('topics')}>{subject}</span>
              {
                topic !== undefined && (
                  <>
                     <span className='opacity'>/</span>
                     <span className='opacity'>{topic}</span>
                  </>
                )
              }
            </div>
            <div>
              <span>WORKSHEETS</span>
              <h1>{topic === undefined ? subject : topic }</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}