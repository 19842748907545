import React from 'react';
import './style.scss';
import { ReactSVG } from "react-svg";
import RightArrowIcon from 'src/Assets/CommonQuestions/arrow-right-icon.svg';

export default function PopularQuestionItem({
  info,
  onShowQuestion,
}) {

  return (
    <div className='popular-question-wrapper'>
      <div
        className="popular-question-item-component shadow-object"
        onClick={() => onShowQuestion(info)}>
        <div className='question-container'>
          <div className='question-title mb-2'>{info.name}</div>
          <ReactSVG
            src={RightArrowIcon}
            className='open-close-icon'
          />
        </div>
      </div>
      <div className='dotted-bg'></div>
    </div>
  );
}