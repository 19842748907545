import React from 'react';
import './PricePage.scss';

export default class PricePage extends React.Component { 
    render() {
        return (
            <div className="demo-page">
                <div className="container demo-container">
                    
                    
                </div>
            </div>
        );
    }
}