import {
    GET_CQ_SUBJECTS,
    GET_CQ_SUBJECTS_FAIL,
    GET_CQ_SUBJECTS_SUCCESS,
    GET_CQ_QUESTIONS,
    GET_CQ_QUESTIONS_FAILED,
    GET_CQ_QUESTIONS_SUCCESS,
    GET_CQ_FULL_ANSWER,
    GET_CQ_FULL_ANSWER_FAILED,
    GET_CQ_FULL_ANSWER_SUCCESS,
    GET_PT_SUBJECTS,
    GET_PT_SUBJECTS_FAIL,
    GET_PT_SUBJECTS_SUCCESS,
    GET_PT_FILES,
    GET_PT_FILES_FAIL,
    GET_PT_FILES_SUCCESS,
    GET_PT_FILE_DOWNLOAD_LINK, 
    GET_PT_FILE_DOWNLOAD_LINK_FAIL,
    GET_PT_FILE_DOWNLOAD_LINK_SUCCESS,
    GET_FLASHCARDS,
    GET_FLASHCARDS_FAIL,
    GET_FLASHCARDS_SUCCESS,
    GET_FLASHCARD_SUBJECTS,
    GET_FLASHCARD_SUBJECTS_FAIL,
    GET_FLASHCARD_SUBJECTS_SUCCESS,
    GET_FLASHCARD_TOPICS,
    GET_FLASHCARD_TOPICS_FAIL,
    GET_FLASHCARD_TOPICS_SUCCESS
} from '../Type';

import {
    getCQSubjects,
    getCQQuestions,
    getCQFullAnswer,
    getPTSubjects,
    getPTSubjectFiles,
    getPTFileDownloadLink,
    getFcSubjects,
    getFcTopics, 
    getFlashcards
} from '../../Api/Question'

export const getCQSubjectsAction = () => async (dispatch) => {
    dispatch({ type: GET_CQ_SUBJECTS })
    const res = await getCQSubjects()
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_CQ_SUBJECTS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_CQ_SUBJECTS_FAIL, payload: res.data });
    }
}

export const getCQQuestionsAction = (subject_id) => async (dispatch) => {
    dispatch({ type: GET_CQ_QUESTIONS })
    const res = await getCQQuestions(subject_id)
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_CQ_QUESTIONS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_CQ_QUESTIONS_FAILED, payload: res.data });
    }
}

export const getCQFullAnswerAction = (question_id) => async (dispatch) => {
    dispatch({ type: GET_CQ_FULL_ANSWER })
    const res = await getCQFullAnswer(question_id)
     if (res && res.data && res.data.data) {
        dispatch({ type: GET_CQ_FULL_ANSWER_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_CQ_FULL_ANSWER_FAILED, payload: res.data });
    }
}

export const getPTSubjectsAction = () => async (dispatch) => {
    dispatch({ type: GET_PT_SUBJECTS })
    const res = await getPTSubjects()
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_PT_SUBJECTS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_PT_SUBJECTS_FAIL, payload: res.data });
    }
}

export const getPTSubjectFilesAction = (subject_id) => async (dispatch) => {
    dispatch({ type: GET_PT_FILES })
    const res = await getPTSubjectFiles(subject_id)
    if (res && res.data && res.data.data) {
        dispatch({ type: GET_PT_FILES_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_PT_FILES_FAIL, payload: res.data });
    }
}

export const getPTFileDownloadLinkAction = (id, email) => async (dispatch) => {
    dispatch({ type: GET_PT_FILE_DOWNLOAD_LINK })
    const res = await getPTFileDownloadLink(id, email)
    if (res && res.data) {
        dispatch({ type: GET_PT_FILE_DOWNLOAD_LINK_SUCCESS, payload: res.data });
    } else {
        dispatch({ type: GET_PT_FILE_DOWNLOAD_LINK_FAIL, payload: res.data });
    }
}

export const getFcSubjectsAction = () => async (dispatch) => {
    dispatch({ type: GET_FLASHCARD_SUBJECTS })
    const res = await getFcSubjects();
    if (res && res.data) {
        dispatch({ type: GET_FLASHCARD_SUBJECTS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_FLASHCARD_SUBJECTS_FAIL, payload: res.data });
    }
}

export const getFcTopicsAction = (id) => async (dispatch) => {
    dispatch({ type: GET_FLASHCARD_TOPICS })
    const res = await getFcTopics(id);
    if (res && res.data) {
        dispatch({ type: GET_FLASHCARD_TOPICS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_FLASHCARD_TOPICS_FAIL, payload: res.data });
    }
}

export const getFlashcardsAction = (topicId) => async (dispatch) => {
    dispatch({ type: GET_FLASHCARDS })
    const res = await getFlashcards(topicId);
    if (res && res.data) {
        dispatch({ type: GET_FLASHCARDS_SUCCESS, payload: res.data.data });
    } else {
        dispatch({ type: GET_FLASHCARDS_FAIL, payload: res.data });
    }
}