import React from "react";
import Sticky from "react-sticky-el";
import "./SummerEnrichmentPage.scss";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import ceoPhoto from "../../Assets/Home/ceo-photo.jpg";
import seoSign from "../../Assets/Home/seo-sign.png";
import phone from "../../Assets/Home/phone.png";
import mail from "../../Assets/Home/mail.png";
import meta_image from "../../Assets/Modal/review-modal-mobile.png";
import valFacebookIcon from "../../Assets/Home/val-facebook.png";
import valLinkedIn from "../../Assets/Home/valLinkedIn.png";
import valTwitter from "../../Assets/Home/valTwitter.png";
import valMail from "../../Assets/Home/valMail.png";
import { Helmet } from 'react-helmet';
import Media from "react-media";
import { CarouselLeftButton, CarouselRightButton } from "../../Constant";
import Loadable from "@loadable/component";
import { TvMedia } from "../../Components";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

const SummerWorks = [
  {title: '1. FREE CONSULTATION', description: 'We offer a free enrollment consultation with our enrollment director to determine the assessment needed by the student and also schedule it'},
  {title: '2. FREE ASSESSMENT', description: 'We’re providing a free assessment in each subject you enroll your student in by waving the $49.99 assessment fees for the next 7 days. The objective of this assessment is to determine your child’s readiness for the fall semester. If your student is going into 5th-grade math, we will evaluate how ready he or she is ready for 5th-grade math (time permitting we can even work ahead).'},
  {title: '3. CUSTOMIZE 1-1 ENRICHMENT LESSONS:', description: 'Using a structured curriculum from CK - 12 Education Foundation, our instructors will prepare their class for the fall semester and start fresh in the Fall, ahead of their classmates.'},
];

const phoneMail = [
  { img: phone, des: "(817) 717 5371" },
  { img: mail, des: "info@gradegetter.com" },
  // { img: printer, des: "(877) 904 0134" },
];

const Subjects = [
  { title: "Elementary Math", price: "Reading comprehension" },
  { title: "Writing ", price: "Pre Algebra" },
  { title: "Algebra ", price: "Geometry" },
  { title: "Physics ", price: "Geometry" },
  { title: "Algebra ", price: "ACT Prep" },  
];

const TvMediaData = [
  {meta_image: meta_image},
  {meta_image: meta_image},
  {meta_image: meta_image},
  {meta_image: meta_image},
  {meta_image: meta_image},
]

export default class SummerEnrichmentPage extends React.Component {

  state = {
    isTVMedia: true,
    isVideoReview: true
  };

  componentDidMount = async () => {
    const script = document.createElement("script");
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
  };

  schedule = () => {
    window.open("https://meetings.hubspot.com/region3/schedule-summer-camp-assessment-");
  };

  toggleTVMedia = (status) =>{
    this.setState({isTVMedia: status});
  };

  toggleTVReview = (status) =>{
    this.setState({isVideoReview: status});
  };

  render() {
        
    const ShareURL = process.env.REACT_APP_PUBLIC_DOMAIN + window.location.pathname;        
    const { isTVMedia, isVideoReview } = this.state;
    const meta_title = "Summer Enrichment Camp For K - 12 - Start With Free Assessment";
    const meta_description = "The objective of our Summer Enrichment Camp for K-12 students is to ensure students are on grade level, despite the pandemic and inevitable summer slide.  Students will not only be ready for next year, but steps ahead of their peers with our instructor-led camp.";
    return (
      <div className="summer-page">
        <Helmet>
          <title>{meta_title}</title>
          <meta name="title" content={meta_title} />
          <meta name="description" content={meta_description} />
          <meta property="type" content="website" />
          <meta property="url" content={window.location.href}/>
          <meta property="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content={meta_title} />
          <meta itemprop="description" content={meta_description} />
          <meta itemprop="image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta_title} />
          <meta property="og:description" content={meta_description} />
          <meta property="og:image" content="https://gradegetter.com/gradegetter.png" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={meta_title}/>
          <meta name="twitter:description" content={meta_description}/>
          <meta name="twitter:image" content="https://gradegetter.com/gradegetter.png"/>
        </Helmet>
        <div className="summer-container">
          <div className="row main-row">
            <div className="col-lg-3 summer-left">
              <div className="ceo-img-name text-center">
                <img src={ceoPhoto} alt="core-bg" className="ceo-photo" />
                <p className="ceo-name">Roland Omene</p>
                <p className="ceo-mail">CEO @ GradeGetter</p>
              </div>
              <div className="ceo-phone-mail">
                {phoneMail.map((item, index) => {
                  return (
                    <div key={index} className="phone-mail-item">
                      <img src={item.img} alt="checkmark" />
                      <p className="des">{item.des}</p>
                    </div>
                  );
                })}
              </div>
              
              <Sticky
                // stickyStyle={{ zIndex: 1 }}
                // style={{ zIndex: 1 }}
                className="schedule-sticky"
              >
                <div className="schedule-btn-left" onClick={()=>this.schedule()}>
                  Schedule Assessment
                </div>
                <div className="schedule-left-area">
                  <div className="schedule-title">Schedule Assessment</div>
                  <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/region3/schedule-summer-camp-assessment-?embed=true"></div>
                </div>
              </Sticky>
            </div>

            <div className="col-lg-9 summer-right">
              <div className="row col-lg-12 summer-block text-center">
                <div className="col-lg-4 text-center">                
                  <img src={ceoPhoto} alt="summer-banner" className="summer-banner" />
                </div>
                <div className="col-lg-8">
                  <h2>Prevent Pandemic & Summer drain with Summer Enrichment GradeGetter</h2>
                </div>
                <div className="row col-lg-12">
                  <div className="summer-share">
                      <div className="share-description">Share</div>
                      <FacebookShareButton url={ShareURL} className="share-btn" >
                          <img src={valFacebookIcon} alt="facebook" />
                      </FacebookShareButton>
                      <TwitterShareButton url={ShareURL} className="share-btn" >
                          <img src={valTwitter} alt="valTwitter" />
                      </TwitterShareButton>
                      <EmailShareButton url={ShareURL} className="share-btn" >
                          <img src={valMail} alt="valMail" />
                      </EmailShareButton>
                      <LinkedinShareButton url={ShareURL} className="share-btn" >
                          <img src={valLinkedIn} alt="linkedin" />
                      </LinkedinShareButton>                      
                  </div>
                </div>
              </div>

              <div className="summer-block">
                <h3 className="item-title">Summer EnrichmentCa Tutoring For K - 12 With Free Assessment</h3>
                <p className="item-description">
                  When COVID-19 interrupted the spring semester, 
                  Frog Tutoring was able to step in and provide free instructor-led 
                  classes for over 1000  K-12 students whose education was disrupted by COVID-19. 
                  This initiative is ongoing and will end the last week of May. 
                  Our initiative has benefited over 1000 students across the nation.                  
                </p>

                <p>We are highlighted by local and national media for the work being done by our tutors. You can see our interview here:</p>
                <div className="sub-title-container mt-5">
                  <div>
                    <h5 className={`item-sub-title ${isTVMedia ? "item-sub-active" : "item-sub-inActive"}`} onClick={()=>this.toggleTVMedia(true)}>TV Mddia</h5>
                  </div>
                  <div>
                    <h5 className="item-sub-title item-sub-inActive ml-1 mr-1">|</h5>
                  </div>
                  <div>
                    <h5 className={`item-sub-title ${!isTVMedia ? "item-sub-active" : "item-sub-inActive"}`} onClick={()=>this.toggleTVMedia(false)}>Print Media</h5>
                  </div>
                </div>      
                <div className="col-lg-12 main-row">
                  <Media
                    queries={{
                      lg: "(min-width: 1200px)",
                      md: "(min-width: 992px) and (max-width: 1199px)",
                      sm: "(min-width: 768px) and (max-width: 991px)",
                    }}
                  >
                    {(matches) => (
                      <OwlCarousel
                        className="owl-theme"
                        rewind
                        nav
                        stagePadding={matches.lg ? 10 : matches.md ? 10 : 5}
                        margin={matches.lg ? 28 : matches.md ? 33 : 20}
                        items={matches.lg ? 2 : 1}
                        dots={false}
                        navText={[CarouselLeftButton, CarouselRightButton]}
                      >
                        {TvMediaData.map((item, index) => (
                          <TvMedia key={index} item={item} />
                        ))}
                      </OwlCarousel>
                    )}
                  </Media>
                </div>
                <p>
                  Now we’re moving into Phase Two of our plan to assist 
                  students whose education is still being disrupted by COVID-19. 
                  The objective of our Summer Enrichment For K-12 students is to make sure 
                  they are ready for their core classes when the fall semester resumes.</p>
              </div>             

              <div className="summer-block">
                <h3 className="item-title">Why Summer Enrichment for K-12?</h3>
                <p className="item-description">
                  Studies show, on average, that students lose 20% of the knowledge gained 
                  from the end of one academic year to the beginning of the next school year 
                  if they do not participate in a summer learning plan. 
                  Most experts agree this number might rise 40% due to the academic disruption of the pandemic. 
                  It can take up to 16 weeks for students to get back on track for the next school year 
                  if no summer enrichment is done.                  
                </p>
              </div>

              <div className="summer-block">
                <h3 className="item-title">How Our Summer Enrichment Camp Works?</h3>
                {SummerWorks.map((item, index) => (                 
                  <div className="summer-sub-block" key={index}>
                    <h5 className="item-sub-title">{item.title}</h5>
                    <p className="item-description">{item.description}</p>
                  </div>
                ))}

                <div className="sub-title-container mt-5">
                  <div>
                    <h5 className={`item-sub-title ${isVideoReview ? "item-sub-active" : "item-sub-inActive"}`} onClick={()=>this.toggleTVReview(true)}>Video Review</h5>
                  </div>
                  <div>
                    <h5 className="item-sub-title item-sub-inActive ml-1 mr-1">|</h5>
                  </div>
                  <div>
                    <h5 className={`item-sub-title ${!isVideoReview ? "item-sub-active" : "item-sub-inActive"}`} onClick={()=>this.toggleTVReview(false)}>Written Review</h5>
                  </div>
                </div>               
                
                <div className="col-lg-12 main-row">
                  <Media
                    queries={{
                      lg: "(min-width: 1200px)",
                      md: "(min-width: 992px) and (max-width: 1199px)",
                      sm: "(min-width: 768px) and (max-width: 991px)",
                    }}
                  >
                    {(matches) => (
                      <OwlCarousel
                        className="owl-theme"
                        rewind
                        nav
                        stagePadding={matches.lg ? 10 : matches.md ? 10 : 5}
                        margin={matches.lg ? 28 : matches.md ? 33 : 20}
                        items={matches.lg ? 2 : 1}
                        dots={false}
                        navText={[CarouselLeftButton, CarouselRightButton]}
                      >
                        {TvMediaData.map((item, index) => (
                          <TvMedia key={index} item={item} />
                        ))}
                      </OwlCarousel>
                    )}
                  </Media>
                </div>
                <div className="schedule-btn-container">
                  <div className="schedule-btn text-center" onClick={()=>this.schedule()}>
                      Schedule FREE Assessment
                  </div>
                </div>
              </div>

              <div className="summer-block">
                <h3 className="item-title">Subjects:</h3>
                <div className="max-transparency">
                  <div className="col-lg-8 col-md-12 col-sm-12 col-12 price-table-block mx-auto">
                    <div className=" price-table">
                      {Subjects.map((item, index) => {
                        return (
                          <div key={index} className="table-item">
                            <div
                              className={`col-lg-6   ${
                                index % 2 === 0
                                  ? "table-left1"
                                  : "table-left"
                              }`}
                            >
                              <p
                                className={` ${
                                  index === 6 ? "total-cost" : ""
                                }`}
                              >
                                {item.title}
                              </p>
                            </div>
                            <div
                              className={`col-lg-6  ${
                                index % 2 === 0
                                  ? "table-right1"
                                  : "table-right"
                              }`}
                            >
                              <p
                                className={` ${
                                  index === 6 ? "total-cost" : ""
                                }`}
                              >
                                {item.price}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>                          
                  </div>                     
                </div>
                <div className="schedule-btn-container">
                  <div className="schedule-btn text-center" onClick={()=>this.schedule()}>
                      Schedule FREE Assessment
                  </div>
                </div>
              </div>

              <div className="summer-block">
                <h3 className="item-title">Enrichment Program Options:</h3>
                <p className="item-description">Rates start at $25.5/hr and the lessons are customized to meet individual needs and schedule.</p>
                <div className="program-options">
                  <p className="item-description">summer enrichment camp</p>
                  <p className="item-description">20 hours  - summer enrichment camp</p>
                  <p className="item-description">30 hours  - summer enrichment camp</p>
                  <p className="item-description">40 hours  - summer enrichment camp</p>
                </div>
              </div>

              <div className="summer-block">
                <h3 className="item-title">Schedule your free consultation & Assessment today</h3>
                <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/region3/schedule-summer-camp-assessment-?embed=true"></div>
              </div>

              <div className="final-block">
                <p className="sincerely-mail">Sincerely,</p>
                <p className="ceo-name">Roland Omene,</p>
                <p className="sincerely-mail">CEO @ GradeGetter</p>
              </div>
              <img src={seoSign} alt="core-bg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
