import { post, logout } from "./Api";

export const signIn = (email, password) => {
  return post("/login", { email, password });
};
export const signOut = () => {
  return post("/logout");
};
export const signUp = (data) => {
  return post("/client_signup", data);
};
export const profileUpdate = (data) => {
  return post("/me", data);
};
export const signUpHubspot = (data) => {
  return post("/hubspot", data);
};
export const forgotPassword = (email) => {
  return post("/forgotpassword", {email});
};
export const resetPassword = (data) => {
  return post("/resetpassword", data);
};
export const tutorSignUp = (data) => {
  return post("/tutor_signup", data);
};
export const mailchimp = (data) => {
  return post("/mailchimp", data);
};

export const sendNoneToServer = (data) => {
  return post("/payments/braintreecard", data);
};

export const tvSubscribe = (data) => {
  return post("/gradetv/subscribe", data);
};


export const tutorProfileUpdate = (data) => {
  return post("/me", data);
};